.ThreeVideo {
}
.DelaySettingsInput {
  position: unset !important;
  width: 360px !important;
  display: flex;
  flex-flow: row;
  // bot
  //   justify-content: center;
  align-items: center;
  &_alert {
    color: #e7416a;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    text-align: right;
  }
  //   bottom: 0;
}

.TwoSceneAnimation {
  margin: 0 7px;
  cursor: pointer;
}
.TwoSceneAnimation_active {
  img {
    border: 2px solid #e7416a;
    border-radius: 5px;
  }
}
.VideoCard_parent {
  width: 148px !important;
  height: unset !important;

  .ThreeCard__wrapper {
    width: unset !important;
    height: unset !important;
    padding: unset !important;
  }
}
.tanimation_list {
  cursor: pointer;
  width: 100%;
  input {
    cursor: pointer;
  }
}
.videoCard {
  // background: rebeccapurple;
  // width: 100%;
  // height: 100%;
  width: 148px;
  height: 82px;
  display: flex;

  video {
    // height: 100%;
    width: 100%;
  }
  // position: absolute;
}

.LineText {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  &_title {
    color: #a9a9a9;
    margin-right: 10px;
  }
  &_value {
    margin-right: 10px;
  }
}

.Mask {
  padding: 20px;
  &_list {
    //  display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    //   grid-auto-rows: 1fr;
    //   gap: 20px;
    //   height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: max-content;
    max-height: 90vh;
    //  justify-content:space-between;
    flex-flow: row wrap;
    .ThreeCard {
      margin: 0 5px;
      width: 90px;
      //  padding: 10px;
      .ThreeCard__wrapper {
        width: 90px;
        height: 90px;
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }
}
.OpacityContainer {
  height: 100%;
  display: flex;
  flex-flow: column;
}
.SlideContainer {
  height: 100%;
}

.Animation {
  // position: relative;
  // height: 100%;
  .Mask_list {
    height: 75vh;
    overflow-y: scroll;
    .ThreeCard {
      margin: 0 3px !important;
    }
  }
  &_duration {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    // background: red;
    background: #313131;
    width: 100%;
    padding: 10px;
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  }
  ._TabTop {
    margin-bottom: 20px;
  }
}
.Animation_tanimation {
  .Mask_list {
    height: 71vh;
  }
}
.SAnimation {
  .Mask_list {
    height: 83vh;
    grid-template-columns: repeat(2, 1fr);
  }
}
.Shadow {
  padding: 20px;
  height: 90vh;
  overflow-y: scroll;
  &_flex {
    display: flex;
    margin-bottom: 20px;
  }
}

.TabColor {
  color: #a9a9a9;
  display: flex;
  padding: 10px 0;
  &_title {
    width: 90px;
  }
  &_color {
    width: 35px;
    height: 20px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.Draggy_LEFT {
  // background: #e7416a;
  width: 10px;
  border-radius: 5px;
  height: 100%;
  cursor: e-resize;
}
.Draggy_RIGHT {
  // background: #e7416a;
  width: 10px;
  border-radius: 5px;
  cursor: e-resize;
  height: 100%;
}
.react-draggble-dragged {
  cursor: grabbing !important;
}
.Draggy {
  // transition-duration: 0.3s;

  color: #e6e6e6;
  background: #e7416a;
  display: flex !important;
  // overflow: hidden !important;
  // display: flex !important;
  // flex-flow: row nowrap;
  // grid-template:1fr/1fr 1fr 1fr;
  justify-content: space-between;
  // width: 150px;
  height: 30px !important;

  margin: 3px;
  border-radius: 6px;
  position: relative !important;
  cursor: grab !important;
  // position: absolute;
  &_left,
  &_right {
    // width: 20px !important;
    background: rgba(214, 214, 214, 0.329);
    border-radius: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute !important;
    z-index: 1;
    display: flex !important;

    img {
      left: 50%;
      top: 50%;

      border: solid 0.5px #e6e6e6;
      padding: 5px;
      margin: 0 5px;

      border-radius: 5px;
      cursor: pointer;
    }
  }
  &_right {
    grid-area: "right";
    right: 0;
    // flex-flow: row-reverse, nowrap;
    justify-content: flex-end;
    transform: unset !important;
  }
  &_left {
    left: 0;
    grid-area: "left";
  }
  &_center {
    width: 100%;
    font-size: 10px;
    display: flex;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 5px;
    overflow: auto;
    // background: red ;
    &_loop,
    &_none {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-self: center;
      // background: #e7416a;
      // background: rgba(255,255,255,0.0);

      overflow: auto;
      height: 28px;

      img {
        margin: 2px;
        padding: 5px;
        background: rgba(255, 255, 255, 0.308);
        border-radius: 5px;
      }
    }
    &_loop {
      border-radius: 5px;
      margin: 1px 1px 1px 0;

      // box-sizing: ;
    }
    &_none {
      width: 100%;
      border-radius: 5px;
      margin: 1px 1px 1px 1px;
      // background: #e7416a;
      background: rgba(255, 255, 255, 0);
    }
  }
  &_center_active {
    .Draggy_center_loop,
    .Draggy_center_none {
      // background: #eeb7c5 !important;
      background: #e7416a !important;
    }
  }
}
.Draggy_parent {
  position: relative;
  // height: 40px;
  // background: pink;
  // z-index: 2;
  width: auto;
}

.TimeElement {
  // background: red;
  color: #e6e6e6;
  border: solid 1px #333;

  display: grid;
  grid-template: 36px/ 320px 1fr;
  font-size: 12px;
  width: 300%;
  height: 36px;

  &_obj {
    // padding: 10px;
    width: 310px;
    border: solid 1px #333;
    border-bottom: none;
    border-top: none;
    background-color: #222;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // position: absolute;
    position: sticky !important;
    left: 0 !important;
    z-index: 2;
    &_drag,
    &_icon {
      width: 30px;
      // background: red;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px;
    }
    &_name {
      margin: 10px;
      width: 100%;
      &_disabled {
        background-color: transparent;
        // background-color: red;

        border: none;
        font-family: "Poppins";
        color: #e6e6e6;
        width: 100%;
      }
      &_input {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.2);
        border: none;
        font-family: "Poppins";
        color: #e6e6e6;
        padding: 2px 5px;
        border-radius: 5px;
        outline: none;
      }
    }
    &_tool {
      width: 100%;
      height: 100%;
      padding: 0 10px;
      // background: rgb(255, 141, 141);
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      &_img {
        width: 25px;
        // height: 100%;
        margin: 0 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          // padding: 8px 4px;
          box-sizing: border-box;
          cursor: pointer;
          // height: 100%;
        }
      }
    }
  }
  &_obj_active {
    background: #333;
  }
  &_time {
    // place-self: center;
    // padding: 3px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    // background: grey;
  }
}
.Trim_Container {
  position: relative;
  &_btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: red;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
  &_Video {
    width: 100%;
    height: 187px;
    margin-top: 20px;
    border-radius: 10px;
  }
  &_time {
    margin-top: 20px;
    &_real {
      border-radius: 5px;
      background-color: #4d4d4d;
      padding: 6px 14px 7px;
      margin-top: 10px;
    }
  }
}
.Trim {
  padding: 20px;

  &_bound {
    position: relative;
    margin-top: 10px;
    height: 30px;
    width: 100%;
    border-radius: 5px;
    border: solid 0.5px #4d4d4d;
    background-color: rgba(51, 51, 51, 0.7);
    &_drag {
      border-radius: 5px;
      border: 1px solid #e7416a;
      border-left: 2px solid #e7416a;
      border-right: 2px solid #e7416a;
    }
  }
}
