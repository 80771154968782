.Elements {
  padding: 20px;
  .ElementSlider {
    &_link {
      color: #e7416a;
    }
    .slick-slider {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      // height: 100%;
      .slick-list {
        width: 100%;
        .slick-track {
          display: flex;
          align-items: center;
        }
      }
      // height: 100%;
      .SlickArrow {
        height: 100%;
        cursor: pointer;
        // background: red;
      }
    }
    &_img {
      // margin:8px 8px;
      // padding:auto;
      padding: 0px 6px;
      // background: red;
      width: 100%;
      min-width: 50px;
      min-height: 50px;
      box-sizing: border-box;
      height: 100%;
      cursor: pointer;
    }

    margin-bottom: 30px;
  }
}

.ColorPage {
  padding: 20px;
}
.ColorTab {
  &_tab {
    display: flex;
    margin-bottom: 10px;
    &_color {
      width: 50px;
      height: 30px;
      cursor: pointer;
    }
    &_color_active {
      border: 2px solid #e7416a;
    }
  }
}

.ElementListInfinite {
  padding: 20px;
  height: calc(94%);
  overflow-y: scroll;
  overflow-x: hidden;

  .InputIcon {
    width: 265px !important;
  }
  #ElementSearch {
    width: 100%;
  }
  .infinite-scroll-component {
    display: grid;
    /* flex-flow: row wrap; */
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* justify-content: space-between; */
    justify-items: center;
  }
  &_img {
    height: 100px;
    // margin: 0 7px;
    cursor: pointer;
    padding: 5px 7px;
  }
  .TitleRow {
    .backBtn {
      height: 100%;
      // width: 100%;
      cursor: pointer;
    }
    display: flex;
    align-items: center;
    .InputIcon {
      margin-left: 5px;
      width: 100%;
    }
  }
}

.ElementsListInfinite_shapes {
  .ElementListInfinite_img {
    width: 60px !important;
    height: unset;
    // margin: 0 7px;
    // padding: 5px px;
    padding: 8px;
  }
}

.ElementsListInfinite_icons {
  .ElementListInfinite_img {
    width: 60px !important;
    height: unset;

    background: rgba(255, 255, 255, 0.795);
    margin: 2px;
    border-radius: 5px;
    padding: 6px;
  }
}
.ColorPage {
  .TitleRow {
    cursor: pointer;
  }
}
