.menu-trigger {
  width: 100% !important;
}

.s0001 {
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-weight: 400;
}
.credit-box-wrapper {
  //   max-width: ;
  min-width: 600px;
  max-width: 1500px;
  width: 90%;
  margin: 4em 0;
}
.contact-box-wrapper {
  min-width: 600px;
  max-width: 1500px;
  width: 90%;
  //   margin: 4em 0;
}
.video-list-main-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
  width: calc(100% - 5vw);
}
.s002 {
  &_plan {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
  }
  .date-label-date {
    font-size: 18px;
    font-weight: bold;
    color: #e7416a;
    padding-left: 0.5em;
  }
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: 50%;
  padding: 2em;
  //   justify-content: center;
  align-items: center;
  .dates-header-in-subs {
    height: unset;
  }
  .creditbx001 {
    height: 100%;
  }
}
