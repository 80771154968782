@import "../../Chemistry/Atom/Atom";
.MergeSpeech {
  width: 100%;
  border: solid 1px #4d4d4d;
  border-radius: 5px;
  background: #444;
  .SelectOption_title {
    padding: 0;
  }
  &_insert {
    background: #4d4d4d;
    // height: 100%;
    // width: 100%;
    border-radius: 5px;
    padding: 10px;
  }
  &_textbox {
    border-radius: 5px;
    font-family: "Poppins";
    min-height: 100px;
    height: max-content;
    font-size: 12px;
    padding: 10px;
    outline: none;
    box-sizing: border-box;
    margin: 0 !important;
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */

    .statement {
      color: #e7416a;
    }
  }
  font-size: 12px;

  &_row {
    @include flex(row, space-between, center);
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    color: #a9a9a9;
  }
  &_function {
    border-top: solid 0.5px #4d4d4d;
    &_row {
      box-sizing: border-box;
      // padding: 10px;

      width: 100%;
      @include flex(row, space-between, center);
      &_select {
        @include flex(row, flex-end, center);
        &_edit {
          width: 120px;
          .EditSelect_input {
            border-bottom: none;
          }
        }
      }
      &_icons {
        @include flex(row, flex-end, center);
        img {
          margin: 0 9px;
          cursor: pointer;
        }
        &_loading {
          width: 25px;
        }
      }
    }
  }
}

.Delay {
  width: 100%;
  @include flex(row, center, center);
  margin: 14px 0;
  color: #a9a9a9;
  &_wrapper {
    @include flex(row, center, center);
    font-size: 12px;
    width: min-content;
    input {
      width: 30px;
      text-align: center;
      // margin: 0 5px;
      outline: none;
      // padding: 3.7px 0 0;
      // width: ;
      border-radius: 4px;
      background-color: #4d4d4d;
      // width: 30px;
      // height: 2px;
      // padding: 0 5px;
      color: #e6e6e6 !important;
      border: none;
      border: 1px solid #e7416a00;
      transition-duration: 0.5s;
      &:focus {
        border: 1px solid #e7416a;
      }
    }
  }
}

.MergeSpeech_textbox {
  cursor: "text";
}