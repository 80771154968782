.Frame {
  padding: 20px;
  overflow-y: scroll;
  height: 90vh;
}
.FrameTab {
  width: 100%;
  //   background-color: red;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 30px;
  margin: 20px 0;
  .Tabium_child {
    overflow-x: none;
  }
  &_tab {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
  }
  &_tab_active {
    box-shadow: 0 0 10px 0 rgba(248, 16, 97, 0.4);
    background-image: linear-gradient(75deg, #e72870, #e76161 98%);
    background-color: yellow;
  }
}
