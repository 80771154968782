.flex {
  display: flex;
  .row {
    flex-flow: row nowrap;
  }
}
.fullTemplateOption {
  width: 20vw;
  height: 200px;
  //   background: red;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  justify-content: center;

  .Button {
    margin-top: 10px;
  }
  //   position: absolute;
}

.PreviewVideo {
  width: 50vw;
  border-radius: 5px;
}
.EditableLabel {
  input {
    font-family: "Poppins";
  }
  display: flex;
  &_input {
    background: #242424;
    text-align: center;
    color: #e6e6e6;
    font-size: 16px;
    border: none;
    border-radius: 100px;
    margin-right: 1em;
    outline: none;
    width: max-content;
    &:disabled {
      background: none;
    }
    &:focus {
      border: 1px solid #e7416a;
    }
  }
  &_img {
    cursor: pointer;
  }
}

.pointer {
  cursor: pointer;
}
.column {
  flex-flow: column nowrap;
}
.between {
  justify-content: space-between;
}
.width50 {
  width: 100%;
}
.width70 {
  width: 60%;
}

.jstart {
  justify-content: flex-start;
}
.center {
  justify-content: center;
  align-items: center;
}
.acenter {
  align-items: center;
}
.PersonalisedHistory {
  &_title {
    margin: 30px 0 20px 0;
  }
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.NewPersonalized {
  width: 290px;
  height: 196px;
  // background-color: #242424;
  border-radius: 5px;
  border: dashed 2px #4d4d4d;
  background-color: rgba(36, 36, 36, 0.4);
  // margin-right: 10px;
  img {
    margin-right: 5px;
    width: 20px;
  }
}

.LinkHistoryTab {
  padding: 20px;
  border-radius: 5px;
  background-color: #242424 !important;
  margin: 5px 0;
  width: 860px;
  &_head {
    &_link {
      // width: 60%;
      // display: none;
      width: 60%;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &_body {
    &_date {
      color: #a9a9a9;
      img {
        margin-right: 5px;
      }
    }
    &_value {
      color: #a9a9a9;

      margin: 20px 0 20px 0;
    }
    &_merge {
      &_tag {
        color: #e7416a;
        margin-right: 10px;
      }
      margin-top: 10px;
    }
  }
}

.PersonalisedHistory {
  &_list {
    height: 100%;
    max-height: 255px;
    overflow-y: scroll;
  }
}
.AppHeader {
  padding: 30px;
  background-color: #242424;
  position: sticky;
  z-index: 1;
  top: 0;
  justify-content: space-between;
  a {
    color: inherit !important;
  }
}
.Button {
  padding: 0.521vw 1.823vw;
  border-radius: 25px;
  cursor: pointer;
  background-color: #e7416a;
  color: inherit;
  font-family: inherit;
  border: none;
  white-space: nowrap;
}
.Button_active {
  background-color: transparent;
}

.VideoCreator {
  height: 200px;
  background: grey;
  margin: 0 40px;
  border-radius: 10px;
  .Ratio {
    margin-bottom: 40px;
  }
}
.Ratio {
  background-color: #242424;
  border-radius: 5px;
  &_button {
    height: 40px;
    width: 66px;
    cursor: pointer;
  }
  &_button_active {
    box-shadow: 0 0 10px 0 rgba(248, 16, 97, 0.4);
    background-image: linear-gradient(59deg, #e72870, #e76161 97%);
    border-radius: 5px;
  }
}
// z-index: 2;
.Profile {
  width: 160px;
  display: flex;
  &_name {
    display: flex;
    align-items: center;
    font-size: 16px;
    &_image {
      width: 40px;
      height: 40px;
      // background: linear-gradient(45deg, hotpink, lightblue);
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  // flex-flow: row;
  // justify-content: flex-end;
  .SelectOption_list {
    font-size: 14px;
  }
}
.TabHeader {
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;

  ._TabTop {
    // margin: 0;

    .TabTop-child {
      white-space: nowrap;
      padding: 18px 30px;
    }
  }
}
.Templates {
  padding: 0 40px;
  .TabHeader {
    position: sticky;
    top: 95px;
    background-color: #242424;
  }
  .HoverImageCard {
    width: 120px !important;
  }
}

.Card {
  margin-bottom: 40px;
  cursor: pointer;
  overflow: hidden;
  &_per {
    position: absolute;
    top: 14%;
    left: -41%;
    transform: rotateZ(-45deg);
    background: #e7416a;
    width: 100%;
    text-align: center;
  }
  &_active {
    border: 5px solid #e72870 !important;
  }
  &_img {
    position: relative;
    width: 100%;
    // height: 245spx;

    &_small {
      border-radius: 10px;
      width: 100%;
    }
    &_option {
      .fullTemplateOption {
        display: none !important;
      }
      position: absolute;
      top: 0;
      right: 20px;
      // background: red;
      margin: 10px;
      .SelectOption {
        display: none;
      }
      .SelectOption_active {
        display: flex;
      }
      .SelectOption_list {
        min-width: 166px;
        right: 0 !important;
        top: 100% !important;
        border-radius: 5px;
        background-color: #333;
        padding: 5px 15px;
        &_item {
          font-size: 14px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          // height: 40px;
          // padding: 10px;
          // box-sizing: border-box;
          padding-top: 5px;
          padding-bottom: 5px;
          img {
            height: 100%;
            width: unset;
            border-radius: unset;
          }
        }
      }
    }
    &:hover {
      //   .Card_img_option {
      //     display: block;
      //   }
      .fullTemplateOption {
        display: flex !important;
      }
      .SelectOption {
        display: flex;
      }
    }
  }
  &_name {
    padding: 15px 10px;
  }
}

// .Card:nth-child(6n + 1) {
// 	.SelectOption_list {
// 		right: -131px !important;
// 	}
// }
.ListTab {
  // height: 75vh;
  .infinite-scroll-component {
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    gap: 20px;
    //   height: 600px;
    overflow-y: scroll;
    height: 100%;
  }
}

.MyVideoList {
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
  .infinite-scroll-component {
    // grid-auto-rows: 295px;
    display: grid;
    grid-template-rows: repeat(1fr, 4);
  }
  .Card {
    border-radius: 5px;
    &_img {
      // height: 12vw;
      width: unset;
      width: 100%;
      justify-content: center;
      // width: max-content;
      display: flex;
      &_small {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.ModelCardVideo {
  background-color: transparent;
  border: none;
  text-align: inherit;
  color: inherit;
  font-family: inherit;
  .Card {
    &_img {
      width: unset;
      height: 100%;
    }
  }
}
.ModelCardVideo_IMG {
  height: 556px;
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    height: 100%;
  }
  img {
    height: 100%;
  }
}

.Popup {
  border-radius: 5px;
  box-shadow: 0 0 6px 0 rgba(36, 36, 36, 0.16);
  border: solid 1px #4d4d4d;
  background-color: #333;
  padding: 30px;
  cursor: default;
  &_header {
    justify-content: space-between;
    margin-bottom: 30px;
    &_name {
      font-size: 20px;
      font-weight: 500;
    }
    &_close {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      &_close {
        cursor: pointer;
        margin-left: 30px;
      }
      .ButtonIcon {
        white-space: nowrap;
        border-radius: 100px;
        padding: 6px 17px;
        margin-left: 20px;
      }
    }
  }
  &_footer {
    margin-top: 30px;
    justify-content: center;

    width: 100%;
    .Button {
      width: max-content;
    }
  }
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.589);
  backdrop-filter: blur(5px);
  // cursor: pointer;
}

.MyVideo {
  padding: 0 40px;
  .TabHeader {
    // margin-top: 0px;
    position: sticky;
    top: 95px;
    background-color: #242424;
  }
  a {
    color: inherit;
  }
}

.FirstMyVideoCard {
  border-radius: 10px;
  border: solid 1px #333;
  background-color: rgba(16, 16, 16, 0.2);
  width: 21.9vw !important;
  height: 12vw;
  margin-right: 20px;

  .Ratio {
    margin-bottom: 40px;
  }
}
.VideoDetail {
  // display: grid;

  margin: 0 40px;
  &_header {
    // opacity: 0.3;
    border-radius: 5px;
    background-color: #333 !important;
    height: 60px;
    // margin: 0 40px;
    justify-content: space-between;
    align-items: center;
    &_img {
      padding: 20px;
      padding-left: 0;
      cursor: pointer;
      img {
        padding: 20px;
      }
    }
    &_name {
      font-size: 16px;
    }
    &_icons {
      img {
        cursor: pointer;
        padding: 20px;
      }
    }
  }
  &_detail {
    // grid-template: 1fr / 1fr 1fr;
    margin-top: 30px;
    &_thumb {
      position: relative;
      display: flex;
      overflow: hidden;
      &_per {
        position: absolute;
        top: 5%;
        left: -21%;
        transform: rotateZ(-45deg);
        background: #e7416a;
        width: 50%;
        text-align: center;
      }

      &_render {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        flex-flow: column;
        &_title {
          font-size: 16px;
          font-weight: 400;
        }
        &_time {
          color: #a9a9a9;
          font-size: 12px;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(17, 17, 17, 0.452);
        .indicator {
          width: 100%;
          // background: red;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          font-size: 45px;
          font-weight: 600;
          color: #e7416a;
          font-family: "Poppins";
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      width: 70%;
      img {
        width: 100%;
        border-radius: 10px;
        border: solid 1px #242424;
      }
    }
    &_about {
      // width: 605px;
      width: 30%;
      margin: 0 0 0 30px;
      height: 70vh;
      overflow-y: scroll;
      &_heading {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 30px;
      }
      &_download,
      &_report {
        width: 100%;
        box-sizing: border-box;
        border-radius: 10px;
        border: solid 1px #333;
        background-color: #333;
        padding: 20px 25px;
      }
      &_download {
        .button {
          &_download {
            border-radius: 25px;
            background-color: #e6e6e6;
            font-size: 16px;
            font-weight: 500;
            color: #e7416a;
            padding: 8px 25px;
            width: max-content;
            cursor: pointer;
            &_img {
            }
          }
          &_share {
            .Button:first-child {
              margin-right: 20px;
            }
          }
        }
      }
      &_report {
        margin-top: 30px;
        &_flex {
          justify-content: space-around;
        }
      }
    }
  }
}

.IconTitle {
  // justify-content: center;
  align-items: center;
  &_icon {
    // padding: 22px;
    width: 4.167vw;
    height: 4.167vw;
    border-radius: 50%;
    background-color: #4d4d4d;
    margin-bottom: 20px;
    img {
      width: 1.875vw;
      height: 1.875vw;
    }
  }
  &_value {
    font-size: 42px;
    font-weight: 600;
  }
}
