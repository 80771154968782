._title {
    display: flex;
    justify-content: center;

}

._title ._innerTitle {
    margin-bottom: 3em;
    font-size: 20px !important;
}

._contact {}

._form {}

._form .container-select {
    position: none;
}

._categroy,
._textarea,
._banner {
    /* margin-bottom: 2em; */
    padding: 13.5px 19.5px 13.5px 20px;
    border-radius: 28px;
    border: solid 2px #333333;
    background-color: #242424;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #4d4d4d;

}

._categroy {
    margin-bottom: 2em;

}

._banner {
    /* padding:0 30% !important; */
    /* padding: 0 15px; */
    margin: 0 15px;
}

._textarea {
    /* margin: 30px 60px 30px 108px; */
    /* padding: 19px 510px 352px 20px; */
    border-radius: 28px;
    border: solid 2px #333333;
    background-color: #242424;
    color: white;
    height: 100%;
}

._line {
    width: 0px;
    margin: 0 3em 0 4em;
    /* margin: 70px 0 30px; */
    /* box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16); */
    border-left: 2px solid #333333;
}

._lady_icon {
    margin: 2em 5em;
}

._banner ._wrap {}

._sm_title {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #a9a9a9;
}

._mail_num,
._mail_num:hover {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #e6e6e6;
    margin: 1em 0 0;
    text-decoration: none;
}


._social {
    /* width: 50%; */
    /* text-align: center; */
    padding: 5em 0 2em 0;
}

._send_btn {
    width: 150px;
    height: 50px;
    cursor: pointer;
    margin: 30px 0 0 15px;
    padding: 13.1px 46px 13.9px;
    border-radius: 28px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #e6e6e6;
    background-image: linear-gradient(72deg, #e72870 -4%, #e76161);
}

._textarea::placeholder {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #4d4d4d;
}

._social ._wrap {
    width: 50%;
    text-align: center;
}

._popup {
    padding: 40px 40px 30px 40px;
    width: 150%;
    right: 25%;
    position: relative;
    border-radius: 5px;
    border: solid 1px #4d4d4d;
    background-color: #333333;
}

._popup ._check {
    width: 100px;
    height: 100px;
}

._popup ._title {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #e6e6e6;
    margin-top: 3em;
}

._popup ._sm_title {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #e6e6e6;
    margin-top: 1em;
}

._close {
    width: 160px;
    cursor: pointer;
    height: 40px;
    margin: 60px 0 0 44px;
    padding: 11.1px 61px 11.9px;
    border-radius: 25px;
    font-family: Poppins;
    font-size: 1em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #dcf5f5;
    background-image: linear-gradient(77deg, #e72870 -4%, #e76161);
}

._tg {
    width: 100% !important;
    border-radius: 28px;
    border: solid 2px #333333;
    background-color: #242424;
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #4d4d4d;
    padding: 13.5px 19.5px 13.5px 20px;
}

._tg2 {
    width: 100%;
    right: 0;
}

._closex {
    width: 25px;
    height: 25px;
    cursor: pointer;
}


._cnt .name-in-profile {
    font-size: 36px;
}

._cnt .header-in-profile {
    margin-bottom: 50px;
}

._cnt ._tg {
    font-size: 16px;
    height: 50px !important;
}

.contact-box-wrapper {
    padding-top: 0;
}

._cnt .container-select {
    margin-bottom: 20px;
}