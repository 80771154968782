.faq {
  color: white;
  padding: 3em 3.5em;
}
.faq .heading {
  font-family: Poppins;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}
.faq .tabs {
  margin: 2em 0;
  /* position: sticky; */
  /* top: 2em; */
}
.faq .tabs .tab,
.outline {
  cursor: pointer;
  /* width: 220px; */
  width: auto;
  height: 120px;
  padding: 26px 29px;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
  margin: 0.5em;
}
.faq .tabs .tab .title {
  margin-top: 1em;
}

.outline {
  border: solid 2px #e7416a;
}
.faq .tabs .tab_active .title {
  color: #e7416a;
}
.faq .tabs .tab_active {
  border: solid 2px #e7416a;
}

.faq .tabs .tab {
  z-index: 2;
  position: relative;
  background-color: transparent;
}
.faq .tabs .tab * {
  z-index: 1;
}
.outline {
  position: fixed;
  transition-duration: 0.5s;
  margin: 0;
  background-color: transparent;
}
.faq .tabs .tab .big {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  top: 0;
  left: 0;
}
.faq .videos{
  width: 100%;
}
.faq .videos .video {
  width: 416px;
  margin: 2em;
}
.faq .videos .video .title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.faq .videos .video .thumb {
  width: 416px;
  height: 241px;
  margin: 20px 0 0;
  /* padding: 17px 132.2px 0.5px 129.8px; */
  border-radius: 5px;
  border: solid 1px #242424;
  background-color: #ffffff;
  cursor: pointer;
  position: relative;
}

.faq .videos .video .thumb::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: red;
}
._teg {
  position: relative;
}
._play {
  position: absolute;
  /* width: 100%; */
  cursor: pointer;
  /* height: 100%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MainVideo {
  position: fixed;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
