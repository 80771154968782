@mixin flex($flow, $jcontent, $aitems) {
  display: flex;
  flex-flow: $flow;
  justify-content: $jcontent;
  align-items: $aitems;
}
.UploadOption {
  &_img {
    width: 25px !important;
  }
  .BadButton {
    padding: 0;
    cursor: pointer;
  }
  .SelectOption_list {
    right: 10px;
    top: 40px;
    width: 100px;
    img {
      width: 15px;
    }
  }
}

.LoadingWrapping {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  // background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  cursor: wait;
  img {
    width: 60px;
  }
}

.Angle {
  .Label {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_img {
    padding: auto;
    justify-self: center !important;
    align-self: center !important;
  }
}

.NewColor {
  width: 100%;
  &_list {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    &_color {
      width: calc(50% - 10px);
      box-sizing: border-box;
      margin: 5px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  &_color {
    height: 280px;
    /* width: 300px; */
    /* padding: 0 1em; */
    width: 100%;
    box-sizing: border-box;
  }

  /* padding:0 40px; */
}
.SaturationValueSelector-module_saturation_value_selector__3JODT {
  margin: 0;
}
._colorCircle {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 1px #4d4d4d;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 10px;
  /* margin: 5px 0; */
}
.colorCircles {
  /* background: red; */
  display: flex;
  justify-content: flex-start;
  padding: 15px 0 0 0;
  flex-flow: row wrap;
  margin-bottom: 30px;
  overflow-x: scroll;
  // scrollbar-width: 0;
}
.colorCircles::-webkit-scrollbar {
  width: 0;
  height: 0;
}
._colorCircle_wrapper {
  position: relative;
}
._colorCircle_close {
  position: absolute;
  top: 0;
  width: 20px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 20px;
  top: -5px;
  /* left: 100%; */
  right: 0;
  border-radius: 50%;
  border: solid 1px #4d4d4d;
  background-color: #4d4d4d;
}

.colorCircles_head {
  // margin: 20px 0 0 10px;
  display: flex;
  justify-content: space-between;
  color: #a9a9a9;
}
.colorCircles_control img {
  margin: 0 10px;
  cursor: pointer;
}

.ZoomCxx {
  position: relative;
  &_title {
  }
  &_zoom {
    position: absolute;
    background: #4d4d4d;
    // left: -10px;
    // right: 0%;
    left: -350%;
    top: calc(100% + 10px);
    padding: 15px 0px;
    z-index: 95;
    border-radius: 10px;
    display: flex;
    align-items: center;
    &_input {
      width: 130px !important;
      display: flex;
      align-items: center;
    }
  }
}

.EditSelect {
  &_input {
    // background: red;
    @include flex(row, space-between, center);
    input {
      height: 30px;
      box-sizing: border-box;
      font-family: "Poppins";
      font-size: 12px;
      background: transparent;
      border: none;
      color: #e6e6e6;
      outline: none;
      width: 100%;
    }
    img {
      height: 8px;
    }
    &_img {
      margin-right: 10px;
    }
    border-bottom: 1px solid #4d4d4d;
  }
}

.Toggle {
  width: 50px;
  height: 25px;
  margin: 0 10px;

  // margin: 0 0 0 15px;
  // padding: 0 24px 0 0;
  // padding: 5px;
  // box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 13px;
  background-color: #242424;

  &_button {
    width: 25px;
    height: 25px;
    border-radius: 28px;
    box-shadow: 0 0 10px 0 rgba(230, 230, 230, 0.1);
    border: solid 1px #4d4d4d;
    background-color: #4d4d4d;
    cursor: pointer;
  }
}
.Toggle_active {
  justify-content: flex-end;
  .Toggle_button {
    background: #e7416a;
  }
}
.myLang {
  width: 100%;
}
.SelectOption {
  position: relative;
  cursor: pointer;
  font-size: 12px;
  &_title {
    // background: green;
    // height: 100%;
    padding: 10px;
  }
  &_list {
    width: max-content;
    top: 110%;

    box-sizing: border-box;
    position: absolute;
    z-index: 2;
    outline: none;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #4d4d4d;
    padding: 0 10px;
    &_item {
      cursor: pointer;
      margin: 5px 0;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }

      &:hover {
        color: #e7416a;
      }
    }
  }
}

.INFINITELOAD {
  backdrop-filter: blur(5px);
  z-index: 6;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  height: calc(100% - 0px);
  display: flex;
  justify-content: center;
  img {
    width: 60px;
    height: 60px;
  }
}
.InputRange {
  width: 100%;
  .css-q15ylk-Slider {
    cursor: pointer;
    width: 100% !important;
  }
}

.MusicContainer {
  display: flex;
  width: 100%;

  box-sizing: border-box;
  margin-top: 10px;
  height: 40px;
  &_music {
    position: relative;
    cursor: pointer;
    &_close {
      border: 3px solid #e7416a;
      cursor: pointer;
      position: absolute;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      bottom: 100%;
      left: 100%;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.048);
      transform: translate(-50%, 50%);
    }

    background: #4d4d4d;
    height: 30px;
    font-size: 12px;
    margin: 0 5px;
    justify-content: space-between;
    border-radius: 10px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 3px solid transparent;

    &_name {
      cursor: pointer;
      width: min-content !important;
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &_img {
      margin-left: 5px;
    }
  }
  &_music_active {
    border: 3px solid #e7416a;
  }
}
.MusicCard {
  .SelectOption_list {
    top: 0px !important;
  }
  // padding: 20px;
  // background: red;
  cursor: pointer;
  padding: 10px;
  // position: relative;
  // width: 334px;
  @include flex(row nowrap, flex-start, center);
  &_icon {
    width: 60px;
    min-width: 60px;
    height: 60px;
    cursor: pointer;
    border-radius: 5px;
    border: solid 1px #4d4d4d;
    background-color: #4d4d4d;
    @include flex(row, center, center);
  }
  &_details {
    padding: 10px 0;
    margin-left: 10px;
    // position: absolute;
    width: 100%;
    @include flex(column, space-between, flex-start);
    // background: blue;
    height: 50px;
    &_title {
      font-size: 12px;
      font-weight: 500;
      font-family: "Poppins";
      border: none;
      color: #e6e6e6;
      background: #242424;
      &:disabled {
        background: none;
      }
    }
    &_time {
      color: #a9a9a9;
    }
  }
  &_option {
    justify-self: flex-end;
    cursor: pointer;
  }

  &:hover {
    background-color: rgba(36, 36, 36, 0.31);
    border-radius: 6px;
  }
}
.MusicCard_selected {
  border: 2px solid #e72870;
  border-radius: 5px;
}
.HoverVideoCard {
  position: relative;
  width: 150px;
  padding: 0 2.5px;
  // .HoverImageCard{
  //   width: 145px;
  // }
  video {
    width: 150px;
    height: 90px;
    border-radius: 5px;
    // margin-bottom: 10px
  }
  video {
    margin-top: 10px;
  }
  &_loading {
    position: absolute;
    top: 10px;
    width: 160px;
    height: 90px;
    background: rgba(0, 0, 0, 0.644);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &_loader {
      width: 40px;
      height: 40px;
      border-radius: 5px;
    }
  }
  &_frame {
    margin-top: 10px;
    position: absolute;
    top: 0;
    height: 90px;
    width: 25% !important;
    right: 0;
    // background: red;
    z-index: 2;
    @include flex(column nowrap, space-between, flex-end);

    &_down {
      font-size: 12px;
      background: #4d4d4d;
      border-radius: 5px;
      color: #e6e6e6;
      padding: 1px 5px 1px;
      margin: 5px;
    }
    &_up {
      width: 100%;
      // width: 100px;
      cursor: pointer;
      // background: gray;
    }
  }
  &:hover {
    .HoverVideoCard_frame {
      display: flex !important;
    }
  }
}

.TabTop {
  @include flex(row nowrap, space-around, center);
  color: #e6e6e6;

  &-child {
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    padding-bottom: 8px;
    @include flex(row nowrap, center, center);
    border-bottom: 1px solid #4d4d4d;
    svg {
      margin-left: 6px;
    }
    &-active {
      svg g path,
      svg path {
        fill: #e7416a !important;
        stroke: #e7416a !important;
      }
      border-bottom: 3px solid #e7416a;
      color: #e7416a;
    }
    img {
      margin: 0 5px;
    }
  }
}
.ButtonIcon {
  cursor: pointer;
  font-size: 12px;
  color: #e6e6e6;
  border: none;
  border-radius: 5px;
  height: 30px;
  padding: 7px 10px;
  font-family: Poppins;
  // max-width: 90px;
  @include flex(row nowrap, center, center);
  background: #4d4d4d;
  svg,
  img {
    padding: 0 4px 0 6px;
  }
}

.ButtonIcon-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.ButtonIcon-mini {
  padding: 8px;
  height: 100%;
  svg,
  img {
    padding: 0;
  }
}

.Vr {
  width: 100%;
  border: none;
  border-top: 2px solid #4d4d4d;
}
.Hr {
  height: 100%;
  border: none;
  border-left: 2px solid #4d4d4d;
}
.TitleRow {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;
  // width: max-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(var(--leftWidth) - 100px);
}

.InputIcon {
  background: #242424;
  @include flex(row nowrap, flex-start, center);
  padding: 0 13px;

  border-radius: 100px;
  margin-top: 5px;
  .Input {
    background: transparent;
    border: none;
    height: 20px;
    width: 100%;
    margin: 0 5px;
    font-size: 12px;
    padding: 5px 10px;
    color: #e6e6e6;
    font-family: "Poppins";
    outline: none;
    &::placeholder {
      font-family: Poppins;
    }
  }
}
.HoverImageCard_title {
  text-align: center;
  border: none;
  font-family: "Poppins";
  background: #242424;
  outline: none;
  color: #e6e6e6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
  &:disabled {
    color: #a9a9a9;
    background: none;
  }
}
.HoverImageCard {
  &_model {
    // width: 65vw;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      // width: 100%;
      height: 100%;
      // height: 90vh;
    }
    video {
      height: 100%;

      // width: 100%;
    }
  }
  &_title {
    text-align: center;
    border: none;
    font-family: "Poppins";
    background: #242424;
    outline: none;
    color: #e6e6e6;
    &:disabled {
      color: #a9a9a9;
      background: none;
    }
  }
  width: 150px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  label {
    font-size: 12px;
    color: #a9a9a9;
  }
  &_check {
    position: absolute;
    background: #e72870;
    padding: 5px 15px 15px 5px;
    // background-clip: 100% 50%;
    clip-path: polygon(100% 0, 0 100%, 0 0);
  }
  cursor: pointer;
  &-wrapper {
    position: relative;
    .Image_wrapper {
      img {
        // height: 88px;
        width: 150px;
        border-radius: 5px;
        border: 2px solid #e7287100;
        box-shadow: 0px 0px 5px #242424;
        box-sizing: border-box;
      }
    }

    &-frame {
      position: absolute;
      top: 0;
      right: 0;
      height: 90px;
      width: 30%;
      img {
        width: 110%;
        // height: unset;
      }
      // background: yellow;
      @include flex(column nowrap, space-between, flex-end);

      &_number {
        background-color: red;
        width: max-content;
        position: relative;
        top: -10px;
        left: -10px;
        padding: 1px 5px;
        opacity: 1;
        border-radius: 5px;
        background-color: #e7416a;
      }
      // display: none;
    }
    &:hover &-frame {
      display: flex;
    }
  }
  &-wrapper_active {
    .Image_wrapper {
      img {
        border: 2px solid #e72870;
      }
    }
  }
}

.ArrowWrapper {
  @include flex(row nowrap, space-between, center);
  cursor: pointer;
  color: #e6e6e6;
  &:hover {
    background: #24242463;
  }
  .Content {
    width: 100%;
    // height: 100%;
    // background: red;
  }
  .Arrow {
    transform: rotateZ(90deg);
    margin: 0 15px;
  }
}
.LabelInput_wrapper {
  // background-color: red;
  // justify-content: center;
  align-items: center;
}
.HaveInc {
  .LabelInput_Input {
    border: 1px solid #4d4d4d;
    border-radius: 5px;
    input {
      background: #292929;
      border-left: 1px solid #4d4d4d;
      border-radius: 5px;
      border-right: 1px solid #4d4d4d;
    }
    background: #4d4d4d;
    &_button {
    }
  }
  input {
    border: 1px solid #4d4d4d;
  }
}
.darkBackground {
  background: #292929 !important;
  border-radius: 5px;
  // padding-left: 5px !important;
  text-align: center !important;
}
.LabelInput {
  display: flex;
  flex-flow: row nowrap;
  height: 30px;
  align-items: center;

  width: min-content;
  label {
    display: flex;
    min-width: 30px;
    font-size: 12px;
    color: #a9a9a9;

    height: 100%;
    align-items: center;
  }
  &_Input {
    &_button {
      // background-color: red;
      width: 20px;
      // height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      border-radius: 5px;
      cursor: pointer;
    }
    display: flex;
    // justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    input {
      background: transparent;
      height: 100%;
      border: none;
      color: #e6e6e6;
      width: 100%;
      font-family: "Poppins";
      //   padding: 0;
      padding: 0 5px;
      outline: none;
      box-sizing: border-box;
      &:focus {
        border-bottom: 1px solid #4d4d4d;
      }
    }
  }
}

.ThreeCard {
  @include flex(column nowrap, flex-start, center);
  width: 105px;
  cursor: pointer;
  &__active {
    border: 3px solid #e7416a !important;
  }
  &__wrapper {
    // background: linear-gradient(45deg, blue, lightblue);
    width: 95px;
    border-radius: 5px;
    border: 1px solid #707070;
    height: 95px;
    @include flex(column nowrap, center, center);
    &__Image {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
  label {
    color: #e6e6e6;
    padding: 6px 0;
  }
  .colors_label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
    .colors {
      display: flex;

      &_color {
        width: 40px;
        height: 20px;
        margin: 0 5px;
        border-radius: 5px;
      }
    }
  }
}

.ImgToggle {
  cursor: pointer;
}

.Color {
  z-index: 1;
  &_drop {
    width: 100%;
    align-items: center;
    img {
      cursor: pointer;
    }
    &_picker {
      margin-left: 1em !important;
    }
  }
  .react-colorful {
    width: 100%;
    height: 210px;
    &__saturation {
      border-radius: 8px;
      cursor: pointer;
    }
    &__hue {
      margin-top: 10px;
      border-radius: 8px;
      height: 20px;
      cursor: pointer;
    }
  }
}

.TitleWrapper {
  margin: 15px 0;
  &_label {
    color: #a9a9a9;
    margin-bottom: 10px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@mixin ja($j, $a) {
  align-self: $a;
  justify-self: $j;
}
.Dimensions {
  &_col {
    @include flex(row nowrap, space-between, center);
    &_input {
      width: 100%;
      &_row {
        @include flex(row nowrap, space-between, center);
        margin-bottom: 20px;
        &:nth-child(2) {
          margin-bottom: 0;
        }
      }
    }
    &_position {
      width: 106px;
      height: 70px;
      margin-left: 10px;
      img {
        cursor: pointer;
      }
      img:nth-child(1) {
        @include ja(flex-start, flex-start);
      }
      img:nth-child(2) {
        @include ja(center, flex-start);
      }
      img:nth-child(3) {
        @include ja(flex-end, flex-start);
      }
      img:nth-child(4) {
        @include ja(flex-start, center);
      }
      img:nth-child(5) {
        @include ja(center, center);
      }
      img:nth-child(6) {
        @include ja(flex-end, center);
      }
      img:nth-child(7) {
        @include ja(flex-start, flex-end);
      }
      img:nth-child(8) {
        @include ja(center, flex-end);
      }
      img:nth-child(9) {
        @include ja(flex-end, flex-end);
      }
    }
  }
}

.DisabledWrapper {
  z-index: 2;
  position: relative;
  &_child {
    z-index: -1 !important;
    height: 100%;
  }
  &_black {
    // opacity: 0.3;
    position: absolute;
    cursor: not-allowed;
    top: 0;
    left: 0;
    // background-color: rgba(0, 0, 0, 0.452);
    width: 100%;
    height: 100%;
    z-index: 999 !important;
  }
}
.DisabledWrapper_active {
  opacity: 0.3;
}

._DragAndDropImage {
  cursor: pointer;
  background-color: #242424;
  width: 100%;
  height: 83px;
  margin: 0.781vw 0;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  // padding: 0.729vw 0 !important;
  img {
    // height: 15px;
    width: 15px;
  }
  &::after {
    // top: 0.729vw;
    // left: 0.729vw;
    position: absolute;
    top: 12px;
    left: 12px;
    content: "";
    width: calc(100% - 28px);
    height: calc(100% - 28px);
    background: transparent;
    border-radius: 0.26vw;
    border: dashed 0.15vw #333333 !important;
  }
}
._DragAndDropImage_Active {
  background: #e72870;
}

.align-items-center {
  align-items: center;
}
.img-eye-short {
  width: 28px;
}
