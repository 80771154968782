.Selector {
  width: 150px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20;
  height: 40px;
  .SelectOption_title {
    padding: 0;
  }
  .EditSelect_input {
    border: none;
  }
  input {
    font-size: 20px;
    cursor: pointer;
    // font-weight: 600;
  }
  .SelectOption_list {
    width: 100%;
    top: 130%;
    &_item {
      font-size: 16px;
    }
  }
}

.CopyLink_button {
  .Button {
    align-items: center;
    // justify-content: space-between;
  }
  &_pending {
    width: 25px !important;
    height: 25px !important;
    // margin-left: 20px;
    margin-left: 10px;
    // position: absolute;
    // right: 0;
  }
}
.x023 {
  width: 700px;
  height: 150px;
}
.x024 {
  justify-content: space-around;
  height: 100px;
}
.CopyLink {
  .RCP {
    height: 20px;
    width: 30px !important;
    position: relative;
    svg {
      // width: 20px;
      height: 60px !important;

      position: absolute;
      top: -100%;
      right: -31px;
    }
  }
}
.LinkPopop {
  border: solid 1px #4d4d4d;
  padding: 20px;
  // padding-right: 0;
  border-radius: 5px;
  // width: 925px;
}
.DownloadPopup {
  width: 45vw;
  position: relative;
  align-items: center;

  .Err {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    padding: 5px;
    font-size: 20px;
    color: #e7416a;
    font-weight: 600;
  }
  .ScreenLoading {
    // width: 80%;
    // height: 80%;
    backdrop-filter: unset;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.356);
    top: 0;
    left: 0;
    .Text {
      // margin-top: 0px;
      padding: 20px 0;
      font-size: 16px;
      // padding: 0;
    }
  }
  &_merge {
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 0 10px;
    // align-items: center;
    // justify-content: center;
    overflow-y: scroll;
    height: 200px;
    // background-color: rgb(194, 194, 194);
    .LabelInput {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      .Label {
        width: 130px;
        font-size: 16px;
        color: #e6e6e6;
        // width: 250px;
        white-space: nowrap;
        //   overflow: hidden;
        //   text-overflow: ellipsis;
      }
      &_Input {
        width: 70% !important;
        border-bottom: 1px solid #4d4d4d;

        margin-left: 20px !important;
      }
    }
  }
}
.TopCard {
  margin-right: 30px;
  width: 240px;
  &_img {
    width: 100%;
    height: 136px;
    border-radius: 5px;
    background-color: #4d4d4d;
  }

  &_title {
    font-size: 16px;
    font-weight: 500;
  }
  &_name {
    color: #e7416a;
    padding: 5px 0 0px 0;
    text-align: left;
  }
  &_top {
    margin-bottom: 15px;
  }
}
.PerImage {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  // border-radius: 10px ;

  &_active {
    border: 4px solid #e7416a;
    box-sizing: border-box;
    // border-radius: 10px !important;
  }
  &_text {
    position: absolute;
    top: 0;
    background-color: #e7416a;
    transform: rotateZ(-45deg);
    width: 300px;
    text-align: center;
    left: -115px;
    font-size: 12px;

    top: 25px;
  }

  &_option {
    position: absolute;
    right: 0;
    .fullTemplateOption {
      display: none !important;
    }

    &:hover {
      .fullTemplateOption {
        display: flex !important;
        backdrop-filter: blur(2px);
      }
    }
    // position: static;
    .SelectOption_list {
      right: 10px !important;
      top: 90% !important;
      //    bottom: 10px !important;
      //    background-color: red;
    }
    // .SelectOption{
    //     position: static;
    // }
  }
}

.CopyLink {
  width: 280px;
  &_link {
    width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &_button {
    margin-top: 20px;
    &_tab {
      cursor: pointer;
      width: 40px;
      height: 40px;
      background-color: #e7416a;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}

.BadButton {
  background: transparent;
  color: inherit;
  font-family: inherit;
  border: none;
}
.SharingPopup_footer,
.VariablePopup_footer,
.MailPopup_footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  width: 100%;
  .Button {
    margin-left: 10px;
  }
  .grey {
    background-color: #4d4d4d !important;
  }
  &_img {
    align-items: center;
    img {
      margin: 0 5px;
    }
  }
}
.SharingPopup {
  .template_img {
    width: 100%;
  }
  .BadButton {
    padding: 0 !important;
  }
  &_first {
    width: 367px !important;
    height: 208px !important;
  }
  .infinite-scroll-component {
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    gap: 20px;
  }
  overflow-y: scroll;

  height: 600px;
  //   min-height: 634px;
  &_card {
    height: min-content;
    &_img {
      width: 100%;
      height: 100%;
    }
  }
  ._name {
    text-align: center;
    margin-top: 10px;
  }
  .PerImage {
    // margin: 10px;
    margin-bottom: 0;
    width: 367px;
    height: 208px;
    border-radius: 5px;
    img {
    }
  }
}

.VariablePopup {
  width: 940px;
  &_grid {
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 170px;
    gap: 20px;
    height: 400px !important;
    overflow-y: scroll;
    ._DragAndDropImage {
      width: 120px;
      height: 120px;
      // padding: 0;
      margin: 0;
      text-align: center;
      font-size: 12px;
    }
    .Card {
      overflow: unset;
      &_img {
        cursor: pointer;
        border-radius: 5px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.11);
        border: solid 1px #333;
        background-color: #242424;
        // display: f;
        height: 120px;
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        img {
          width: unset;
          height: unset;
          border-radius: unset;
        }
        &_option {
          //   width

          top: 0 !important;
          right: 0 !important;
          .SelectOption_title {
            padding: 0px;
            img {
              width: 25px;
            }
          }
        }
      }
      &_name {
        text-align: center;
        width: 120px;
      }
    }
    &_csv {
      &_icon {
        cursor: pointer;
        border-radius: 5px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.11);
        border: solid 1px #333;
        background-color: #242424;

        height: 120px;
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .name {
        margin-top: 15px;
        //   background-color: green;
        text-align: center;
        width: 120px;
      }
    }
    .upload {
      .VariablePopup_grid_csv_icon {
        //   background: transparent !important;
        background-color: transparent !important;
        border: 1px solid #4d4d4d;
        img {
          border: 3px dashed #4d4d4d;
          padding: 30px;
          border-radius: 5px;
        }
        //   outline-offset: 2px;
        //   outline-width: 4px;
        //   outline-color: red;
      }
    }
  }

  .LabelInput {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .Label {
      width: 130px;
      font-size: 16px;
      color: #e6e6e6;
      // width: 250px;
      white-space: nowrap;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
    }
    &_Input {
      width: 70% !important;
      border-bottom: 1px solid #4d4d4d;

      margin-left: 20px !important;
    }
  }
}

.MailPopup {
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: 175px;

  gap: 20px;
  height: 600px;
  overflow-y: scroll;
  //   min-height: 634px;
  &_card {
    height: min-content;
  }
  ._name {
    text-align: center;
    margin-top: 10px;
  }
  .PerImage {
    // margin: 10px;
    margin-bottom: 0;
    width: 150px;
    height: 150px;
    border-radius: 5px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.VariablePopup_tab {
  display: flex;
  background: #4d4d4d;
  border-radius: 100px;
  width: min-content;
  margin-bottom: 30px;
  &_tab {
    padding: 9px 44px;
    font-size: 16px;
    white-space: nowrap;
    cursor: pointer;
  }
  &_tab_active {
    border-radius: 20px;
    background-color: #e7416a;
  }
}
.grey {
  background: #4d4d4d !important;
}
.GeneratePopup {
  width: 50vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #333;
  &_img {
    width: 70px;
    height: 70px;
  }
  &_text {
    color: #e7416a;
  }
  &_progress {
    margin-top: 10px;
  }
  &_info {
    margin: 20px;
    color: #a9a9a9;
  }
}

.ErrorPopup {
  width: 650px;
  &_heading {
    text-align: center;
    &_main {
      font-size: 20px;
    }

    &_info {
      font-size: 14px;
      color: #e7416a;
    }
    margin-bottom: 15px;
  }
  &_row {
    padding: 5px 0;
    border-top: 1px solid #4d4d4d;
    align-items: center;
    &_title {
      width: 130px;
    }
    &_list {
      &_item {
        margin: 5px 0;
        align-items: center;
        &_button {
          width: 70px;
          height: 30px;
          // font-size: 14px;
          font-weight: 600;
          border-radius: 5px;
          background-color: #4d4d4d;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &_img {
          margin: 0 10px;
        }
        &_err {
          // font-size: 14px;
          color: #e7416a;
        }
      }
    }
  }
}

.TemplatePopup {
  .PerImage_option {
    width: 100%;
    height: 100%;

    // backdrop-filter: blur(5px);
  }
}

.TopCard_title {
  text-align: start;
}
