.IFrame {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
}

.IFrame span.true {
}
.IFrame .Frame {
  width: 100%;
  height: 100%;
  background: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: unset !important;
}
.IFrame .Desktop iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.IFrame .MobileFrame {
  /* background: red; */
  position: relative;
  height: 100%;
  width: 475px;
}
.IFrame .Mobile iframe {
  position: absolute;
  top: 41px;
  left: 46px;
  height: 780px;
  width: 372px;
  border: none;
  border-radius: 43px;
}

.salespage-preview {
  display: flex;
  justify-content: space-between;
  height: 60px;
  padding: 0 2.5rem 0 27px;
  background-color: #222222;
}

.salespage-preview .gp-preview {
  display: flex;
  align-items: center;
  /* width: 300px; */
}

.salespage-preview .gp-preview #Group_73 {
  height: 27px;
  width: 249px;
}

.back-btn-preview {
  width: 48px;
  height: 48px;
  margin-right: 17px;
}
.select-size-icon-container {
  position: relative;
}

.selected-size-bd {
  position: absolute;
  width: 35px;
  height: 0;
  border-top: solid 5px #e7416a;
  bottom: 0;
  display: none;
  right: 14px;
}

.selected-size-bd.x99 {
  left: 9px;
  right: auto;
}
.closesalespagebtn {
  width: 160px;
  height: 2.5rem;
  margin: 10px 0 10px 20px;
  padding: 10px 20px;
  border-radius: 25px;
  border: solid 1px #333333;
  background-color: #333333;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}
