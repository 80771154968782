.BackgroundSettings {
  padding: 20px;

  overflow: scroll;
  overflow-x: hidden;

  height: 90vh;
  .ImagePanel_range {
    margin: 15px 0;
  }
  .Mask {
    padding: unset;
    &_list {
      .ThreeCard {
        margin: 0 4px !important;
      }
    }
  }
  // .NewColor_list{
  //     height: 22vh;
  //     overflow: scroll;
  // }
}

.ControlsBackground {
  margin: 30px 0 5px 0;
  display: flex;
  justify-content: space-between;
  &_full {
    width: 100%;
    // cursor: pointer;
  }
  &_m {
    margin: 0 4px;
    cursor: pointer;
  }
  &_active {
    .ButtonIcon {
      background: #e7416a;
    }
  }
}

.InputRangeWithTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_input {
    width: 100%;
    margin-right: 20px;
  }
}
.MakeVariableButton {
  &_button {
    box-sizing: border-box;
    padding: 6px 10px 7px;
    border-radius: 5px;
    border: solid 1px #e7416a;
    color: #e7416a;
    background-color: #333;
    width: max-content;
    cursor: pointer;
  }
  .LabelInput {
    width: 100%;
  }
}
