.Avatar {
  color: #e6e6e6;
  height: 100%;
  .AVATAR_INFO {
    label {
      cursor: pointer;
    }
  }
  .DefaultPage {
    padding: 20px;
    height: 90vh;
    overflow-y: scroll;
    .DisabledWrapper {
      z-index: unset;
    }
    &_between {
      justify-content: space-between;
      margin-bottom: 10px;
    }
    &_mixedavatar {
      width: 170px;
      background-color: #4d4d4d;
      border-radius: 5px;
      .SelectOption {
        padding: unset;
        &_title {
          padding: 5px 10px;
        }
        .x002 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          img {
            height: 7px;
          }
        }
      }
      .SelectOption_list {
        background: #333;
        // box-shadow: ;
        box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
      }
      .x001 {
        display: flex;
        justify-content: space-between;
        width: 100px;
        box-sizing: border-box;

        img {
          margin-right: unset;
        }
      }
      // width: calc(100% - 40px);
    }
    .VoiceOnly {
      display: flex;
      width: 100%;
      margin: 20px 0 10px 0;
      align-items: center;
    }
    .Avatar_Profile {
      background: red;
      padding: 10px 0 10px 10px !important;
      border-radius: 5px;
      border: solid 1px #4d4d4d;
      background-color: #4d4d4d;
      cursor: pointer;
      .Avatar_Profile_img {
        width: 80px;
        height: 80px;
      }
    }
  }
  &__Settings {
    .myLang {
      cursor: pointer;
      margin-bottom: 10px;
      border-radius: 10px !important;
      .SelectOption {
        &_list {
          height: 400px;
          overflow: scroll;
          overflow-x: hidden;

          background-color: #4d4d4d;
          &_item {
            &_img {
              width: 25px !important;
            }
          }
        }
      }
      .SelectOption_title {
        padding: 3px 10px;
        background: #404040;
        border-radius: 5px;
        cursor: pointer;
        transition-duration: 0.5s;
        &:hover {
          background: #6d6d6d;
        }
      }
      .EditSelect {
        &_input {
          cursor: pointer;
          border-bottom: none;

          input:disabled {
            cursor: pointer;
          }
          &_img {
            height: unset !important;
            width: 25px;
          }
        }
      }
    }
    padding: 20px;
    &__video {
      background: rgb(255, 255, 255);
      width: 100%;
      border-radius: 5px;
      color: #e7416a;
      position: relative;
      .slick-list {
        width: 100%;
        video {
          height: 188px;
          margin: 0;
        }
      }

      .slick-slider {
        display: flex;
        align-items: center;
        img {
          // height: 100%;
          margin: 5px;
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }

      .ImgToggle {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 5px;
        // left: 90%;
        // right: 10%;
      }
    }
  }
  .Tabium {
    .TabTop {
      margin: 15px 0;
    }
    .ThreeCard {
      margin-bottom: 5px;
      // width: 90px;
    }
  }
}
.row {
  display: flex;
  flex-flow: row nowrap;
}
.wrap {
  flex-wrap: wrap;
}
.justify-content-between {
  justify-content: space-between;
}
.threeGrid {
  display: grid;
  // grid-template-areas: repeat(1fr,3);
  grid-template-columns: 1fr 1fr 1fr;
  max-height: 50vh;
  height: max-content;
  overflow-y: scroll;
  overflow-x: hidden;

  padding: 2px;
  grid-gap: 5px;
}
.aitems-center {
  align-items: center;
}
._THREEICONS {
  width: 95px !important;
  img {
    width: unset !important;
    height: unset !important;
  }
}

.AvatarPosition {
  display: grid;
  height: 100%;
  width: 100%;
  grid: 1fr 1fr 1fr/1fr 1fr 1fr;
}
.OrderRow {
  justify-content: space-between;
  padding: 10px 0 10px 0;
  .ButtonIcon {
    margin: 0 0.5px;
    padding: 6px 6px !important;
  }
}

.AVATAR_INFO {
  label {
    display: flex;
    align-items: center;
    font-weight: 200 !important;
    img {
      margin-right: 5px;
    }
  }
}
.customVideoLoad {
  position: relative;
  &_load,
  &_play {
    position: absolute;
    width: 100%;
    // background: red;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  &_play {
    &_img {
      width: unset !important;
      height: unset !important;
    }
  }
  &_load {
    display: none;
    img {
      width: 50px !important;
      height: unset !important;
    }
  }
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    // width: 100%;
    height: 150%;

    // transform: translate(-50%,0px);
  }
}

.AvatarWarning {
  // width: 50%;
  font-size: 16px;
  text-align: center;
  // width: 30%;
}

.AVATARVIEW {
  margin-top: 20px;
}
