.time_seek {
  // width: 10px;
  // height: 10px;
  background: #222222;
  position: absolute;
  top: 15px;

  left: 20px;
  padding: 2px 5px;
  border-radius: 100px;
  font-size: 14px !important;
  border: 1px solid #a9a9a9;
}
.haveEasyMode {
  width: calc(100% - var(--leftWidth));
  background: rgba(0, 0, 0, 0.363);
  height: calc(var(--timelineHeight) - 80px);
  position: fixed;
  left: var(--leftWidth);
  //   top: 0;
  bottom: 0;
  overflow: scroll;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  .small {
    font-size: 16px;
    margin-top: 10px;
  }

  button {
    color: #e6e6e6;
    font-family: "Poppins";
    border: none;
    cursor: pointer;
  }
}
.time_move {
  position: fixed;
  width: 10px;
  height: 10px;
  background-color: yellow;
}
.TimeBar {
  background: #222;
  height: calc(100% - 53px);
  overflow-y: scroll;
  // height: 40px;
  .DisabledWrapper {
    //  height: 100%;
  }
  .TimeScene {
    height: 30px;
    width: 100%;
  }

  width: 100%;
  border-radius: 5px;
  position: relative;
  transition-duration: 0.5s;
  &_close {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    height: 15px;
    background: #242424;
    width: 80px;
    z-index: 1;
    left: calc(50% - 40px);
    border-radius: 200px 200px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
    }
  }
}

.SeekBar_container {
  // background: rgba(255, 0, 0, 0.163);
  // width: 100%;
  // height: 100%;

  position: sticky;
  top: 0px;
  z-index: 12;
  transform: translateX(320px);

  // left: -320px;

  // z-index:99;
}
.Worksace {
  background: rgba(255, 255, 255, 0.541);
  cursor: url("eresize32.png") 16 16, auto !important;
  border-radius: 100px;
}
.SeekBar {
  width: 5px !important;
  // height: 100% !important;
  height: 180px !important;
  z-index: 4;
  background: #e6e6e6;

  cursor: url("eresize32.png") 16 16, auto !important;

  position: absolute !important;
  top: 0;
  #triangle {
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 15px solid #e6e6e6;
    position: relative;
    left: -6.8px;
    // cursor: e-resize;
    cursor: url("eresize32.png") 16 16, auto;

    border-radius: 3px 3px 0 0;
  }
}

.Time {
  // display: flex;
  // width: 100%;
  display: grid;
  grid-template: 40px/ 320px 1fr;
  position: sticky;
  top: 0;
  z-index: 3;
  background: #222222;
  &_blank {
    .sceneTime {
      width: 100%;
      text-align: center;
      font-size: 14px;
    }
    width: 310px !important;
    font-size: 16px;
    display: flex;
    align-items: center;
    // padding: 0 15px;

    // padding-right: 0px;
    font-weight: 400;
    border: solid 0.5px #4d4d4d;
    position: sticky;
    left: 0;
    background: #222222;
    z-index: 1;
    // border-left: 0.25px solid #e6e6e6;
  }
  &_time {
    cursor: pointer;
    display: flex;
    width: 100%;
    align-items: flex-end;
    position: relative;
    // background: red;
    &_big {
      position: absolute;
      // text-outline: 0 0 #000;
      top: 0;
      width: 100%;
      height: 100%;
      // background: blue;
      z-index: 1;
    }
  }
  &_sec {
    border-left: 0.25px solid #a9a9a9;
    width: 0;
  }
  &_minisec {
    border-left: 0.25px solid #a9a9a9;
    // border-radius: 50%;
    color: #a9a9a9;
    // background: #e6e6e6 !important;
    width: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 15px;
    // background: red;
    &_sec {
      position: absolute;
      top: 0;
      // left: -50%;
      transform: translateX(-50%);
      // text-outline: 0 0 #000;
    }
  }
}

// .List{
//     display: flex;
//     flex-flow: column-reverse;
// }
