
.video_recording_btn {
    margin: 10px;
}

.video_recording_btn_disable {
    opacity: 0.3;
    cursor: not-allowed;
}

