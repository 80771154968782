.Tab{
    display: flex;
    flex-flow: column nowrap;
    width: 70px;
    height: 70px;
    justify-content: center;
    align-items: center;
    background: #242424;
    z-index: 2;
    cursor: pointer;
    label{
        cursor: pointer;
        font-size: 11px;
        margin-top: 7px;
        text-transform: capitalize;
        color: #e6e6e6;
        text-decoration: none !important;
        &:visited{
            text-decoration-color: transparent;
        }
        
    }
    &-active{
        background: #333;
        color: #e7416a;
        border-radius: 0px;
        svg g path,svg path{
            fill: #e7416a !important;
            stroke: #e7416a !important;
        }
        label{
            color: #e7416a;
        }
       
    }
}
a:-webkit-any-link{
    text-decoration: none !important;
}