@mixin GridPropery($gridName, $backgroundColor, $fontSize) {
  grid-area: $gridName;
  background: $backgroundColor;
  font-size: $fontSize;
  color: #e6e6e6;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.MainLoading {
  width: 80px;
}
:root {
  --timelineHeight: 75px;
  --rigthWidth: 334px;
  --leftWidth: 424px;
  --headerHeight: 50px;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  // border-radius: 5px;
  /* margin-right: 5px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #777777;
  opacity: 0.25;
  border-radius: 10px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(163, 163, 163);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
#FullSCREEN {
  .ButtonIcon {
    padding: unset;
    border-radius: 50%;
    img {
      width: 30px;
    }
  }
}
.SceneDuration {
  // margin-top: 10px;
  .ExpandButton {
    // height: calc(100% - 10px);
    // box-sizing: border-box;
    // margin: 5px 0;
    width: 50px;
    background: transparent;
    img {
      padding: unset !important;
      height: 40px;
    }
  }
}

.bottomWrapper {
  width: 100%;
  position: relative;

  .LabelInput {
    // margin-top: 10px;

    background: #4d4d4d;
    width: 150px;
    border-radius: 5px;
    label {
      width: 80px;
    }

    // box-sizing: border-box;
    &_Input {
      width: 120px !important;
      display: flex;
      input {
        text-align: center;
        // min-width: max-content;
        background: #333;
        border-radius: 5px;
        padding: 0 5px;
        margin: 0 5px;
      }
    }

    padding: 5px 10px;
  }
}

.GenerateButton {
  padding: 7.8px 35px 8.2px;
  border-radius: 25px;
  font-size: 14px;

  background-image: linear-gradient(99deg, #e72870 5%, #e76161 93%);
}

.Deselector {
  position: absolute;
  width: 100%;
  height: 100%;
  // background: yellow;
  // cursor: pointer;
}

.LOADING {
  width: 100%;
  position: fixed;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  z-index: 99999999;

  font-size: 80px;
  // background: white;
  // backdrop-filter: blur(5px) hue-rotate(90deg);
  animation: ani 2s linear infinite;
  // font-weight: 600;
  color: white;
}
@keyframes ani {
  0% {
    // backdrop-filter: blur(5px) hue-ra;
    backdrop-filter: blur(5px) hue-rotate(0deg);
  }
  100% {
    backdrop-filter: blur(5px) hue-rotate(359deg);
  }
}
.Media {
  display: none;
}
.App {
  // width: 100vw;
  // zoom: 0.8;
  position: fixed;
  height: 100vh;
  // transition-duration: 0.5s;
  font-family: Poppins;
  display: grid;
  grid-template:
    var(--headerHeight) 1fr var(--timelineHeight) / var(--leftWidth)
    1fr var(--rigthWidth);
  // transition-duration: 0.3s;
  grid-template-areas:
    "Header Header Header"
    "LeftPanel Screen RightPanel"
    "LeftPanel Timeline Timeline";
  background: #222222;
  gap: 1px;
  width: 100vw;

  .LeftPanel {
    z-index: 2;
    @include GridPropery(LeftPanel, rgba(255, 255, 255, 0), 30px);
    font-size: 12px;
  }
  .RightPanel {
    // z-index: 2;

    @include GridPropery(RightPanel, rgb(46, 39, 39), 30px);
    padding: 20px;
    background: #333333;
    border-radius: 10px 0 0 10px;
  }
  .Screen {
    height: calc(100vh - var(--timelineHeight));
    position: relative;
    &_flex {
      flex-flow: column nowrap;
    }
    // overflow: scroll;
    // position: absolute;
    // left: 454px;
    canvas {
      // border: 6px solid green;
    }

    @include GridPropery(Screen, #24242400, 30px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    // margin: 20px;
    .Logo {
      g {
        fill: red;
        &:hover {
          fill: black;
        }
      }
    }
  }
  .TimeLine {
    @include GridPropery(Timeline, #242424, 30px);
    position: relative;
    .DisabledWrapper {
      z-index: unset !important;
    }
  }
}
body,
html {
  margin: 0;
  padding: 0;
  font-family: Poppins;
  overflow: hidden;
  user-select: none;
  // position: fixed;
  // top: 0;
}
input:-internal-autofill-selected {
  background-color: red !important;
}

.flex {
  display: flex;
}
.row1 {
  flex-flow: row nowrap;
}
.j-between {
  justify-content: space-between;
}
.j-start {
  justify-content: flex-start;
}

.CropCanvas {
  position: absolute;
  display: none;
  z-index: 1000;
}
.AvatarCropCanvas {
  position: absolute;

  display: none;
  z-index: 999;
}
.Overflow {
  // padding: 10px;
  // position: relative;
  overflow: scroll;
  // width: 977px;
  // height: 553px;
  display: flex;
  // align-items: center;
  // justify-content: center;
  // scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

body,
html {
  // overflow: unset;d
}

/* Track */
// ::-webkit-scrollbar-track {
//   // box-shadow: inset 0 0 5px grey;
//   border-radius: 10px;
// }

.ScreenLoading {
  position: absolute;
  z-index: 2;
  // transform: translate(0px,-30px);
  background: rgba(68, 68, 68, 0);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  top: 0;
  cursor: wait;
  flex-flow: column;
  img {
    transform: scale(0.7);
  }
  .Text {
    // backdrop-filter:blur(10px);
    // background-color: rgba(0, 0, 0, 0.479);
    border-radius: 100px;
    font-size: 32px;

    // box-shadow:10px 10px 20px 10px rgba(0,0,0,0.4);
  }
  // display: none;
}

.jbetween {
  justify-content: space-between;
}

.Tooltip {
  &_text {
    background: red;
  }
}

.Wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

._canvas {
  overflow: hidden;
}
