.TextSettings {
  padding: 20px;
  overflow: scroll;
  overflow-x: hidden;

  height: 100%;
  .Dimensions {
    margin-top: 10px;
  }
  .Opacity {
    margin-top: 10px;
  }
  &_variable {
    background-color: unset;
    width: 100%;
    .SelectOption_list {
      width: 100%;
      position: absolute;
    }
  }
}
.TextFormating {
  .mt10 {
    margin-top: 10px;
  }
  &_merge {
    background: #4d4d4d;
    border-radius: 5px;
    // padding: 10px;
    .EditSelect_input {
      border-bottom: none;
    }
    .SelectOption_title {
      padding: 5px 5px;
    }
  }
  &_row {
    margin-top: 10px;

    img {
      padding: 8px;
      margin: 0 5px;
      cursor: pointer;
    }
  }
  &_row2 {
    margin-top: 10px;

    img {
      margin: 0 5px;
      cursor: pointer;
    }
  }
  &_fontfamily {
    .SelectOption_title {
      padding: 3px 10px;
      background: #404040;
      border-radius: 5px;
      cursor: pointer;
      transition-duration: 0.5s;
      &:hover {
        background: #6d6d6d;
      }
    }

    .EditSelect_input {
      border-bottom: none;
    }
    input {
      width: 120px;
      cursor: pointer;
    }
    .SelectOption_list {
      height: max-content;
      overflow-y: scroll;
      max-height: 300px;
    }
  }
  &_fontsize {
    input {
      width: 50px;
    }
    .SelectOption_list {
      height: max-content;
      overflow-y: scroll;

      // max-height: 300px;

      left: 15%;
      max-height: 300px;
      width: 115px;

      padding: 0;
      &_item {
        text-align: center;
        justify-content: center;
        &:hover {
          padding: 2px 0;
          background: #404040;
        }
      }
    }
  }
}

.ColorToggle {
  position: relative;
  .Color {
    position: absolute;
    width: 200px;
    padding: 10px;
    border-radius: 10px;
    background: #404040;
    box-shadow: 0 5px 20px 10px rgba($color: #000000, $alpha: 0.2);
    top: 40px;
    left: 10px;
  }
}

.newDropDown {
  padding: 3px 10px;
  background: #404040;
  border-radius: 5px;
  cursor: pointer;
  transition-duration: 0.5s;
  &:hover {
    background: #6d6d6d;
  }
}

.font_hover {
  &:hover {
    background: #6d6d6d;
  }
}