.Music {
  padding: 20px;
  height: 100%;
  .Tabium {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
  }
}
#music_wrapper,
#music_wrapper2,
.music_wrapper2 {
  height: 94%;
  font-size: 12px;
  overflow-y: scroll;
  .infinite-scroll-component {
    overflow-y: scroll;
    // overflow: scroll !important;
    overflow-x: hidden;

    .INFINITELOAD {
      width: 320px;
    }
  }
}
