// @import "../Chemistry/Atom/Atom";
@mixin flex($flow, $jcontent, $aitems) {
  display: flex;
  flex-flow: $flow;
  justify-content: $jcontent;
  align-items: $aitems;
}
.RightPanel {
  width: 100%;
  height: calc(94.3vh - var(--timelineHeight)) !important;
  overflow-x: hidden;
  .DisabledWrapper {
    height: 100%;
  }
  .Tabium {
    margin-top: 5px;
    display: flex;
    height: 100%;
    flex-flow: column nowrap;
  }
  .music_wrapper2 {
    height: calc(100% - 70px);
  }
  .TypeScriptTab {
    width: 100%;
    height: 100%;
    // overflow-y: scroll;
    @include flex(column, flex-start, center);
    .ButtonIcon {
      margin: 15px 0;
    }
    .ListOfSpeech {
      position: relative;
      // background: pink;
      width: 100%;
      height: calc(76vh - var(--timelineHeight)) !important;

      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
  .RecordTab {
    // height: 100%;
    height: calc(100% - 90px);
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .InitState {
      height: 100%;
      width: 100%;
      // background: red;
      border: dashed 2px #4d4d4d;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column nowrap;
      label {
        color: #a9a9a9;
        font-size: 12px;
        margin: 18px 0;
      }
    }
    .RecordState {
      border: none;
      &_panel {
        display: flex;
        &_pink {
          background: #e7416a;
          margin-right: 10px;
        }
      }
      .ReactMic {
        width: 100%;
      }
    }
  }
  .OnUseComponent {
    // background: red;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    &_img {
      margin: 15px 0;
    }
    &_name {
      font-size: 16px !important;
    }
    &_label {
      color: #a9a9a9;
      font-size: 14px;
      margin: 10px 0 20px;
      text-align: center;
    }
    &_button {
      padding: 7.8px 35px 8.2px;
      border-radius: 25px;
      font-size: 14px;
      cursor: pointer;
      border: none;
      color: white;
      font-family: Poppins;
      background-image: linear-gradient(99deg, #e72870 5%, #e76161 93%);
    }
  }
}
.AvtarSpeechClose {
  position: absolute;
  top: 50%;
  z-index: 999;
  // left: ;
  cursor: pointer;
  transform: translate(-200%, -50%);
  background: #343434;
  height: 70px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 20px 0 0 20px;
}
