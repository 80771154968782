.Background {
  overflow: scroll;
  height: 90vh;
  padding: 20px;
  ._TabTop {
    margin-bottom: 20px;
  }
  .Color {
    margin-bottom: 20px;
  
  }
  // .NewColor_list{
  //   height: 35vh;
  //   overflow: scroll;
  // }
  .LabelInput {
    margin: 23px 0;
  }
  ::-webkit-scrollbar {
    // height: 12px;
    height: 0;
    width: 0px;
    background: rgba(20, 20, 20, 0.568);
  }
  .scrollableDiv {
    .infinite-scroll-component {
      // display: flex;
      // flex-flow: row wrap;
      justify-content: space-between;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}
