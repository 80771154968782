.scrollableDiv {
  overflow: scroll;
  overflow-x: hidden !important;
  .infinite-scroll-component {
    display: flex;
    flex-flow: row wrap;
    overflow-x: hidden !important;
    justify-content: space-between;
  }
  // position: relative;
}
.UploadFeedBack {
  position: fixed;
  z-index: 99;
  bottom: 40px;
  left: 40px;
  padding: 0 0 1px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
  // border: solid 1px #333;
  width: 370px;
  background-color: #333;
  &_header {
    // border: solid 1px #333;
    background-color: #222;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-flow: row;
    justify-content: space-between;
    padding: 15px 20px;
    &_img {
      height: 20px;
      // background: #333;
      cursor: pointer;
    }
  }
  &_total_info {
    font-size: 14px;
    font-weight: 500;
    color: #a9a9a9;
    padding: 20px;
    padding-top: 0;
  }
  &_list {
    padding: 5px 20px;
    max-height: 215px;
    overflow-y: scroll;
    &_item {
      justify-content: space-between;
      padding: 12px 0;
      align-items: center;
      &_name {
        // background: red;
        width: 55%;
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 20px;
      }
      &_size {
        // background: yellow;
        margin-right: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #a9a9a9;
        width: max-content;
      }
      &_progress {
        // background: blue;
        // width: 20px;
        // padding: 20px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
      }
    }
  }
}
// ::-webkit-scrollbar {
//   // height: 12px;
//   width: 0px;
//   height: 0;
//   background: rgba(20, 20, 20, 0.568);
// }

// .Animation{

//   overflow: scroll;
//   height: 90vh;

// }
