.MusicSettings {
  padding: 20px;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;

  &_loop {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    .SelectOption {
      cursor: pointer !important;
      &_title {
        padding: 0;
        .EditSelect_input {
          border-bottom: unset;
          background: #242424;
          border-radius: 20px;
          padding: 0 10px;
          input {
            cursor: pointer;
          }
        }
      }
    }
  }
  &_bound {
    // background: red;
    height: 30px;
    width: 100%;
    border-radius: 5px;
    position: relative;
    border: solid 0.5px #4d4d4d;
    background-color: rgba(51, 51, 51, 0.7);
    &_drag {
      border-radius: 5px;
      border: 1px solid #e7416a;
      border-left: 5px solid #e7416a;
      border-right: 5px solid #e7416a;
    }
  }
  &_play {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.SceneDurationAlert {
  // background: wheat;
  position: absolute;
  right: 0;
  // top: 100%;
  // bottom: 20px;
  // top: 100%;
  bottom: 100%;
  background: red;

  font-size: 14px;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px;
  color: #e6e6e6;
  background: #4d4d4d;
}
