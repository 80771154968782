.Templatess {
  padding: 20px;
  .InputIcon {
    // margin-top: 20px;
    margin-bottom: 5px;
  }
  &_video {
    // width: 53vw;
    height: 67vh;
  }
}
.TemplateScene {
  padding: 20px;
  &_mainList {
    height: 85vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  &_all {
    width: 100%;
    margin-top: 20px;
    font-size: 14px !important;
    padding: 10px 0 !important;
    height: unset !important;
  }
}
