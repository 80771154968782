body,
html {
  margin: 0;
  padding: 0;
  background: #242424;
  font-family: "Poppins";
  font-size: 14px;
  color: #e6e6e6;
  height: 100vh;
  // overflow-y: scroll;
}
