// @import "../../_App";
// @import "../Chemistry/Atom/Atom";
@mixin GridPropery($gridName, $backgroundColor, $fontSize) {
  grid-area: $gridName;
  background: $backgroundColor;
  font-size: $fontSize;
  color: #e6e6e6;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
@mixin flex($flow, $jcontent, $aitems) {
  display: flex;
  flex-flow: $flow;
  justify-content: $jcontent;
  align-items: $aitems;
}

.Header {
  .Logo {
    height: 50%;
  }
  @include GridPropery(Header, #333333, 30px);
  padding: 0 40px 0 30px;
  @include flex(row nowrap, space-between, center);
  .RightHeader_save {
    margin: 0 20px;
  }
  .RCP {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 40px !important;
  }
  .InputIcon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 25px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    .Input {
      height: 26px;
      text-align: center;
      font-size: 14px;
    }
  }
  .RightHeader {
    @include flex(row nowrap, space-between, center);
    img {
      cursor: pointer;
    }
  }
}
