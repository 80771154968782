@function vw($px-vw, $base-vw: 1920px) {
	@return ($px-vw * 100vw) / $base-vw;
}
@function vv($px-vw, $base-vw: 676px) {
	@return ($px-vw * 100vw) / $base-vw;
}

.LinkPopup {
	.PersonalisedHistory {
		.LinkHistoryTab {
			.Button {
			}
		}
	}
}

.manage-team-popup-wrapper {
	.menu-signals {
		width: 158px;
	}
	.menu-submenu-manageteam-container {
		width: 275px;
	}
}

.VariablePopup_footer {
	.Disabled-button-generate-invarPopup {
		cursor: not-allowed;
		pointer-events: none;
		border: solid 1px #a9a9a9;
		background-color: #a9a9a9 !important;
		color: #e6e6e6;
	}
}

.VariablePopup_grid .Card_name {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.PersonalisedHistory_list {
	.CopyLink_button .Button:first-child {
		margin-right: 20px;
	}
	.LinkHistoryTab {
		width: 96%;
	}
}

.PersonalisedHistory_list .LinkHistoryTab.realLinkHIstoryTab {
	width: 100%;
}

.DownloadPopup {
	width: 96%;
}

.AppHeader {
	.profile_pic_in_navbar_new {
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}
}

.BatchHistoryTab {
	.LinkHistoryTab_head_link {
		width: 50%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.campaign_url_csv_row {
	// width: 300px !important;
	min-width: 360px !important;
}
.campaign_url_csv_inner {
	// width: 300px !important;
	width: 308px !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block !important;
}

.Profile {
	width: auto;
}

.Disabled-button-generate-invarPopup {
	cursor: not-allowed;
	pointer-events: none;
	border: solid 1px #a9a9a9;
	background-color: #a9a9a9 !important;
	color: #e6e6e6;
}

.TablePopup {
	.TablePopup_column_each {
		display: flex;
		justify-content: space-between;
	}
	.TableRow_each {
		display: flex;
		justify-content: space-between;
	}
}

.playbutton_custom {
	position: absolute;

	top: 37%;
	left: 36%;
	width: 127px;
	height: 66px;
	padding: 13.7px 28.4px 12.1px 53px;
	opacity: 0.8;
	border-radius: 10px;
	border: solid 1px #000;
	background-color: #000;
}

.bxppp-item {
	.error-new-hide {
		display: none;
	}
}

.VariablePopup_grid ._DragAndDropImage {
	width: 120px;
	height: 120px;
	margin: 0;
	text-align: center;
	font-size: 12px;
	padding: 16px;
}

.playbutton-video-details {
	width: 147px !important;
	position: absolute;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
}

.AvatarChoosePopup_avatar {
	height: 78px;
}

.__List_Item {
	.ellipse-name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 9.635vw;
	}
	.del {
		position: absolute;
		width: vv(10px);
		height: vv(10px);
		top: 7px;
		right: 7px;
		display: none;
	}
}

.__List_Item.iimage {
	position: relative;
	overflow: hidden;
	&:hover {
		.del {
			display: block;
		}
	}
	.Card_per {
		top: 18%;
		left: -33%;
	}
}
.__List_Item.otherImage {
	position: relative;
	overflow: hidden;

	.Card_per {
		top: 16%;
		left: -34%;
	}
}

.PerImage {
	position: relative;
	.PerImage_option {
		display: none;
	}
	&:hover {
		.PerImage_option {
			display: block !important;
		}
	}
}

.ErrorPopup {
	width: 717px;

	.scroll_custom {
		max-height: 68vh;
		overflow-y: scroll;
	}
	.ErrorPopup_row_title {
		width: 352px;
	}
}

.how-it-works {
	display: flex;
	justify-content: center;

	video {
		width: 69%;
	}
}

.pointer-none-overlay {
	pointer-events: none !important;
}

.VariablePopup {
	.LabelInput_Input.errorInput {
		border: none;

		border-bottom: 1px solid #e7416a;
		box-shadow: none;
		position: relative;
	}
	._error_i {
		top: 0;
	}
}

.VideoDetail_detail_thumb_render {
	.VideoDetail_detail_thumb_render_title {
		font-size: 26px;
	}
	.loading_infinite {
		margin: 20px;
		width: 164px;
		height: 164px;
		-webkit-animation-name: spin;
		-webkit-animation-duration: 2000ms;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-timing-function: linear;
		-moz-animation-name: spin;
		-moz-animation-duration: 2000ms;
		-moz-animation-iteration-count: infinite;
		-moz-animation-timing-function: linear;
		-ms-animation-name: spin;
		-ms-animation-duration: 2000ms;
		-ms-animation-iteration-count: infinite;
		-ms-animation-timing-function: linear;
		animation-name: spin;
		animation-duration: 2000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		fill: #e7416a;
	}

	@-ms-keyframes spin {
		from {
			-ms-transform: rotate(0deg);
		}
		to {
			-ms-transform: rotate(360deg);
		}
	}

	@-moz-keyframes spin {
		from {
			-moz-transform: rotate(0deg);
		}
		to {
			-moz-transform: rotate(360deg);
		}
	}

	@-webkit-keyframes spin {
		from {
			-webkit-transform: rotate(0deg);
		}
		to {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
}

.MainLoad.INFINITELOAD {
	background-color: #242424;
	backdrop-filter: blur(5px);
	z-index: 16;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	align-items: center;
	height: calc(100% - 0px);
	display: flex;
	justify-content: center;
	img {
		width: 60px;
		height: 60px;
	}
}
