.ElementSetting {
  padding: 20px;
  height: 100%;
}
.ColorTabList {
  // background: red;
  // width: auto;
  // height: 100px;
  display: flex;
  padding: 15px 0;
  flex-flow: row wrap;

  &_title {
    margin-right: 10px;
  }
  &_color {
    height: 20px;
    width: 35px;
    border: solid 1px #4d4d4d;
    cursor: pointer;
  }
}
