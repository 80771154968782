.Video {
  padding: 20px;
  overflow: none !important;
  .infinite-scroll-component {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    .SelectOption_list_item_img {
      width: 15px;
    }
    .SelectOption_list {
      right: 10px;
      top: 40px;
      width: 100px;
    }
    .SelectOption {
      // left: -10px;
    }
    .HoverVideoCard_frame_up {
      left: -10px;
      top: 5px;
      position: relative;
    }
  }
}
