.Menu {
  position: fixed;

  z-index: 100000000000;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 8px 0;
  border-radius: 5px;
  //   box-shadow: ;
  box-shadow: 0 0 10px 0 #404040c2;
  cursor: pointer;
  background: #404040;

  .MenuItem {
    font-size: 16px;
    padding: 2px 10px;
    min-width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_icon {
      width: 25px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    &_cmd {
      font-weight: 200;
      font-size: 14px;
      color: #cecece;
    }
    &:hover {
      background: #e7416a;
    }
  }
}
