/* eslint-disable */
:root {
  --liner: linear-gradient(6deg, #e72870, #e76161);
  --white: #ffffff;
  font-size: 16px;
}

* {
  outline: none;
  /* font-family:'Poppins', sans-serif !important; */
}

.vjs-has-started .vjs-big-play-button {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
}

.vjs-paused .vjs-big-play-button {
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.5s;
}

.mySlider img {
  border-radius: 5px;
}

.image-selector-container-now {
  border-radius: 5px;
}

.image-selector-container-now img {
  border-radius: 5px;
}

/* 
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
	box-shadow:0 0 0 30px pink inset ;
 -webkit-box-shadow: 0 0 0 30px pink inset !important;
} */

._AlertBox {
  position: fixed;
  bottom: 1.563vw;
  right: 2.083vw;
  height: max-content;
  width: max-content;
  transition-duration: 0.5s;
  /* background: white */
}

._Alert {
  display: flex;
  margin: 0.7em 0;
  padding: 0.542vw;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(81deg, #e72870 -1%, #e76161 99%);
  justify-content: space-between;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 10px;
  min-width: 13.125vw;
}

._Alert .message {
  font-family: Poppins;
  font-size: 0.729vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

._Alert .close {
  padding: 5px;
}

.slide-in-blurred-bottom {
  -webkit-animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
  animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.slide-out-blurred-top {
  -webkit-animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
  animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
}

.slide-out-blurred-right {
  -webkit-animation: slide-out-blurred-right 0.45s cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
  animation: slide-out-blurred-right 0.45s cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
}

.slide-out-blurred-left {
  -webkit-animation: slide-out-blurred-left 0.45s cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
  animation: slide-out-blurred-left 0.45s cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
}

.slide-in-blurred-left {
  -webkit-animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
  animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.slide-in-blurred-right {
  -webkit-animation: slide-in-blurred-right 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
  animation: slide-in-blurred-right 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}

@-webkit-keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-out-blurred-left {
  0% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-1000px) scaleX(2) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
}

@keyframes slide-out-blurred-left {
  0% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-1000px) scaleX(2) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
}

@-webkit-keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-out-blurred-top {
  0% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
}

@keyframes slide-out-blurred-top {
  0% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
}

@-webkit-keyframes slide-out-blurred-right {
  0% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
    transform: translateX(1000px) scaleX(2) scaleY(0.2);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
}

@keyframes slide-out-blurred-right {
  0% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
    transform: translateX(1000px) scaleX(2) scaleY(0.2);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
}

input:-internal-autofill-selected {
  appearance: none;
  background-color: unset !important;
  background-image: none !important;
  color: unset !important;
}

#root,
html,
body {
  height: 100vh;
  /* width: 100vw; */
  margin: 0;
  background-color: #222222;
}

.logo-ai {
  padding-top: 9px;
  transition: width, left, right, 0.3s;
}

body label,
body p {
  margin: 0;
}

.link-custom {
  color: inherit;

  text-decoration: none !important;
}

.link-custom-bread {
  color: inherit;
  display: flex;
  align-items: center;
  text-decoration: none !important;
}

.link-custom:hover {
  text-decoration: none;
  cursor: pointer;
  color: inherit !important;
}

.link-custom-bread:hover {
  text-decoration: none;
  cursor: pointer;
  color: inherit !important;
}

button {
  border: none !important;
  outline: none !important;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.disp-none {
  display: none !important;
}

.edit-strip {
  /* height: 100%; */
  position: relative;
  margin-top: 0.1875rem;
  box-shadow: -0.35356rem 0.35356rem 1.125rem 0 rgba(30, 30, 30, 0.09);
  transition: 0.5s;
}

.edit-strip-inverse {
  position: fixed;
  margin-top: 0px;
  z-index: 1001;
  /* color: #212529; */
}

#mainToolbar {
  /* height: 10p 0%; */
}

.ql-toolbar.ql-snow {
  border: none !important;
  padding: 4px !important;
}

.ql-picker.ql-emailVars {
  width: 160px;
}

/* .ql-picker.ql-emailVars .ql-picker-item::before,
.ql-picker.ql-emailVars .ql-picker-label::before {
	content: "Custom";
} */
/*
.ql-picker.ql-emailVars [data-value="1"]::before {
	content: attr(data-label)
	content: "{AccountURL}";
}

.ql-picker.ql-emailVars [data-value="2"]::before {
	content: "{FirstName}";
}

.ql-picker.ql-emailVars [data-value="3"]::before {
	content: "{Login}";
}

.ql-picker.ql-emailVars [data-value="4"]::before {
	content: "{OrganizationName}";
}

.ql-picker.ql-emailVars [data-value="5"]::before {
	content: "{SupportEmail}";
} */

.toolbar-custom {
  z-index: 9999999999;
}

.video-thumbnail-preview {
  width: 100%;
  margin: auto !important;
}

.video-thumbnail {
  /* height: 300px; */
  width: 100%;
  background: #a7a1a1;
  margin: auto !important;
  color: #fff;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.fa-play-circle {
  font-size: 60px;
}

.fa-trash-alt {
  margin: 5px;
}

.toolbar-custom {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 60px;
  transition: top 0.2s linear;
  border: solid 1px #4d4d4d;
  background-color: #333333;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.rc-tabs-nav-wrap {
  color: #212529 !important;
}

.toolbar-custom-editor {
  /* transition: border-width 0.6s linear; */
  position: relative;
  margin: auto !important;
  width: fit-content;
  display: block !important;
}

.custom-border:hover {
  outline: solid 0.7px #e7416a;
  box-sizing: border-box;
}

.custom-border:hover .custom-content-name-hover {
  display: flex;
}

.scroll-custom {
  background-color: #212529;
  color: #fff;
  top: 0;
}

.custom-add-button-single {
  height: 200px;
  width: 600px;
  background: rgb(165, 151, 151);
  margin: auto;
}

.editor-input {
  width: 93%;
  border-radius: 25px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #242424;
  outline: none;
  border: none;
  padding: 9px 20px 9px 51px;
  margin: 0px 20px 0px 0px;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.editor-input.rename {
  padding: 10px 16px;
  margin: 0px 20px 0px 0px;
}

.editor-input-lable {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-input-container {
  /* padding-top: 10px;
	margin-right: 20px; */
}

.custom-border {
  /* padding: 10px; */
  position: relative;
  display: inline-block;

  margin: 5px;
}

.custom-trash,
.custom-trash-text,
.custom-trash-carosel {
  /* display: none; */
  /* background: #9400ff; */
  color: white;
  /* padding: 2px; */
  border-radius: 50%;
  height: 25px;
  width: 25px;
}

.button-trash,
.custom-trash-text {
  position: absolute;
  top: -19px;
  right: -12px;
}

.custom-trash-carosel {
  position: absolute;
  top: -29px;
  right: 0px;
}

.custom-trash-text,
.custom-trash-carosel {
  display: none;
}

.custom-content-name-hover {
  display: none;
  position: absolute;
  top: -19px;
  left: -3px;
  width: fit-content;
  height: 18px;
  padding: 0 4px;
  border: solid 1px #e7416a;
  background-color: rgba(231, 65, 106, 0.5);
  font-family: "Poppins";
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;

  justify-content: center;
  align-items: center;
  letter-spacing: 0.3px;
  text-align: center;
  color: #e7416a;
}

.custom-content-name {
  display: flex !important;
  position: absolute;
  top: -19px;
  left: -3px;
  width: fit-content;
  height: 18px;
  padding: 0 4px;
  border: solid 1px #e7416a;
  background-color: #e7416a;
  font-family: "Poppins";
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.3px;
  text-align: center;
  color: #e6e6e6;
}

.custom-border {
  border: none;
  margin-left: 2px;
  margin-right: 2px;
  /* border: 1px solid #000 !important; */
}

.salespage-template-editor-container {
  /* padding: 15px; */
  padding-right: 0 !important;
  padding-left: 0 !important;
  background: #222222;
  padding-top: 9vh;
}

.ql-container.ql-snow {
  border: none !important;
}

.contact-icons {
  /* padding: 4px; */
  /* width: 44px;
	height: 44px; */
  margin: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
}

.contact-icons img {
  width: 100%;
  height: 100%;
}

.custom-trash-icon {
  display: none;
  /* background-color: #9400ff; */
  border-radius: 50%;
  position: absolute;
  top: -1.9rem;
  right: -27px;
  /* font-size: 1rem; */
  /* width: 2.5rem;
	height: 2.5rem; */
  z-index: 9999;
}

.button-trash {
  z-index: 9999;
}

.button-trash {
  position: absolute;
  top: -28px;
  right: 3px;
}

/* .custom-trash-icon svg{
	width: 100%;
	height: 100%;
} */

.toolbar-custom-editor .custom-trash-text {
  position: absolute;
  top: -28px;
  right: 3px;
}

.sales-preview-editor {
  background-color: #ffffff;
}

.alice-carousel {
  background-color: #dddcdc;
}

.alice-carousel__stage-item {
  padding: 8px;
  margin: 24px;
}

.crousel-image {
  transition: transform 0.2s;
  border-radius: 5px;
  cursor: pointer;
  /* Animation */
}

/* .crousel-image:hover {
  
 } */
.image-crousal-container {
  margin-top: 20px;
}

.icon-tray {
  justify-content: end;
  line-height: 0;
}

.disBlock {
  display: block !important;
}

.bdDashed {
  outline: solid 0.6px #e7416a !important;
}

.inactiveLink {
  pointer-events: none;
  cursor: default;
  /* margin: 0px; */
  text-transform: none !important;
}

.inactiveLink * {
  pointer-events: none;
  cursor: default;
  /* margin: 0px; */
  text-transform: none !important;
}

.linkContainer {
  display: inline-block;
  margin: 0 20px 20px 0;
  border-radius: 24px;
}

.linkContainer:last-child {
  margin: 0 0px 20px 0;
}

.trash-icon {
  display: none;
  position: absolute;
  bottom: -50px;
  left: -4px;
  z-index: 999;
}

.manage-content-sales {
  display: none;
  position: absolute;
  bottom: -50px;
  left: -4px;
}

.logo-nav-video svg {
  width: 261px;
}

.logo-nav-video {
  height: auto;
  display: flex;
  align-items: center;
  margin-left: 18px;
}

.manage-content-sales button,
.btn-manage-content {
  padding: 0 16px;
  width: auto;
  height: 40px;
  background-color: #e7416a;
  border-radius: 25px;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ql-editor {
  padding: 0px !important;
  overflow: hidden;
}

.editor4 {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: 0.48px;
  text-align: center;
  color: #ffffff;
}

.text-editor {
  min-width: 400px;
}

.text-editor p {}

.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  margin: auto;
  /* border: 1px solid #000; */
}

.nav-gp1 {
  display: flex;
  justify-content: space-evenly;

  margin-left: 10px;
  margin-right: 10px;
  width: 150px;
  font-size: 15px;
}

.undo,
.redo {
  font-size: 14px;
}

.add-button-select-con {
  width: 417px;
  height: 236px;
  /* margin: 0 0 35px 41.4px; */
  margin: auto;
  padding: 27px 25px 28px;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #e6e6e6;
}

.add-button-select {
  width: 359px;
  height: 180px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: dashed 1px #333333;
  background-color: #e6e6e6;
  color: #333333;
  font-size: 2.5rem;
}

.nav-gp1 {
  /* margin: 10px; */
  text-align: center;
}

.nav-gp2 {
  /* text-align: center; */
  padding: 10px 0 0 0;
  width: 100%;
  font-size: 15px;
}

.nav-gp-2 {
  padding: 17px 0 0 0;
}

.nav-gp-button {
  margin: 0;
}

.nav-gp-button .nav-lv1-div {
  margin: 0px !important;
}

.nav-gp-button,
.nav-gp-2 {
  display: flex;
  justify-content: space-evenly;
  margin-left: 10px;
  margin-right: 10px;
}

.nav-gp-button .nav-lv1-div,
.nav-gp-button label,
.nav-gp-button input,
.button-color,
.button-text-color {
  margin-left: 10px;
  margin-right: 10px;
}

.undo-redo {
  width: 30%;
  max-width: 136px;
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  text-align: center;
}

.undo-redo label,
.nav-gp-button label {
  text-align: center;
  margin-bottom: 0;
  padding: 7px 0;
}

.nav-gp-button .nav-lv1-div {
  /* border-right: 1px solid rgb(131, 131, 131); */
}

.change-size {
  display: flex;
  margin: 0 !important;
}

.range i {
  width: 10%;
  padding: 13px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.range input {
  width: 63%;
}

.range {
  border: none !important;
  display: flex;
}

.styling-crousel div {
  outline: none;
  /* padding: 5px; */
}

.css-1yn2e29-InputColor {
  padding: 0 !important;
  border: solid 1px #e6e6e6 !important;
  border-radius: 0 !important;
  width: 38px !important;
  height: 20px !important;
}

.btn-custom-add-button-container {
  width: 114px;
  height: 40px;
  margin: 0px;
  padding: 5px 7px;
  border-radius: 20px;
  border: solid 1px #a9a9a9;
  background-color: #e6e6e6;
}

.btn-custom-add-button {
  width: 98px;
  height: 28px;
  padding: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  border: solid 1px #a9a9a9;
  background-color: #e6e6e6;
  font-size: 10px;
}

#ParentsToolBar {
  padding: 0px;
}

.disabled-undo-redo {
  pointer-events: none;
  color: #a7a1a1;
}

/* square buttons */
.rec.rec-arrow {
  border-radius: 0;
}

/* round buttons on hover */
.rec.rec-arrow:hover {
  border-radius: 50%;
}

/* hide disabled buttons */
.rec.rec-arrow:disabled {
  visibility: hidden;
  display: none;
}

/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
  outline: none;
}

.jsGVec {
  outline: none !important;
  background: none !important;
  box-shadow: none !important;
  color: #000000 !important;
}

.styling-crousel {
  /* max-width: 1300px; */
  margin: auto;
}

.mb-cus {
  padding: 0;
  padding-bottom: 73px;
  margin: 1rem;
  margin-top: 70px;
  margin-bottom: 73px;
}

.change-actions {
  padding: 10px 0;
}

.change-image {
  margin: 0 !important;
}

.icon-url {
  margin: 0 !important;
}

.button-color,
.button-text-color {
  display: flex;
  padding: 4 px 0;
}

.gap-nav-gp2 {
  padding: 4px 0 0 0;
}

.btn-custom-select {
  background-color: #0d1212 !important;
  color: #faffff !important;
  margin: 0 33px 0 0px !important;
  padding: 15.5px 73px 15.4px 73.2px !important;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
}

.btn-secondary-custom {
  color: #000000 !important;
  margin: 23px 20px 0 177px;
  padding: 15.5px 84px;
  border: solid 1px #0d1212;
  background-color: #faffff;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 3.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.edit-del-gp {
  display: flex;
}

.file-select-container {
  text-align: center;
  margin: 0 20px 0 0;
}

.image-selector-container:focus,
.select-image-in-carosel:focus,
.selected-image {
  border: 2px solid #000;
}

.image-selector-container {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.select-image-in-carosel {
  padding: 5px;
}

.nav-lv1-div {
  /* margin: 9px 0px 9px 0px !important; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-image-buttonn {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  margin: 0px 20px 0px 0px;
  color: #e6e6e6;
  border-radius: 25px;
  background-image: linear-gradient(80deg, #e72870 1%, #e76161);
  width: 180px;
  height: 2.5rem;
}

.image-selector-container img {
  height: 200px;
  width: 300px;
}

.fa-times {
  cursor: pointer;
  font-size: 31px;
}

.select-image-in-carosel-now {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  max-height: 300px;
  max-width: 491.6px;
  margin: auto;
}

.image-selector-container-now {
  margin-bottom: 1rem;
  width: 417px;
  height: 234px;

  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
}

.crousel-image {
  width: 100%;
  height: 100%;
  max-height: 263.9px;
  object-fit: fill;
}

/* RC TABS START */
.select-upload-tab .rc-tabs {
  border: none !important;
}

.select-upload-tab .rc-tabs-tab {
  padding: 5px;
  margin: 5px !important;
  margin-bottom: 0 !important;
  text-transform: capitalize;
}

.select-upload-tab .rc-tabs-tab .rc-tabs-tab-btn {
  font-weight: normal;
  outline: none;
}

.select-upload-tab .rc-tabs-tab.rc-tabs-tab-active {
  background: #ffffff;
  color: #000000;
}

.select-upload-tab .rc-tabs-ink-bar {
  background: #000000 !important;
}

.select-upload-tab .rc-tabs-content-holder {
  padding: 10px;

  background: #ffffff;
}

/* RC TABS STOP */

.nohover-border:hover {
  outline: none !important;
  border: none !important;
}

.custom-search {
  padding: 15px;
  width: 100%;
  padding-left: 35px;
}

.uploading-container {
  position: absolute;
  bottom: 29px;
  z-index: 150;
  max-width: 370px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #333333;
  background-color: #333333;
}

.uploading-header {
  padding: 13px;
  height: 50px;
  border: solid 1px #333333;
  background-color: #222222;
}

.uploading-header p {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.5px;
  text-align: left;
  color: #faffff;
}

.progress-bar-custom {
  height: 20px !important;
  width: 20px !important;
  margin-left: 10px;
}

.progress-bar-custom svg {
  height: 20px !important;
  width: 20px !important;
}

.uploading-content {
  display: flex;
  width: 100%;
  min-width: 370px;
  height: 50px;

  padding: 13px;
  justify-content: space-between;
  align-items: center;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.merge-modal-container.modal-dialog-scrollable {
  position: relative;
  max-width: 785px;
}

.rc-tabs-tabpane {
  outline: none !important;
}

.disabled-button {
  pointer-events: none;
  cursor: default;
}

.mobile-view-carosel {
  border: 1px solid #ececec;
  display: none;
  margin: 10px 0;
}

.mobile-view-carosel img {
  height: 164px;
  min-width: 298px;
  max-width: 298px;
  width: 100%;
  margin-right: 40px;
}

.mobile-view-carosel p,
.mobile-view-carosel a {
  /* width: 70%; */
  display: flex;
  align-items: center;

  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: center;
  white-space: nowrap;
  padding: 6px 4px 6px 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preview-container-wrapper .mobile-view-carosel {
  box-shadow: 0 0 20px 0 rgba(11, 52, 89, 0.16);
  border: 1px solid #ececec;
  display: flex;
  margin: 10px;
}

.preview-page-container {
  position: relative;
  /* height: 100vh; */
}

.preview-container-wrapper .preview-page-container {
  position: relative;
  height: 94vh;
  overflow-y: auto;
}

.tools-in-preview {
  position: fixed;
  bottom: 3px;
  /* border: 1px solid #000; */
  padding: 10px;
}

.preview-page-container .desktop-crousel {
  border: none !important;
  outline: none !important;
}

.carousel-edit-btn-disabled {
  width: 96px;
  height: 2.5rem;
  margin: 5px 20px 5px 0;
  padding: 8.2px 12px 8.8px;
  border-radius: 25px;
  border: solid 1px #a9a9a9 !important;
  background-color: #323232;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.carousel-edit-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 96px;
  height: 2.5rem;
  margin: 5px 20px 5px 0;
  padding: 8.2px 12px 8.8px;
  border-radius: 25px;
  border: solid 1px #e7416a !important;
  background-color: #323232;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e7416a;
}

.desktop-crousel {
  position: relative;
  display: block !important;
  padding: 1.9rem 0px 1.9rem 0px;
  margin: 0 0 1.9rem 0;
}

.desktop-crousel .slick-slide {
  width: fit-content !important;
  margin: 0 16px 0 0 !important;
}

.desktop-crousel .slick-slide:first-child {
  margin: 0 16px 0 16px !important;
}

.upload-list {
  overflow: auto;
}

.navVideoSetting {
  height: 50px;
  display: flex;
  align-items: center;
  margin: 0 0;
  /* padding: 15px 0 0 0.5px; */
  background-color: #242424;
}

.vd-toolbar {
  padding: 4px 9px;
  border: solid 1px #4d4d4d;
  background-color: #333333;
  justify-content: center;
}

.toolbar-gradient {
  border-top: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to right, #ea1f72, #d86666);
}

.setting-vd {
  height: 100%;
  margin: auto;
  background-color: #333333;
  max-height: 98vh;

  /* overflow-y: scroll; */
}

.toolbar-width-5 {
  background-color: #333333;
  width: 39.375% !important;
  max-width: 39.375% !important;
}

.toolbar-width-7 {
  background-color: #333333;
  width: 60.625% !important;
  max-width: 60.625% !important;
  margin-right: 0;
}

.toolbar-width-7-bg {
  background-color: #333333;
  width: 60.625% !important;
  max-width: 60.625% !important;
}

/* width */

.container-fluid.setting-vd-container {
  max-width: 768px;
  width: 100%;
  height: 100%;
  padding: 0 38px;
  /* margin: 0 50px 0 50px;*/
}

.preview-vd-space {
  background-color: #222222;
  height: 100%;
}

.avatar-selection-setting {
  width: 120px;
  height: 120px;
  border-radius: 4px;
  border: solid 1px #333333;
  margin: 0px;
}

.modal-avatar-select {
  background-color: #333333;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.setting-vd-container .col-md-12 {
  padding: 0;
  /* padding-right: 6px; */
}

.vd-modal-header {
  border: none !important;
  background-color: #333333 !important;
  font-size: 20px;
  padding: 5px;
}

.vd-modal-content {
  height: 75vh;

  border-radius: 5px;
  border: solid 1px #707070;
  color: #e6e6e6;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
}

.vd-avatar-select {
  padding: 9.8px 0;
  margin: 10px 0;
  margin-left: 0;
  padding-left: 0;
}

.vd-selection-container {
  margin: 0px 0 18.8px;
  border-right: solid 2px #4d4d4d;
  padding: 0 !important;
}

.col-md-7-thumb.vd-selection-container {
  margin: 0px 0 -0.2px;
  border-right: solid 2px #4d4d4d;
  padding: 0 !important;
}

.images-vd-container {
  max-width: 400px;
  padding-bottom: 20px;
  border-bottom: solid 2px #4d4d4d;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
}

.sound-vd-setting {
  width: 60px;
  height: 60px;
  margin: 0 0 5px;
  padding: 17.4px 14.8px 16.7px 14.6px;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
}

.selected-sound {
  background-image: var(--liner);
}

.vd-voice-lable {
  margin-bottom: 20px;
  margin-top: 10px;
}

.voice-single-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 60px;
}

.voice-vd-carosel .rec-arrow-right,
.voice-vd-carosel .rec-arrow-left {
  color: #fff !important;
  font-size: 1rem;
}

.vd-modal-footer {
  padding: 15px 2.5rem 1rem 2.5rem;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
  border-top: solid 1px #707070 !important;
  background-color: #333333;
  display: flex;
  justify-content: space-between !important;
  align-items: unset;
}

.vd-avatar-select-btn {
  min-width: 160px;
  max-width: 182px;
  height: 2.5rem;
  outline: none !important;
  color: #e4e9ef;
  margin: 0 0 0px 2.5rem;
  border: none;
  border-radius: 25px;
  background-image: linear-gradient(77deg, #e72870 -4%, #e76161);
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel-vd-avatar-btn {
  width: 46px;
  height: 17px;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #e7416a;
  background: none !important;
  outline: none !important;
  box-shadow: none !important;
  border: none;
}

.preview-avatar-vd {
  margin: auto;
  width: 100%;
  /* height: 70%; */
  border-radius: 5px;
  border: solid 1px #333333;
  /* background-color: #ffffff; */
}

.vd-avatar-preview-btn {
  width: 120px;
  height: 1.9rem;
  margin: auto;
  color: #e4e9ef;
  padding: -1px 18.7px 8px 22px;
  border-radius: 25px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #e7416a;
  background-image: linear-gradient(77deg,
      #e72870 0%,
      #e7406a 42%,
      #e76161 98%);
}

.vd-avatar-preview-btn img {
  margin-left: 5px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.selected-Csv-camp {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.11);
  outline: solid 5px #e7416a;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin-left: 20px;
}

.switch .switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #242424;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.mails-container {
  overflow-y: scroll;
}

.slider:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 25px;
  height: 25px;
  margin: 0 0 0 1px;
  border-radius: 28px;

  border: solid 1px #a4a4a4;
  background-color: #a9a9a9;

  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-input:checked+.slider {
  background-color: #242424;
}

.switch-input:focus+.slider {
  box-shadow: 0 0 1px #242424;
}

.switch-input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-image: linear-gradient(53deg, #e72870 5%, #e76161 88%);
  box-shadow: 0 0 10px 0 rgba(231, 65, 106, 0.4);
  border: solid 1px #e7416a;
}

.add-logo-container {
  width: 171px;
  height: 82px;
  padding: 21.5px 72.7px 28.5px 73.3px;
  border-radius: 5px;
  border: dashed 1px #333333;
  background-color: #242424;
  color: #e4e9ef;
  font-size: 25px;
}

.vd-setting-contain {}

.logo-auto-gen {
  width: 10%;
}

.add-music {
  width: 100%;
  max-width: 106px;
  max-height: 106px;
  /* margin: 0 34px 42px 0; */
  padding: 14px;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
  cursor: pointer;
}

.add-music-container {
  width: 78px;
  height: 78px;
  padding: 19.5px 0 26.5px 0;
  border-radius: 5px;
  border: dashed 1px #333333;
  background-color: #242424;
  color: #e4e9ef;
  font-size: 25px;
}

.Csv-vd-setting {
  width: 100%;
  max-width: 106px;
  max-height: 106px;
  /* margin: 0 0 6px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 38.8px 0 37.9px 0;
  border-radius: 4px;
  border: solid 1px #333333;
  background-color: #242424;
  position: relative;
}

.bgMusic-vd-setting {
  width: 100%;
  max-width: 106px;
  max-height: 106px;
  /* margin: 0 0 6px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 38.8px 0 37.9px 0;
  border-radius: 4px;
  border: solid 1px #333333;
  background-color: #242424;
  position: relative;
}

.bgMusic-single-container {
  width: 106px;
}

.selected-bgMusic {
  background-image: var(--liner);
}

.bgmusic-container .infinite-scroll-component {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(106px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(106px, 1fr));
  row-gap: 27px;
  column-gap: 27px;
}

.bgMusic-single-container {
  margin: 0;
  text-align: center;
  color: #e4e9ef;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.settingMainContainer {
  position: relative;
  height: 100%;
}

.music-player {
  color: #e4e9ef;
  border-top: solid 1px #4d4d4d;
  padding-top: 0px;
}

.templateSetting-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 34px;
  column-gap: 34px;
  margin: 0 -40px;
  padding: 0 40px;
  max-height: 78vh;
  overflow-y: scroll;
  padding-top: 14px;
}

.templateSetting-single-container {
  width: 100%;
  height: 100%;

  cursor: pointer;
}

.templateSetting-single-container img {
  width: 100%;
  height: 100%;

  border-radius: 5px;
}

.templateSetting-single-container div {
  width: 100%;
  height: 100%;

  border-radius: 5px;
}

.templateSetting-vd-setting {
  width: 106px;
  height: 106px;
  /* margin: 0 0 6px; */
  padding: 38.8px 38.5px 37.9px 32.2px;
  border-radius: 4px;
  border: solid 1px #333333;
  background-color: #242424;
}

.selected-templateSetting {
  box-shadow: 0 0 15px 0 rgba(250, 0, 106, 0.4);
  outline: solid 5px #e7416a;
}

.info-logo-new {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.infologo-content {
  z-index: 9999999999 !important;
}

.info-logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 10px; */
}

.loading-complete-details-lable {
  margin: 10px 0 20px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.minimise-details-sidebar {
  width: 103px;
  height: 40px;
  margin: 20px auto 0px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: #333333;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.info-logo img {
  cursor: pointer;
}

.info-logo-new img {
  position: static;
  cursor: pointer;
}

.textOnVideo-input {
  width: 100%;
  height: 100px;
  margin: 20px 0 0;
  padding: 20px 28.9px 32px;
  background-color: #242424;
  color: #e4e9ef;
}

.add-mergeTag {
  width: 114px;
  height: 40px;
  display: inline-block;
  /* margin: 0 20px 0 0; */
  padding: 5px 7px;
  border-radius: 20px;
  border: solid 1px #333333;
  background-color: #242424;
  cursor: pointer;
}

.add-mergeTag-container {
  width: 98px;
  height: 28px;
  padding: 6.7px 44.6px 9.3px 43.4px;
  border-radius: 14px;
  border: solid 1px #333333;
  background-color: #242424;
  color: #e4e9ef;
  font-size: 10px;
}

.iDfeOS {
  margin: 0 !important;
}

.avatarSpeech-merge-tag button.sc-bdVaJa {
  width: 12px;
  min-width: 12px;
  margin-right: 7px;
}

.avatarSpeech-merge-tag .rec-carousel-item {
  margin: 0 14px 0 0px;
}

.mergeTag {
  /* width: 109.9px; */
  height: 40px;
  padding: 0 1rem;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: solid 1px #e6e6e6;

  cursor: pointer;
}

.avatarSpeech-merge-tag {
  margin-top: 29.5px;
}

.avatarSpeech-merge-tag .rec-arrow-right,
.avatarSpeech-merge-tag .rec-arrow-left {
  color: #e7416a !important;
  font-size: 1rem;
}

.textInSpeech {
  width: 100%;
  max-height: 63vh;
  font-family: "Poppins";
  color: #ececec;
  margin-top: 25px;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
  /* overflow: auto; */
  min-height: 370px;
  padding: 20px;
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.mergeTagCustomization {
  display: flex;
  flex-direction: column;
}

.mergeTagCustomization input {
  height: 2.5rem;

  padding: 9px 0 11px 1rem;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #4c4f4f;
  background-color: #242424;
  color: #e6e6e6;
}

.mergeTagCustomization label {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 0;
  margin-top: 20px;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.mergeTagCustomContainer {
  margin: 20px;
  margin-left: 0;
}

.mergeTrigger {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6 !important;
}

.mergeTrigger span {
  color: #e7416a;
}

.avatarLayout_arrow_box {
  box-shadow: 0 0 10px 0 rgba(248, 16, 97, 0.4);
  background-image: linear-gradient(66deg, #e72870, #e76161 98%);
}

.avatarLayout_arrow_box:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(213, 28, 77, 0);
  border-top-color: #e7416a;
  border-width: 8px;
  margin-left: -8px;
}

.avatarLayout {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 102px;
  width: 33.333%;
  border-radius: 0px 0 0 0;
  height: 60px;
  /* padding: 7.8px 33.2px 11.2px; */
  background-color: #242424;
  color: #e6e6e6;
  /* margin: 5px; */
  cursor: pointer;
}

.avatarLayout:first-child {
  align-items: flex-end;
  border-radius: 6px 0 0 6px;
}

.avatarLayout:last-child {
  border-radius: 0px 6px 6px 0;
}

.avatar-position-custom .avatarLayout img {
  max-width: 37px;
}

.avatarLayout.Pos {
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 103px;
  width: 33.333%;
  border-radius: 0;
  height: 30px;
  /* padding: 7.8px 33.2px 11.2px; */
  background-color: #242424;
  color: #e6e6e6;
  /* margin: 5px; */
  cursor: pointer;
  border: solid 1px #333333;
  border-top: 0;
  border-left: 0;
}

.avatarLayout.Pos:last-child {
  border: none;
}

.avatarLayout.Pos.canDis {
  background-color: #2c2c2c;
  pointer-events: none;
  cursor: default;
}

.avatarLayout.Pos.canDis path {
  fill: #7b7b7b;
  stroke: #7b7b7b;
}

.layout-avatar-custom {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 9.8px 30px 10px 0px;
}

.layout-avatar-custom.avatar-position-custom {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 9.8px 0px 10px 30px;
}

.avatar-position-custom .avatarLayout {
  padding: 4px 24.2px 5px 24px;
}

.avatar-edit-pos {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6 !important;
  margin: 0 0 20px 0;
}

.react-tabs__tab {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.react-tabs__tab {
  display: flex !important;
  justify-content: space-evenly;
  /* border: solid 1px #cab4b4 !important; */
}

.react-tabs__tab-list {
  border-bottom: 1px solid #4d4d4d !important;
  margin: 0 0 10px;
  padding: 0;
  display: flex;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid #4d4d4d !important;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 11px 12px !important;
  cursor: pointer;
  color: #e4e9ef !important;
  height: 50px !important;
  max-width: 169px;
  min-width: 115px;
  width: 100%;
  background: #242424;
}

.react-tabs__tab--selected {
  box-shadow: none !important;
  background: #333333 !important;
  border: none !important;
  outline: none !important;
  border-top: 4px solid !important;
  border-image: linear-gradient(92deg, #ea1f72 1%, #d86666) !important;
  border-image-slice: 1 !important;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e7416a !important;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, 0.2);
}

.tabLabel {
  font-family: "Poppins", sans-serif;
}

.tabLabel::after,
.react-tabs__tab--selected::after {
  border: none !important;
  outline: none !important;
  display: none !important;
  visibility: hidden !important;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: inline-block;
  width: 100%;
}

.react-tabs__tab--selected .tabLabel .ntsvg,
.tabLabel .selectedSvg {
  display: none;
}

.tabLabel .ntsvg,
.react-tabs__tab--selected .tabLabel .selectedSvg {
  display: inline-block;
}

/* RC TABS STOP */
.search-avatar {
  position: relative;
}

.svgWrapper {
  position: absolute;
  top: 6px;
  left: 15px;
}

.search-avatar.search-avatar-library .svgWrapper {
  position: absolute;
  top: 14px;
  left: 15px;
}

.searchBox {
  text-indent: 1.9rem;
  margin-top: 0px;
  max-width: 428px;
  width: 100%;
  height: 2.5rem;
  padding-left: 10px;
  border-radius: 20px;
  border: solid 1px #333333;
  background-color: #242424;
  font-family: "Poppins", sans-serif;
  color: #e6e6e6;
}

.my-upload-select {
  width: 182px !important;
  height: 2.5rem !important;
  margin-top: 10px !important;
  margin-right: 20px !important;
  color: #e4e9ef !important;
  border-radius: 20px !important;
  border: solid 1px #333333 !important;
  padding: 5px 9px 5px 1rem;
  outline: none !important;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;

  background: url("./selectArrow.svg") no-repeat right #242424;
  -webkit-appearance: none;
  background-position-x: 90%;
}

.my-upload-select-camp {
  /* width: 61% !important; */
  height: 2.5rem !important;
  margin: 0px 0px 20px 0px !important;
  color: #e4e9ef !important;
  border-radius: 20px !important;
  border: solid 1px #333333 !important;
  padding: 5px 9px 5px 1rem;
  outline: none !important;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;

  background: url("selectArrow.svg") no-repeat right #333333;
  -webkit-appearance: none;
  background-position-x: 97%;
}

.input-camp-gp.mail-clint {
  width: 100% !important;
}

.my-upload-select-camp.mail-clint {
  width: 100% !important;
  height: 2.5rem !important;
  margin: 0px !important;

  color: #e4e9ef !important;
  border-radius: 20px !important;
  border: solid 1px #333333 !important;
  padding: 5px 9px 5px 1rem;
  outline: none !important;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;

  background: url("selectArrow.svg") no-repeat right #333333;
  -webkit-appearance: none;
  background-position-x: 97%;
}

.bgImgAvatar img {
  width: 100%;
  height: 100%;
  max-width: 218px;
  max-height: 120px;
  border-radius: 5px;
}

.video-avbg {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 120px;
  border-radius: 5px;
}

.video-avbg video {
  width: 100%;
  height: 100%;
  object-fit: fill !important;
  border-radius: 5px;
  max-height: 120px;
}

.video-textspeech {
  width: 100%;
  height: 100%;
  max-width: 316px;
  max-height: 162px;
  border-radius: 5px;
}

.video-textspeech video {
  width: 100%;
  height: 100%;
  /* max-height: 155px !important; */
  object-fit: fill !important;
  border-radius: 5px;
  max-height: inherit;
}

.selected-svg-av-vid {
  box-shadow: 0 0 15px 0 rgba(250, 0, 106, 0.4);
  border: solid 5px #e7416a;
  /* display: none; */
  position: absolute;
  background-color: rgba(43, 41, 41, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.selcted-bgVidAvatar {
  /* display: block; */
  position: relative;
}

.bgImgAvatarContainer {
  /* height:651px; */
  /* overflow-y:auto; */
}

.image-preview-popup {
  width: 60vw;
  height: 60vh;
  cursor: pointer;
}

.image-preview-popup img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.infinite-scroll-component__outerdiv {}

.bgImgAvatarContainer .infinite-scroll-component {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 11px;
  column-gap: 11px;

  overflow: inherit !important;
  margin: 0 -35px;
  padding: 0 35px;
  grid-auto-rows: 145px;
}

.bgImgAvatarContainer .infinite-scroll-component::-webkit-scrollbar {
  margin-left: 5px;
  /* width: 0; 
     background: transparent;   */
}

.list-gp-campaign {
  overflow-y: scroll;
  height: 61vh;
}

.list-merge-invideo {
  overflow: auto;
}

.add-logo {
  /* display: inline-block; */
  float: left;
  width: 199px;
  height: 110px;
  padding: 14px;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
  margin: 0;
  cursor: pointer;
}

.bgImgAvatar {
  float: left;
  /* display: inline-block; */
  width: 100%;
  max-width: 218px;
  max-height: 143px;
  border-radius: 5px;
  border: solid 1px #333333;
  margin: 0;
  cursor: pointer;
}

.setting-vd-container {}

.arrow-svg-slider {
  background-color: #333333;
}

.loadMore {
  width: 100px;
  height: 25px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: #242424;
  margin: auto;
  margin-top: 10px;
  outline: none !important;
  border: none !important;
}

.loadMore i {
  margin-left: 5px;
}

.snapshot-container {
  /* border-bottom: solid 1px #4d4d4d;
	padding-top: 1.9rem;
	padding-bottom: 1.9rem;
	margin-bottom: 1.9rem; */
}

.link-snapshot-container {
  color: #e4e9ef;
}

.link-snapshot {
  width: 100%;
  max-width: 460px;
  height: 2.5rem;
  margin: 24px 0px 0 0;
  padding: 9.2px 0px 10.8px 15.7px;
  border-radius: 20px;
  border: solid 1px #333333;
  background-color: #242424;
  outline: none !important;
  color: #e4e9ef;
}

.link-snapshot-disabled {
  width: 100%;
  max-width: 460px;
  height: 2.5rem;
  margin: 24px 0px 0 0;
  padding: 9.2px 0px 10.8px 15.7px;
  border-radius: 20px;
  border: solid 1px #333333;
  background-color: #242424;
  outline: none !important;
  color: #e4e9ef;
  cursor: not-allowed;
  pointer-events: none;
}

.link-snapshot-btn {
  width: 150px;
  height: 2.5rem;
  margin: 24px 0px 0 0;
  padding: 8px 52.1px 9px 51.9px;
  border-radius: 28px;
  border: solid 1px #e7416a;
  color: #e6e6e6;
  background-image: linear-gradient(79deg, #e72870 1%, #e76161 92%) !important;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.link-snapshot-btn-disabled {
  width: 150px;
  height: 2.5rem;
  margin: 24px 0px 0 0;
  padding: 8px 52.1px 9px 51.9px;
  border-radius: 28px;
  cursor: not-allowed;
  pointer-events: none;
  border: solid 1px #a9a9a9;
  background-color: #a9a9a9;
  color: #e6e6e6;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.toolbar-content-container {
  display: flex;
}

.gp-toolbar-2,
.gp-toolbar-1 {
  display: flex;
  justify-content: center;
  /* border-right: 1px solid #4d4d4d; */
  text-align: center;
  /* margin: 10px;
  margin-top: 12px; */
}

.gp-toolbar-2 {
  justify-content: flex-end;
  width: 70%;
  margin-right: 0;
}

.gp-toolbar-1 {
  /* padding-left: 10px; */
  width: 30%;
}

.toolbar-content {
  /* border-bottom: 4px solid rgb(235, 71, 71); */
  position: relative;
  color: #e72870;
}

.selectedArr svg {
  position: absolute;
  left: 36%;
  bottom: -9px;
  font-size: 25px;
}

.selected-toolbar .toolbar-tab .toolbar-lable-avatar {
  text-shadow: 0 0 10px rgba(248, 16, 97, 0.4);
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e72870;
  padding: 0 !important;
  cursor: pointer;
}

.avatarLayout_selected {
  box-shadow: 0 0 10px 0 rgb(248 16 97 / 40%);
  background-image: linear-gradient(66deg, #e72870, #e76161 98%);
}

.toolbar-tab {
  margin: 0;
  padding: 0;
  background: none;
  outline: none !important;
  box-shadow: none;
  border: none;
  color: #e4e9ef;
  font-size: 5px;
}

.selected-toolbar .toolbar-tab {
  margin: 0;
}

.preview-toolbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* margin: 10px;
  margin-top: 12px;    */
  /* border-right: 1px solid #4d4d4d; */
}

.button-add-new-video {
  max-width: 22.5rem;
  height: 2.5rem;
  margin: 0px 5px 0 20px;
  outline: none;
  border: none;
  text-align: center;
  border-radius: 25px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #242424;
  color: #e4e9ef;
  font-family: "Poppins", sans-serif;
}

.toolbar-sv-content {
  /* margin: 20px; */
  padding: 0px 14px 0px 14px;
  /* margin: 0 20px 0 0; */
}

.toolbar-sv-content * {
  cursor: pointer;
}

.toolbar-sv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  /* margin: 10px; */
  /* padding-top: 7px; */
  /* margin-bottom: 0; */
  /* border-left: 1px solid #4d4d4d; */
}

.toolbar-sv-container {
  /* margin: 10px 0 10px 0; */
}

.toolbar-gen-vid {
  font-family: "Poppins", sans-serif;
  width: 180px;
  height: 2.5rem;
  border: none !important;
  outline: none !important;
  padding: 6.8px 28px 8.2px 26px;
  border-radius: 25px;
  background-image: linear-gradient(100deg, #e72870 6%, #e76161 93%);
  color: #e6e6e6;
}

.toolbar-lable {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  /* padding-top: 14px; */
  margin: 0;
  display: flex;
  align-items: center;
}

.title-vd-setting {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.9px;
  text-align: left;
  color: #e6e6e6;
  margin: 35px 0 35px 0;
}

.position-avatar-container {
  margin-bottom: 20px;
}

.search-upload {
  margin-top: 0px;
  margin-bottom: 25px;
}

.my-upload-lable {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  width: 100%;
  display: block;
  white-space: nowrap;
  padding: 6px 4px 6px 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.myupload-label-profile {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  white-space: nowrap;
  padding: 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 6px 0 0 0;
}

.my-uplaod-lable-music {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  width: 100%;
  display: block;
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
}

.avatar-select-button {
  outline: none !important;
  background: none;
  border-radius: 25px;
  border: solid 2px #333333;
}

.avatar-select-button img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 25px;
  background-color: #fff;
}

.add-color-container {
  width: 100%;
  height: 120px;
  padding: 15px;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
  margin: 0px;
}

.add-color {
  width: 97%;
  height: 97%;
  padding: 14px;
  border-radius: 0;
  border: dashed 1px #333333;
  background-color: #242424;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-select {
  width: 100%;
  height: 120px;
  border: solid 1px #e6e6e6;
  border-radius: 5px;
  max-width: 218px;
  max-height: 120px;
}

.color-selected {
  box-shadow: 0 0 10px 0 rgba(231, 65, 106, 0.4);
  border: solid 5px #e7416a;
}

.container-select-color {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.25rem 1.25rem;
  margin: 24px 0;
}

.content-select-color {
  margin: 0px;

  cursor: pointer;
}

.voice-vd-carosel .rec-item-wrapper {
  width: 113px !important;
}

.text-video-main {
  position: relative;
  height: 100%;
}

.text-on-video-container {
  position: absolute;
  /* max-width: 756px; */
  margin: 0 !important;
  width: 100%;
  color: #e6e6e6;
  bottom: 0;
  left: 0;
  padding: 25px 2.5rem 50px;
  box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: #333333;
}

.text-template-container {}

.full-height {
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.setting-con {
  flex: 2;
}

.toolbar-lable-avatar {
  font-family: "Poppins", sans-serif;
  margin: 0;
  vertical-align: middle;
  padding-top: 0px;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

#basic-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Some browsers will not display the caret when using calc, so we put the fallback first */
  background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat 98.5% !important;
  /* !important used for overriding all other customisations */
  background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat calc(100% - 10px) !important;
  /* Better placement regardless of input width */
}

.bgMusic-single-container label {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 8px 0 0 0;
}

#add-logo-container,
#add-logo-container-video {
  display: none;
  width: 100%;
  height: 100%;
}

.popup-toolbar {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #242424;
}

.popup-info {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  padding: 18px 25px 22px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #242424;
}

.tooltip-bg-content div {
  top: -34% !important;
}

.bgImgAvatar .hover-video-player-container div,
.bgImgAvatar .hover-video-player-container video {
  width: 100% !important;
  height: 100% !important;
}

.arrow {
  width: 2.5rem;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #fff;
  cursor: pointer;
  /* border: 1px solid rgb(199, 42, 42); */
}

.arrow-avSetting .arrow {
  height: 20px;
}

.arrow--left {
  left: -36px;
  /* opacity: 1; */
  /* display: block; */
  border: none;
}

.arrow-dashboard .arrow--left {
  left: -6px;
  /* opacity: 1; */
  /* display: block; */
  border: none;
  top: 50%;
  z-index: 9;
  height: 57px;
}

.arrow--left.scene-arrow {
  left: -38px;
  /* opacity: 1; */
  /* display: block; */
  border: none;
}

.sales-caraosel-page.arrow--left,
.sales-caraosel-page.arrow--right {
  top: 47%;
  z-index: 50;
}

.arrow--right {
  right: -15px;
  /* opacity: 1; */
  /* display: block; */
  border: none;
}

.arrow-dashboard .arrow--right {
  right: -14px;
  top: 50%;
  height: 57px;
  /* opacity: 1; */
  /* display: block; */
  border: none;
}

svg.arrow--disabled {
  /* display: none; */

  border: 1px solid rgb(143, 31, 31);
}

.sales-caraosel-page.arrow--left {
  left: -24px;
}

.sales-caraosel-page.arrow--right {
  right: -24px;
}

.avatarSpeech-merge-tag .Mergetag-slick-slider-item {
  width: auto !important;
  max-width: none !important;
  min-width: min-content !important;
}

.avatarSpeech-merge-tag .slick-initialized .slick-slide {
  width: auto !important;
  max-width: none !important;
  min-width: min-content !important;
  margin-left: 20px;
}

.avatarSpeech-merge-tag .slick-initialized .slick-slide:first-child {
  margin-left: 0px;
}

.avatar-selected-setting {
  border: solid 5px #e7416a;
}

#AvatarSpeech {
  color: #e6e6e6;
  padding: 1em;
  overflow-y: scroll;
  scrollbar-width: 0;
  height: 360px;
  /* box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.685) */
}

.video-player-jw {
  display: block;
  width: 50vw;
  /* height: 50vh; */
  z-index: 3;
}

.music-player-container,
.kkksls {
  position: relative;
}

.merge-modal-container.modal-dialog-scrollable {
  width: 90vw;
  max-width: 785px;
}

.preview-container {
  display: flex;
  flex-direction: column;
  max-width: 88%;
  margin: auto;
}

.scene-container {
  display: flex;
  flex-wrap: wrap;
}

.Preview-scene-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
}

.Preview-scene-now {
  width: 150px;
  height: 84px;

  padding: 29.9px 65px 30.1px 65.8px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(231, 40, 112, 0.16);
  border: solid 1px #e7416a;
  background-image: linear-gradient(60deg, #e72870, #e74469 47%, #e76161 98%);
  outline: none !important;
  border: none !important;
}

.Preview-scene-label {
  padding-right: 70px;
}

.Preview-scene-now-container {
  padding-right: 35px;
  /* margin-top: 10px; */
  border-right: 1px solid #4d4d4d;
  margin: 10px 35px 0 0;
}

.Preview-scene-now svg {
  width: 19.2px;
  height: 24px;
}

.scene-selected {
  box-shadow: 0 0 15px 0 rgba(250, 0, 106, 0.4);
  border: solid 5px #e7416a;
}

.selcted-bgImgAvatar {
  box-shadow: 0 0 15px 0 rgba(250, 0, 106, 0.4);
  outline: solid 5px #e7416a;
}

.scene-main {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  position: relative;
}

.delete-in-scene {
  display: none;
  position: absolute;
  top: 4px;
  right: 4px;
}

.delete-show-scene {
  display: block;
}

.scene-main:hover .delete-in-scene,
.bgMusic-vd-setting:hover .delete-in-scene {
  display: block;
}

.scene-main canvas {
  cursor: pointer;
}

.scene-main img {
  width: 100%;
  height: 100%;
}

.scene-label {
  margin-top: 5px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  width: 100%;
}

.add-scene {
  width: 149.5px;
  height: 84px;
  margin: 0 0 5px;
  padding: 10px;
  border-radius: 5px;
  border: solid 1px #4d4d4d;
  background-color: #242424;
  cursor: pointer;
}

.keen-slider__slider {
  width: 149.5px;
  height: 84px;
}

.maxLimit {
  color: #e9e9e9;
  font-family: "Poppins";
}

.add-scene-container {
  width: 130px;
  height: 64px;
  margin: auto;
  padding: 14.5px 52.4px 19.5px 52.6px;
  border-radius: 5px;
  border: dashed 1px #333333;
  background-color: #242424;
  color: #e4e9ef;
  font-size: 25px;
}

#add-scene-container,
#add-scene-container-video {
  opacity: 0;
  width: 100%;
  height: 100%;
}

.settingMainContainer.music-container-custom {
  overflow-y: scroll;
  margin: 0px -40px;
  padding: 0 40px;
}

.music-player-container {
  /* position: absolute; */

  /* max-width: 756px; */
  margin: 0 !important;
  width: 100%;
  color: #e6e6e6;
  bottom: 0;
  left: 0;
  padding: 25px 0;

  background-color: #333333;
}

.scene-main {
  width: 149.5px;
  height: 84px;
}

.preview-slider .slick-slide {
  width: auto !important;
  max-width: max-content !important;
  min-width: min-content !important;
  margin-right: 20px;
}

.preview-slider .slick-track {
  padding-top: 10px;
}

.styling-crousel .slick-track {
  max-width: max-content;
}

.arrow--left,
.arrow--right {
  top: 44%;
}

.scene-select-container:first-child {
  display: flex;
}

/* 


.preview-slider .scene-select-container{
  display: flex;
  flex-direction: column;
  text-align: center;
} */

/* .scene-select-container{ 

  width: 153px;
  height: 88px;
  margin: 0 20px 5px 0;
  padding: 0;
  border-radius: 5px;

  background-color: #e6e6e6;
  display: flex;
  

} */

.canvas-eg {
  background-color: #9400ff;
  height: 100%;
  width: 100%;
}

.preview {
  width: 100%;
  /* max-width: 980px; */
  /* height: 570px; */
  margin-top: 0px;
  margin-bottom: 29px;
  box-shadow: 0 0 10px black;
}

.TextBtn {
  /* background: red; */
  background: rgb(255, 255, 255, 0.1);
  padding: 5px;
  border-radius: 5px;
  display: flex;
  flex-flow: row wrap;
  margin: 5px;
  align-items: center;

  color: #e6e6e6;
}

.container-preview-slider {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.col-md-2-preview {
  width: auto;
}

.col-md-10-preview {
  width: 78%;
  position: relative;
}

.overlay-video-play {
  background: red;
  height: 99vh;
  width: 100vw;
  position: fixed;
  top: 0%;
  left: 0%;
  opacity: 0.2;
}

.overlay-escape {
  width: 100%;
  height: 100%;
  position: relative;
}

.add-color-setting {
  display: flex;
  flex-direction: column;
  position: absolute;
}

.color-picker-container {
  position: absolute;
  top: 0px;
  left: 104%;
}

.color_picker {
  min-width: 320px;
  height: 453px;
  padding: 20px;
  border: solid 1px #4d4d4d;
  background-color: #333333;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-rows: 1fr auto;
}

.color_picker::after {
  content: "";
  position: absolute;
  top: 47px;
  left: -11px;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  transform: rotate(91deg);
  border-color: #333333 transparent transparent transparent;
}

.color_picker .SaturationValueSelector-module_saturation_value_selector__3JODT {
  margin: 0 !important;
}

.color_picker.insales:before {
  content: "";
  display: block;
  position: absolute;
  top: -23px;
  left: 49%;
  margin-left: -5px;
  border-width: 2px;
  border-style: solid;
  transform: rotate(0deg);
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-bottom-color: #4d4d4d;
}

.color_picker.insales:after {
  content: "";
  display: block;
  position: absolute;
  top: -17.5px;
  left: 50%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  transform: rotate(0deg);
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: #333333;
}

/* .color_picker.insales::after {
    content: "";
    position: absolute;
    top: -16px;
    left: 50%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    transform: rotate(
180deg
);

box-shadow:  0 0 0 1px #4d4d4d transparent transparent transparent;

	border-color: #333333 transparent transparent transparent;
} */

.HueSlider-module_hue_slider__3-jXT {
  margin: 0 !important;
}

.HueSlider-module_hue_slider__3-jXT .HueSlider-module_hue_slider_picker__1XZR2 {
  position: absolute;
  top: 0px;
  left: 0;
  width: 3px;
  height: 100%;
  border: 4px solid #ffffff;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 50%);
  background-color: #ff0000;
  border-radius: 11px;
  cursor: pointer;
  transform: translateX(-50%);
}

.ReactColorPicker-module_react_color_picker__1OEfS .ReactColorPicker-module_details__106p_ {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
  margin-left: 11px;
  margin-right: 11px;
  flex-direction: row-reverse;
}

.ReactColorPicker-module_hex_value__10kKY {
  width: 144px !important;
  height: 2.5rem;

  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #242424;
  color: #e6e6e6;
  border: none;
  text-align: center;
}

.ReactColorPicker-module_details__106p_ label span {
  color: #e6e6e6 !important;
  margin-right: 5px;
}

.ReactColorPicker-module_react_color_picker__1OEfS .ReactColorPicker-module_details__106p_ .ReactColorPicker-module_swatch__3kHFV {
  height: 35px !important;
  width: 35px !important;
  margin: 0;
  margin: 0 0 0 25px;
  border: 1px solid #ccc;
}

.color-cancel {
  width: 120px;
  height: 2.5rem;
  margin: 5px;
  padding: 0 30.3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: #242424;
  color: #e6e6e6 !important;
  border: none !important;
  outline: none !important;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

#UploadingContainer.video-list-main-container {
  overflow-y: scroll;
  /* height: 89vh; */
  margin-right: 0px;
  padding-right: 2rem;
}

.color-add {
  width: 120px;
  height: 2.5rem;
  margin: 5px;
  padding: 0 30.3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  color: #e6e6e6 !important;
  border: none !important;
  outline: none !important;
  background-image: linear-gradient(104deg, #e72870 6%, #e76161 92%);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}

.ReactColorPicker-module_react_color_picker__1OEfS {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  min-height: 100%;
}

.color-set-controls {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0 0;
}

.addcolor-main-wrapper {
  position: relative;
}

.HueSlider-module_hue_slider_picker__1XZR2,
.HueSlider-module_hue_slider_picker__1XZR2 {
  position: absolute;
  top: 0px !important;
  left: 0;
  width: 2px !important;
  height: 100%;
  border: 3px solid #ffffff !important;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 50%);
  background-color: #ff0000;
  border-radius: 11px;
  cursor: pointer;
  transform: translateX(-50%);
}

.template-color-salespage {
  display: flex;
  align-items: center;
}

.sales-nav-lable {
  display: flex;
  align-items: center;
}

.nav-color-theme {
  display: flex;
  justify-content: space-between;
}

.menu-salespage-content {
  z-index: 9999999999 !important;
  pointer-events: auto;
  position: fixed !important;
}

.menu-salespage-content.tp-0-content {
  z-index: 9999999999 !important;
  pointer-events: auto;
  top: 8% !important;
  left: 18px !important;
}

.menu-salespage-content.tp-4-content {
  z-index: 9999999999 !important;
  pointer-events: auto;
  top: 8% !important;
  left: 42.5% !important;
}

.menu-salespage-content.tp-5-content {
  z-index: 9999999999 !important;
  pointer-events: auto;
  top: 8% !important;
  left: 52.8% !important;
}

.menu-salespage-content.tp-1-content {
  z-index: 9999999999 !important;
  pointer-events: auto;
  top: 8% !important;
  left: 70px !important;
}

.menu-salespage-content.tp-2-content {
  z-index: 9999999999 !important;
  pointer-events: auto;
  top: 8% !important;
  left: 123px !important;
}

.menu-salespage-content.tp-3-content {
  z-index: 9999999999 !important;
  pointer-events: auto;
  top: 8% !important;
  left: 176px !important;
}

.menu-salespage-overlay {
  background: none !important;
}

.color_picker.insales {
  width: 287px;
  min-width: 288px;
  height: 339px;
  padding: 20px 5px;
  border: solid 1px #4d4d4d;
  background-color: #333333;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 25%);
  z-index: 1551151515151548134;
}

.overlay-video-play {
  background: rgba(0, 0, 0, 0.8);
  height: 99vh;
  width: 100vw;
  position: fixed;
  top: 0%;
  left: 0%;
}

.modal-title,
.modal-body,
.modal-footer {
  font-family: "Poppins", sans-serif !important;
}

.merge-modal-container .vd-modal-footer {
  justify-content: flex-end !important;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000000019 !important;
}

.scene-arrow {
  width: 34px;
  height: 27px;
  top: 44%;
  right: -35px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.upload-list {
  overflow: auto;
}

.preview-avatar-vd .jwplayer.jw-flag-aspect-mode .jw-aspect {
  /* padding-top: 48.25% !important; */
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  background-color: #333333;
  position: absolute;

  bottom: -68px;
  height: 48px;

  width: 100%;
}

.pagination-gp1,
.pagination-gp2 {
  display: flex;
  justify-content: center;
}

.pagination-gp2 input {
  width: 2.5rem;
  height: 1.9rem;
  margin: 0 6px;
  padding: 6.5px 0px 6.5px 11.5px;
  border-radius: 25px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #242424;
  border: none;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.pagination-gp2 {
  margin-left: 63px;
}

.prev-pagination button,
.next-pagination button {
  width: 11.9rem;
  height: 1.9rem;
  border-radius: 25px;
  background: none;
  background-color: #4d4d4d;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  margin-right: 15px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.pagination-lable {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  padding-top: 6px;
}

.popup-lable-merge {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.link-icon-con {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-icon {
  position: absolute;
  top: 40%;
  left: 20px;
}

.carousel-btn {
  border-radius: 25px;
  border: solid 1px #e7416a !important;
  padding: 8.2px 20px 8.8px;
  margin-right: 20px;
  background-color: #323232;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e7416a;
}

.btn-salespage-custom {
  /* width: 113.9px; */
  height: 2.5rem;
  margin: 0 0px 0 0;
  padding: 7.7px 25px 9.3px 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 20px;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

/* modal related */

.modal-header {
  padding: 18px 1.9rem 17px;

  letter-spacing: normal;
  text-align: left;
  color: #faffff;
  font-size: 20px;
  width: 100%;
}

.modal-body {
  /* height: 60vh !important;
  width: 89vw; */
}

.modal-title {
  color: #faffff;
}

/* .modal-dialog-scrollable {
	position: relative;
	max-width: none;
} */

.modal-dialog-centered.modal-dialog-scrollable {
  background-color: #333333;
}

.Salespage-popup .popup-content svg path,
.popup-arrow {
  fill: #242424;
  color: #242424;
}

.avatarsetting.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  /* max-height: calc(100vh - 9.5rem) !important; */
  /* height: calc(100vh - 9.5rem) !important; */
  width: 80vw;
  max-width: 1352px;
  background-color: #333;
}

.Salespage-popup.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 9.5rem) !important;
  height: calc(100vh - 9.5rem) !important;
  width: 80vw;
  max-width: 1160px;
}

.Salespage-popup .rc-tabs-tab-btn {
  width: 245px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 20px 78px 0 0;
	padding: 19px 0 3px; */
  background-color: #ffffff;
}

.Salespage-popup .rc-tabs-nav {
  margin-bottom: 20px;
}

.rc-tabs-tab-btn {
  border: 1px solid #000;
}

.Salespage-popup .rc-tabs-ink-bar {
  background: #0d1717;
}

.Salespage-popup .modal-header {
  background-color: #7b7b7b !important;
}

.preview-salespage-btn {
  width: auto;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-image: linear-gradient(80deg, #e72870 1%, #e76161);
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  padding: 0 16px;
  margin-left: 30px;
}

.save-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.save-gp-salespage {
  display: flex;
  justify-content: flex-end;
}

.saveiconSalespage {
  padding: 8px;
  position: relative;
  margin: 0px 28px;
}

/* .signal-prospect-info-lable span{

	width: 20%;
	max-width: 37px;
} */
.signal-prospect-info-lable {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.css-19gs8ny-Popover {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #4d4d4d;
  background-color: #333333;
  color: #ececec;
}

.vd-modal-header .fas.fa-times {
  color: #a9a9a9;
}

.launchcamp-icon-inv-pages path {
  fill: #e6e6e6;
}

.play-pause-toolbar {
  position: inherit !important;
  width: 24px;
  height: 24px;
}

.player {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  /* align-items: center; */
  /* padding: 20px 0; */
  /* background-color: #212527; */
}

.player .song {
  user-select: none;
  margin: 0 20px;
  margin: 25px 0 20px 0;
}

.player .song .song__title {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.player .song .song__title:hover {
  color: #e6e6e6;
}

.player .song .song__artist {
  margin: 0;
  color: white;
  font-family: "Rock Salt", cursive;
  font-weight: normal;
  font-size: 1em;
}

.player .song .song__artist:hover {
  color: #e6e6e6;
  cursor: pointer;
}

.player .controls {
  /* flex-grow: 1; */
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 25px 10px 11px;
  border-radius: 35px;
  border: solid 1px #333333;
  background-color: #242424;
}

.player .player__button {
  width: fit-content;

  background-color: transparent;
  border: none;
}

.player .player__button:focus {
  outline: none;
}

.player .player__button:hover {
  cursor: pointer;
}

.player .player__button:hover svg {
  color: #e6e6e6;
}

.player .player__button svg {
  font-size: 4em;
  color: white;
}

.player .bar {
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;
}

.player .bar .bar__time {
  color: white;
  font-size: 1rem;
}

.player .bar .bar__progress {
  flex: 1;
  background: linear-gradient(to right, #e6e6e6 0%, #4d4d4d 0px);
  border-radius: 5px;
  margin: 0 20px;
  height: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.player .bar .bar__progress .bar__progress__knob {
  position: relative;
  height: 1rem;
  width: 1rem;
  border: 1.5px solid white;
  border-radius: 50%;
  background-color: #e6e6e6;
}

.salespage-preview {
  display: flex;
  justify-content: space-between;
  height: 60px;
  padding: 0 2.5rem 0 27px;
  background-color: #222222;
}

.salespage-preview .gp-preview {
  display: flex;
  align-items: center;
  /* width: 300px; */
}

.salespage-preview .gp-preview #Group_73 {
  height: 27px;
  width: 249px;
}

.back-btn-preview {
  width: 48px;
  height: 48px;
  margin-right: 17px;
}

.edittempbtn {
  width: 159px;
  height: 2.5rem;
  margin: 10px 20px 10px 20px;
  padding: 10px 20px;
  border-radius: 25px;
  background-image: linear-gradient(78deg, #e72870 1%, #e76161);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.closesalespagebtn {
  width: 160px;
  height: 2.5rem;
  margin: 10px 0 10px 20px;
  padding: 10px 20px;
  border-radius: 25px;
  border: solid 1px #333333;
  background-color: #333333;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.select-size-icon-container {
  position: relative;
}

.selected-size-bd {
  position: absolute;
  width: 35px;
  height: 0;
  border-top: solid 5px #e7416a;
  bottom: 0;
  display: none;
  right: 14px;
}

.selected-size-bd.x99 {
  left: 9px;
  right: auto;
}

.selected-size-bd.true {
  display: block;
}

.preview-container-wrapper {
  height: auto;
  background-color: #e6e6e6;
  /* overflow-y: scroll; */
}

.preview-popup-salespage-content {
  width: 100%;
}

.heading-analytics {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.header-analytics {
  display: flex;
  justify-content: space-between;
  margin: 0 0 35px 0;
  position: relative;
}

.funnelChart-container {
  width: 100%;
  height: 546px;
  /* margin: 0px 0 0 29px; */
  padding: 1.9rem 1.9rem;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
}

.funnelChart-container .funnel-image {
  width: 100%;
  height: 400px;
}

.funnel-heading-label {
  position: relative;
  /* width: 118px; */
  height: 25px;
  margin: 0;
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  display: flex;
  margin: 0 0 30px 0;
}

.funnelChart-container .info-logo img {
  top: 2%;
  right: -75px;
}

.funnellabel {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #a9a9a9;
  margin: 5px;
}

.funnel-count {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  margin: 0;
}

.funnel-detail-item {
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.funnel-details,
.funnel-image {
  position: absolute;
}

.funnel-details {
  width: 100%;

  top: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.funnel-container {
  position: relative;
  width: 100%;
  height: 100%;
  /* border: 1px solid white; */
}

.toolbar-tab-analytic-container {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
  /* max-width: 362.5px; */
  max-height: 95px;
  width: 100%;
  /* max-width: 362.5px; */
  margin: 0px 0;
}

.toolbar-tab-analytic-box1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolbar-tab-analytic-box1 img {
  width: 3rem;
}

.analytic-toolbar-heading {
  font-family: "Poppins";
  font-size: 0.76rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin: 0 0 4px 0;
  white-space: nowrap;
}

.analytic-popupButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.analytic-number-toolbar {
  font-family: "Poppins";
  font-size: 1.5rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.46; */
  letter-spacing: normal;
  text-align: left;
  color: #e7416a;
  margin: 0;
}

.written-toolbar-tab-analytic {
  margin: 0 0 0 20px;
  display: flex;
  flex-direction: column;
}

.analytics-tabs-popup {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(354px, 1fr)); */
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1.5rem 1.5rem;
  margin: 0 0 1.5rem 0px;
  width: 100%;
}

.tabs-in-analytics-tablist {
  list-style: none;
  display: flex;
  padding: 2.5rem;
  margin-bottom: 0;
}

.avatarsrtting-container-main {
  width: 100%;
  display: flex;
}

.avatar-setting-main-popup-contain {
  max-width: 437px !important;
  padding-right: 2.5rem !important;
  margin-right: 2.5rem !important;
  width: 44%;
  margin: 0;
}

.avatarsrtting-containerpreview {
  /* width: 61%; */
  /* padding: 2.5rem 0; */
}

.tabs-in-analytics-tabs.react-tabs__tab--selected {
  /* max-width: 133px; */
  border-top: none !important;
  border-image: none !important;
  height: 2.5rem;
  margin: 0;

  border-radius: 28px;
  background-image: linear-gradient(81deg, #e72870 0%, #e76161 92%) !important;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}

.tabs-in-analytics-tabs {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  /* width: 188px; */
  padding: 10px 20px 10px 20px;
  height: 2.5rem;

  margin: 0;

  border-radius: 20px;
  background-color: #333333 !important;
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #e6e6e6 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs-in-analytics-tablist-switch {
  background-color: #333333 !important;
  display: flex;
  border-radius: 28px;
  margin: 0;
}

.tabs-in-analytics-tablist .info-logo img {
  right: -27px;
  top: 27%;
}

.Prospect-items {
  padding: 1.25rem 2.5rem;
}

.signals-items {
  display: flex;
  justify-content: space-between;
  padding: 20px 1.9rem;
  border-bottom: solid 1px #333333;
}

.mail-name-analytics {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.signals-inner-items {
  display: flex;
}

.signals-data-headings {
  margin: 0 0 0 1.9rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.signals-heading {
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 10px 0;
}

.signals-timestamp {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  margin: 0;
}

.menu-campain-order {
  margin: 0 20px 0 0;
}

.analytic-popup-filter .menu-campain-order {
  margin: 0 0px 0 0;
}

.signal-unread {
  background-color: rgba(51, 51, 51, 0.4);
}

.signals-item-footer {
  display: flex;
  justify-content: center;
  padding: 20px;

  border-top: solid 1px #333333;
}

.signals-containers {
  /* border-radius: 5px;
		border: solid 1px #333333; */
  /* border-top: none; */
  background-color: #242424;
}

.tabs-in-analytics {
  border: solid 1px #333333;
  border-radius: 5px;
  margin-bottom: 1.9rem;
}

.tabs-in-analytics.gpsolo-tabs {
  padding: 0px 2.5rem 0px;
}

.campaign-table-list-items {
  display: flex;
  justify-content: space-between;
}

.campaign-table-list-inner {
  display: flex;
}

.lable-campaigned-open {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.table-campaign-names {
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e7416a;
  padding: 1.25rem 0;
}

.table-campaign-numbers {
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  width: 100%;
  margin: 0 auto;
  padding: 1.25rem 0;
}

.table-campaign-numbers svg {
  margin: auto auto;
}

.lable-campaigned-open {
  margin: 11px;
}

.campaign-opend-item {
  /* margin: 20px 0; */
  border: 1px solid #333333;
}

.table-signals-containers {
  width: 100%;
}

.table-campaign-hd {
  font-family: "Poppins";
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;

  margin: 0 auto;
  /* white-space: nowrap; */
}

/* in dashboard */

.table-campaign-numbers:nth-child(2) {
  max-width: 83px;
}

.table-campaign-numbers:nth-child(3) {
  max-width: 127px;
}

.table-campaign-numbers:nth-child(4) {
  max-width: 85px;
}

.table-campaign-numbers:nth-child(5) {
  max-width: 84px;
}

.table-campaign-numbers:nth-child(6) {
  max-width: 113px;
}

.table-campaign-hd:nth-child(2) {
  max-width: 83px;
}

.table-campaign-hd:nth-child(3) {
  max-width: 127px;
}

.table-campaign-hd:nth-child(4) {
  max-width: 85px;
}

.table-campaign-hd:nth-child(5) {
  max-width: 84px;
}

.table-campaign-hd:nth-child(6) {
  max-width: 113px;
}

/* in analytic */

.real-analytic .gpsolo-tabs .svgWrapper {
  top: 8px;
}

.real-analytic .searchBoxongoingCamp {
  width: 157%;
}

.real-analytic .prospect-notification-container {
  padding-left: 0;
}

.real-analytic .prospect-items-mini:first-child {
  padding-left: 0;
}

.real-analytic .prospect-hr {
  width: 100%;
  /* max-width: 45px;
	min-width: 20px; */
  border-top: 1px dashed #333333;
  margin: 10px ​0 0 0px;
}

.real-analytic .prospect-hr-not-valid {
  /* max-width: 124px; */
}

.real-analytic .prospect-items-mini:nth-last-child(2) {
  /* min-width: 124px; */
}

.gpsolo-tabs-popup .table-campaign-hd {
  margin: 0 19px;
}

.gpsolo-tabs .table-campaign-hd {
  margin: 0 13px;
}

.gpsolo-tabs .table-campaign-numbers {
  margin: 0px 1rem;
}

.gpsolo-tabs-popup .table-campaign-numbers {
  margin: 0px 1rem;
}

.gpsolo-tabs-popup .table-campaign-hd.table-campaign-names {
  margin-left: 0;
}

.gpsolo-tabs .table-campaign-hd.table-campaign-names {
  margin-left: 0;
}

.table-campaign-tr {
  width: 100%;
  display: flex;
  border-bottom: solid 1px #4d4d4d;
}

.ongoing-Camp .table-campaign-tr:first-child {
  border: none;
}

.table-campaign-td,
.table-campaign-numbers {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-campaign-names {
  width: 100%;
  max-width: 13.1rem;
  min-width: 13.1rem;
  margin: 0 1.25rem 0 0;
  display: flex;
  align-items: center;

  /* border-bottom: solid 1px #333333; */
}

.table-campaign-hd.table-campaign-names {
  padding: 11px 0;
}

.table-campaign-hd {
  /* max-width: 123px; */

  padding: 11px 0;
}

.onGoingCamp-container .table-campaign-tr {
  border-bottom: solid 1px #333333;
}

.onGoingCamp-container .table-campaign-names {
  width: 100%;
  max-width: 11.1rem;
  min-width: 11.1rem;
  margin: 0 1.25rem 0 0 !important;
  display: flex;
  align-items: center;
  /* border-bottom: solid 1px #333333; */
}

.onGoingCamp-container .table-campaign-hd {
  margin: 0 auto;
  font-size: 0.79rem;
}

.table-campaign-names-solo {
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  height: 50px;
}

.icon.table-campaign-numbers {
  max-width: none;
  min-width: auto;
  width: 31px;
  margin: 0;
  padding: 0;
}

.tab-gp2 svg.pointer {
  height: 2.5rem;
}

.icon.table-campaign-hd {
  max-width: none;
  min-width: auto;
  width: 31px;
}

.onGoingCamp-container .table-campaign-hd:nth-child(6) {
  max-width: 118px;
}

.real-analytic .table-campaign-names {
  max-width: 11.1rem;
  min-width: 11.1rem;
}

.only-signals-analytics .table-campaign-hd:nth-child(2) {
  max-width: 109px;
}

.only-signals-analytics .table-campaign-hd:nth-child(5) {
  max-width: 105px;
}

.only-signals-analytics .table-campaign-numbers:nth-child(3) {
  max-width: 74px;
}

.only-signals-analytics .table-campaign-numbers:nth-child(2) {
  max-width: 98px;
}

.only-signals-analytics .table-campaign-numbers:nth-child(4) {
  max-width: 48px;
}

.only-signals-analytics .table-campaign-numbers:nth-child(5) {
  max-width: 102px;
}

.gpsolo-tabs-popup .only-signals-analytics .table-campaign-numbers {
  margin: 0px 20px;
}

.chart-container {
  display: flex;
  height: 100% !important;
}

.chart-bx1 {
  width: 64%;
  /* height: 560px; */
  /* 
		display: flex;
		justify-content: flex-end;
		flex-direction: column; */
}

.chart-bg {
  /* height: 560px; */
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.chart-bx2 {
  width: 36%;
  /* height: 560px; */
  border-left: solid 1px #4d4d4d;
  padding: 0 1.9rem 1.9rem 1.9rem;
  margin: 0 0 0 30px;
}

.analytic-detail-popup-video {
  max-width: 1220px !important;
  width: 90vw !important;
}

.manage-team-popup-wrapper .menu-submenu-manageteam-container {
  width: 275px !important;
  padding: 6px !important;
}

/* kkkk */

@media only screen and (max-width: 1306px) {
  .analytic-detail-popup-video {
    max-width: 1220px !important;
    width: 97% !important;
  }
}

@media only screen and (max-height: 900px) {
  .bgImgAvatarContainer .infinite-scroll-component {
    display: grid;
    height: 49vh;
  }

  .pagination-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
    background-color: #333333;
    position: absolute;
    bottom: -99px;
    height: 79px;
    width: 100%;
  }
}

.analytic-detail-popup-video .vd-modal-content {
  height: 83vh;
  background-color: #333333;
}

.mail-list {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #4d4d4d;
  padding: 20px 0 15px 0;
}

.mail-header {
  display: flex;
  align-items: center;
  padding-bottom: 1.9rem;
  border-bottom: solid 1px #4d4d4d;
}

.header-date-mail {
  margin: 0;
  font-family: "Poppins";
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.how-many-mails {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  margin: 0 10px;
}

.arrowRight-analytic-popup {
  position: absolute;
  right: -31px;
}

.arrowLeft-analytic-popup {
  position: absolute;
  left: -31px;
}

.arrow-analytic-popup {
  top: 0;
  z-index: 99;
}

.slider-in-charts {
  width: 100%;
  position: relative;
}

.dates-in-popup {
  width: 78px !important;
  height: 2.5rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.detail-mail-mail-container {
  display: flex;
  justify-content: space-between;
}

.dates-in-popup-selected {
  height: 2.5rem;

  border-radius: 20px;
  background-image: linear-gradient(to bottom, #e72870, #e76161);
}

.slider-in-charts {}

.chart-bx2 {
  overflow-y: scroll;
}

.sub-heading-analytic-video-popup {
  display: flex;
}

.analytic-views-headinc887 {
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.label-time-analytic-popup {
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.modal-title-analutics {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  display: flex;
  margin-bottom: 10px;
}

.my-upload-select-popup-analytic * {
  padding: 10px 1rem;
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #333333;
}

.my-upload-select-popup-analytic {
  width: 136px !important;
  height: 2.5rem !important;
  /* margin-top: 10px !important; */
  margin-right: 20px !important;
  color: #e4e9ef !important;
  border-radius: 20px !important;
  border: solid 1px #333333 !important;
  padding: 5px 9px 5px 1rem;
  outline: none !important;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  background: url("selectArrow.svg") no-repeat right #242424;
  -webkit-appearance: none;
  background-position-x: 91%;
}

.my-upload-select-popup-analytic option {
  background-color: #333333;
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  padding: 1rem 10px;
}

.analytic-popup-filter {
  margin-right: 50px;
}

.analytic-popup-filter .css-1r4vtzz,
.analytic-popup-filter .css-48ayfv {
  width: 220px;
  height: 2.5rem;
  /* margin: 0 106px 58px 838px;
		padding: 5px 10px 5px 1rem; */
  border-radius: 20px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.11);
  background-color: #242424;
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.analytic-popup-filter .css-1pcexqc-container {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.11);
  background-color: #242424;
}

.analytic-popup-filter .css-1qprcsu-option {
  background-color: #242424 !important;
}

.analytic-popup-filter .css-1vr111p-option,
.analytic-popup-filter .css-1qprcsu-option {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  background-color: #242424 !important;
}

.header-popup-analytics-rightside {
  display: flex;
  align-items: center;
}

.camp-analytic-info {
  display: flex;
  align-items: center;
}

.camp-analytic-info img {
  position: relative;
}

.camp-analytic-info .info-logo-new img {
  position: static;
}

.CAT.analytic-detail-popup-video .vd-modal-content {
  height: 80vh;
  background-color: #333333;
}

.filterIconCat {
  margin-right: 50px;
  display: flex;
}

.filterIconCat .menu-container,
.filterIconCat .container-select {
  min-width: auto;
}

.CAT .analytic-popup-filter {
  margin-right: 20px;
}

.CAT.analytic-detail-popup-video {
  max-width: 980px !important;
}

.CAT .analytic-detail-popup-video .modal-analytic-popup {
  overflow-y: auto;
}

.cat-list-container {
  width: 100%;
}

.cat-list-item {
  width: 100%;
  padding: 0 0 1.9rem 0;
  margin: 0 0 20px 0;
  border-bottom: solid 1px #4d4d4d;
}

.cat-camp-name {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin-bottom: 20px;
}

.cat-button-list {
  display: flex;
}

.cat-button-con {
  display: flex;
}

.CAT.analytic-detail-popup-video.analticCTA .vd-modal-content {
  min-height: 81vh;
  height: auto;
}

.analticCTA .cat-list-item {
  border: none;
  margin: 0;
  padding: 0;
}

.cat-button {
  border-radius: 20px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.11);
  border: solid 1px #4d4d4d;
  background-color: #4d4d4d;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 1rem;
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  min-width: 143px;
}

.cat-button-clicked-number {
  margin: 0 35px 0 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e7416a;
}

.cat-button-clicked-number-disabled {
  color: #4d4d4d;
}

.cat-button-clicked-number-disabled svg path {
  fill: #4d4d4d;
}

.padding-40-popup {
  padding: 2.5rem !important;
}

.mail-link-popup-th {
  padding: 1rem 13px;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.56; */
  letter-spacing: normal;
  text-align: left;
  color: #e7416a;
  border: 1px solid #4d4d4d;
  border-left: none;
  border-top: none;
  background-color: #242424;
}

.mail-link-popup-th:first-child {
  width: 48px;
}

.mail-link-popup-table {
  width: 100%;
}

.MailSetnt.analytic-detail-popup-video {
  max-width: 1012px !important;
  width: 1012px !important;
}

.MailSetnt.analytic-detail-popup-video.detailscsvpopup {
  max-width: 983px !important;
  width: 983px !important;
}

.mail-link-popup-td {
  padding: 15px;
  border: 1px solid #4d4d4d;
  border-left: none;
  border-top: none;
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 3.57; */
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.td-main-mail-link-popup {
  background-color: #242424;
}

.MailSetnt.analytic-detail-popup-video .vd-modal-content {
  height: 81vh;
  background-color: #333333;
  display: flex;
  flex-direction: column;
}

.MailSetnt.analytic-detail-popup-video.detailscsvpopup .vd-modal-content {
  height: 87vh;
  max-height: 842px;
}

.chart-container-mail-sent {
  overflow-y: scroll;
  flex: auto;
  margin: 0px -37px;
  padding: 0 40px;
}

.MailSetnt .modal-analytic-popup {
  overflow: hidden !important;

  flex: auto;
  display: flex;
}

.gp-solo-popup-con {
  width: 983px !important;
  max-width: 983px !important;
  height: 87vh;
  max-height: 842px !important;

  border-radius: 5px;
  border: solid 1px #4d4d4d;
  background-color: #333333;
  /* overflow: scroll !important; */
  position: relative;
}

.gp-solo-popup-con .table-signals-containers td {
  border-bottom: solid 1px #4d4d4d;
}

.ongoing-Camp .table-signals-containers td {
  border-bottom: solid 1px #4d4d4d;
}

.tabs-in-analytics-tablist-popup {
  z-index: 1;
  position: sticky;
  top: 0;
  background-color: #333333;
}

.gp-solo-popup {
  z-index: 999999;
}

.left-side-header-dp-solo {
  display: flex;
}

.tabs-in-analytics-tabs-popup {
  padding: 10px 20px 10px 20px;
  height: 2.5rem;
  margin: 0;
  border-radius: 20px;
  background-color: #242424 !important;
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #e6e6e6 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs-in-analytics-tablist-switch-popup {
  background-color: #242424 !important;
  display: flex;
  border-radius: 28px;
}

.tabs-in-analytics-tabs-popup.react-tabs__tab--selected {
  /* max-width: 133px; */
  border-top: none !important;
  border-image: none !important;
  height: 40px;
  margin: 0;

  border-radius: 28px;
  background-image: linear-gradient(81deg, #e72870 0%, #e76161 92%) !important;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}

.header-gp-solo-poopup .fa-times {
  color: #e6e6e6;
}

.searchBoxpopup::-webkit-search-cancel-button {
  display: none;
}

.searchBoxpopup {
  text-indent: 1.9rem;
  /* margin-top: 10px; */
  max-width: 256px;
  width: 100%;
  height: 2.5rem;
  padding-left: 10px;
  border-radius: 20px;
  border: solid 1px #333333;
  background-color: #242424;
  font-family: "Poppins", sans-serif;
  color: #e6e6e6;
}

.search-avatarPopup {
  position: relative;
}

.svgWrapperPopup {
  position: absolute;
  top: 8px;
  left: 15px;
}

.right-side-header-dp-solo {
  display: flex;
  align-items: center;
}

.search-avatar-library {
  width: 100%;
  max-width: 245px;
  display: flex;
  align-items: center;
}

.right-side-header-dp-solo .svgWrapper {
  position: absolute;
  top: 8px;
  left: 15px;
}

.side-header-dp-solo {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.header-gp-solo-poopup {
  width: 100%;
}

.solo097 {
  padding: 20px 0 0 0;
}

.tabs-in-gp-solo-popup {
  padding: 0 2.5rem 2.5rem 2.5rem;
  height: -webkit-fill-available;
  overflow-y: auto;
}

.tabs-in-gp-solo-popup .infinite-scroll-component {
  height: max-content !important;
  overflow: hidden !important;
}

.tabs-in-signals .react-tabs__tab-list {
  border-bottom: none !important;
  margin: 0 0 25px 0;
  padding: 0;
  display: flex;
}

.tabs-in-signals .react-tabs__tab {
  display: inline-block;
  border: none !important;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 13px 28px 17px !important;
  cursor: pointer;
  color: #e4e9ef !important;
  height: 50px !important;
  max-width: none;
  min-width: auto;
  width: auto;
  background: #333333;
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.sigprop .tabs-sig .react-tabs__tab {
  padding: 0px 28px 10px !important;

  width: 120px !important;
  height: 38px !important;
}

.sigprop .tabs-in-prospect .react-tabs__tab {
  padding: 0px 28px 10px !important;

  height: 38px !important;
}

.tabs-in-signals .react-tabs__tab--selected {
  /* background: #333333 !important; */
  border: none !important;
  outline: none !important;
  border-bottom: 4px solid !important;
  border-image: linear-gradient(92deg, #ea1f72 1%, #d86666) !important;
  border-image-slice: 1 !important;

  color: #e7416a !important;
  border-radius: 5px 5px 0 0;
  box-shadow: none;
}

.signals-items-popup {
  padding: 20px 2.5rem;
  background-color: #333333;
  display: flex;
  justify-content: space-between;
}

.signals-items-popup.signal-unread {
  padding: 20px 2.5rem 20px 2.5rem;
  /* opacity: 0.4; */
  border: solid 1px #242424;
  border-left: 0 solid #000;
  border-right: 0 solid #000;
  background-color: rgba(77, 77, 77, 0.6);
}

.tabs-in-signals {
  padding: 0;
  width: 100%;
}

.signals-inner-tab-popup {
  padding: 0 2.5rem 2.5rem 0;
}

.showmore-analytics {
  width: 135px;
  height: 1.9rem;
  margin: 0px 20px 0 20px;
  padding: 5px 1rem 5px 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: #333333;
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.84px;
  text-align: left;
  color: #e7416a;
}

/*  dropdown  */

.menu-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-trigger {
  /* background: #ffffff; */
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-left: auto;
  /* Strictly for positioning */
}

.menu-trigger:hover {
  /* box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3); */
}

.menu-trigger span {
  vertical-align: middle;
  font-size: 0.9rem;
  margin: 0;
}

.menu-trigger img {
  margin-right: 10px;
  /* border-radius: 90px; */
}

.prospect-menuList img {
  margin-right: 5px;
  /* border-radius: 90px; */
}

.menu {
  z-index: 15;
  /* width: 197px;
		height: 247px; */
  margin: 0;
  padding: 3px 0 4px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #333333;
  position: absolute;
  top: 50px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.prospect-menuList.order-list:hover {
  background-color: #4d4d4d;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu .mainList {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100% !important;
  margin: 0;
  padding: 3px 0 4px;
  border-radius: 5px;
}

.menu .main-list-li {
  padding: 12px 15px;
  width: 100%;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  /* border: 1px solid #000; */
}

.main-list-li img {
  width: auto;
  height: auto;
}

.List-Menu.menu .main-list-li {
  padding: 0;
  width: 100%;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  /* border: 1px solid #000; */
}

.prospect-item-header .my-upload-select-prosp {
  min-width: 125px !important;
  height: 1.9rem !important;
  margin: 0px !important;
  color: #e7416a !important;
  border-radius: 20px !important;
  cursor: pointer;
  background: url("selectArrow.svg") no-repeat right #333333;
  padding: 6px 36px 6px 1rem;
  outline: none !important;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  -webkit-appearance: none;
  background-position-x: 94%;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  display: flex;
  justify-content: flex-start;
}

.prospect-item-header .my-upload-select-prosp.Pendingprospect {
  color: #e4e9ef !important;
}

.popup-select-prosp {
  background-color: #242424 !important;
  border: none !important;
}

.prospect-menuList.ana-dash {
  width: 100%;
  max-width: 208px;
  transition: 0.3s ease;
}

.prospect-menuList.ana-dash:hover {
  background-color: #4d4d4d;
}

.index-prospect-select {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.11);
  /* background: url("selectArrow.svg") no-repeat right #333333; */
  background-color: #333333 !important;
}

.signal-DOts {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  /* border-radius: 100%; */
}

.signals-dots {
  display: flex;
  align-items: center;
}

.signal-DOts:hover {
  background: none;
  border: none;
  box-shadow: none;
}

.signal-DOts img {
  margin: 0;
}

.menu-signals {
  width: auto;
  height: auto;
  background-color: #333333;
  z-index: 999999999999999999999999999999;
  width: 147px;
}

.arrowinthumbnail svg {
  cursor: pointer;
}

.mainList-signals li {
  width: 164px;
  height: 2.5rem;
  padding: 10px 1rem;
  border-radius: 5px;
  background-color: #333333;
  cursor: pointer;
}

.time-above {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  margin-right: 20px;
  /* position: absolute;
	top: -44px;
	right: 208px; */
}

.Timeline-select-trigger {
  /* width: 182px !important; */
  padding: 0px 1rem;
  background-position-x: 93%;
  margin-bottom: 0 !important;
}

.Timeline-menu {
  z-index: 135;
  margin: 0;
  padding: 3px 0 4px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 16%);
  background-color: #333333;
  position: absolute;

  top: 48px;
  right: 1px !important;

  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  /* width: 182px; */
}

.Timeline-menu .main-list-li {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  transition: 0.3s ease;
}

.Timeline-menu .main-list-li:hover {
  background-color: #4d4d4d;
}

.Analytixs-timeline-menue {
  top: 44px;
  right: 19px;
}

.menu .main-list-li:hover {
  background-color: #4d4d4d;
}

.time-above.time-analytics {
  top: 12px;
  right: 239px;
}

.prospect-menue-list ul {
  min-width: 170px;
}

.sigprop {
  overflow: hidden;
  padding-bottom: 2.5rem;
}

.chart-arrow {
  position: absolute;
  top: -32px;
}

.chart-arrow-right {
  right: 7px;
}

.chart-arrow-left {
  left: -11px;
}

.chart-bxslide-container {
  /* width: 100% !important;
	max-width: 768px !important;
	height: 100% !important; */
  height: 100% !important;
  max-width: 100% !important;
}

.chart-bxslide-container canvas {
  /* width: 100% !important; */
  height: 100% !important;
  max-width: 100% !important;
}

#ajeeberrorcrossbtn path,
#ajeeberrorcrossbtn g,
#ajeeberrorcrossbtn {
  width: 25px;
}

.container-selectt.filter-no-solo .menu-campain-orderx {
  margin-right: 0px;
}

.right-side-header-dp-solo .container-select .menu-campain-order {
  margin-right: 0;
}

.right-side-header-dp-solo .search-avatar-library {
  /* width: 245px; */
}

.d-content-item {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #333333;
  padding: 17px 0;
}

.d-content-right-side {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 103px;
}

.d-content-count {
  font-family: "Poppins";
  font-size: 1.7rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  text-align: right;
  color: #e6e6e6;
  margin: 0;
}

.d-content-lable {
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin: 0 1.9rem;
}

.dashbord-container {
  width: 100%;
  /* height: 546px; */
  margin: 0px 0 1.9rem 0px;
  padding: 0rem 1.9rem;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
}

.d-content-list .d-content-item:last-of-type {
  border-bottom: none;
}

.header-dashboard-main {
  position: relative;
}

.header-dashboard-main .my-upload-select-camp {
  /* position: absolute;
	top: -52px;
	right: -17px; */
  outline: none;
  box-shadow: none;
  max-width: 182px;
}

.header-dashboard-main .container-select {
  display: flex;
  align-items: center;
  position: absolute;
  top: -4.5rem;
  right: 0;
  /* min-width: 125px; */
}

.searchBoxongoingCamp {
  text-indent: 1.9rem;
  /* margin-top: 10px; */
  max-width: 245px;
  width: 245px;
  height: 2.5rem;
  padding-left: 10px;
  border-radius: 20px;
  border: solid 1px #333333;
  background-color: #242424;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e4e9ef;
}

.searchBoxongoingCamp::placeholder {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
}

.ongoing-Camp .svgWrapperongoingCamp {
  top: 4px;
  left: 15px;
}

.ongoing-Camp .searchBoxongoingCamp {
  margin: 0 20px 0 0;
}

.search-ongoingCamp {
  position: relative;
  margin-right: 0;
}

.svgWrapperongoingCamp {
  position: absolute;
  top: 8px;
  left: 15px;
}

.onGoingCamp-header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 2.5rem 0;
}

.onGoingCampaign-label {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  display: flex;
  align-items: center;
}

.search-avatar-library.an .svgWrapper {
  top: 8px;
}

.ongoing-Camp .search-avatar-library.an .svgWrapper {
  top: 4px;
}

.ongoing-Camp .search-avatar-library.an .svgWrapper svg {
  width: 1rem;
  height: 1rem;
}

.sigprop .search-avatar-library.an .svgWrapper {
  top: 9px;
}

.sigprop .search-avatar-library.an .svgWrapper svg {
  width: 1rem;
  height: 1rem;
}

.onGoingCampaign-label .info-logo img {
  top: -9px;
  right: -58px;
}

.export-csv-analytuc-popups {
  width: 100px;
  height: 2.5rem;
  margin: 0 50px 0 0px;
  padding: 10px 13.5px 10px 19px;
  border-radius: 25px;
  background-image: linear-gradient(66deg, #e72870, #e76161 91%);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.ongoing-Camp.analytic-detail-popup-video {
  max-width: 1091px !important;
  width: 90vw !important;
}

.ongoing-Camp.analytic-detail-popup-video .vd-modal-content {
  height: 93vh;
  background-color: #333333;
}

.onGoingCamp-container {
  padding: 1.9rem 1.9rem 1.25rem;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
}

.container-select {
  /* display: flex; */
  /* align-items: center; */
  /* position: absolute;
	top: -4.5rem; */
  /* right: 0; */

  /* min-width: 125px; */
}

.header-analytics .container-select {
  display: flex;
  align-items: center;
  position: absolute;
  top: -0.5rem;
  right: 0;

  /* min-width: 125px; */
}

.prospect-email-lable {
  font-family: "Poppins";
  font-size: 1rem;
  /* 1rem */
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e7416a;
}

.prospect-item-header {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.detail-main-container .prospect-item-header {
  margin-bottom: 1rem;
  position: relative;
}

.detail-main-container .prospect-item-header .container-select,
.prospect-item-header.prospect-item-header-popup .container-select {
  top: 0;
}

.prospect-campaign-lable {
  max-width: 20%;
  width: 100%;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin: 0;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0 10px 0 0;
}

.date-pros {
  font-family: "Poppins";
  font-size: 10px;
  min-width: 57px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 37px;
}

.real-analytic-popup .date-pros {
  min-width: 66px;
}

.real-analytic .date-pros {
  min-width: 78px;
}

.d-d-d {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.propsect-item-list {
  display: flex;
  padding: 19px 0 0px 0;
  margin: 0 0 20px 0;
  position: relative;
}

.detail-main-container .propsect-item-list {
  display: flex;
  padding: 0;
  margin: 0;
  padding-bottom: 15px;
}

.prospect-notification-container {
  padding: 8px 10px 7px 9px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.props-tick {
  margin: 0 8px;
}

.props-tick-inner {
  margin: 0 8px;
  min-width: 10px;
}

.props-tick.popup-unseleccted-svg,
.props-tick-inner.popup-unseleccted-svg {
  margin: 0 0px 0 10px;
}

.popup-unseleccted-svg path {
  fill: #4d4d4d;
}

.prospect-hr {
  width: 100%;
  /* max-width: 33px;
	min-width: 20px; */
  border-top: 1px dashed #333333;
  margin: 10px ​0 0 0px;
}

.prospect-items-mini {
  /* min-width: 96px; */
  padding: 7.6px 10px 5.4px 0;
  /* margin: 0 10px 0; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}

.main-dash .prospect-items-mini:last-child {
  /* min-width: 96px; */
}

.main-dash .prospect-items-mini.prospect-items-mini-notSelected:nth-last-child(2),
.main-dash .prospect-items-mini:nth-last-child(2) {
  /* min-width: 136px; */
  /* margin-right: 12%; */
}

.main-dash .expand-prospect-view {
  margin: 0 0 0 21%;
  justify-content: flex-start;
}

.arrow-dashboard-popup svg {
  width: 6px;
  height: 10px;
}

.arrow-dashboard-popup {
  width: 36px;
  height: 59px;
  background: linear-gradient(90deg,
      rgba(51, 51, 51, 1) 0%,
      rgba(51, 51, 51, 0.938813025210084) 52%,
      rgba(51, 51, 51, 0) 100%);
}

.arrow-dashboard-popup .arrow--right {
  right: -4px;
  top: 51%;
  border: none;
  background: linear-gradient(270deg,
      rgba(51, 51, 51, 1) 0%,
      rgba(51, 51, 51, 0.938813025210084) 52%,
      rgba(51, 51, 51, 0) 100%);
}

.arrow-dashboard-popup.right {
  background: linear-gradient(270deg,
      rgba(51, 51, 51, 1) 0%,
      rgba(51, 51, 51, 0.938813025210084) 52%,
      rgba(51, 51, 51, 0) 100%);
}

.arrow-dashboard-popup.left {
  background: linear-gradient(90deg,
      rgba(51, 51, 51, 1) 0%,
      rgba(51, 51, 51, 0.938813025210084) 52%,
      rgba(51, 51, 51, 0) 100%);
}

.arrow-dashboard-popup .arrow--left {
  left: -6px;
  /* opacity: 1; */
  /* display: block; */
  border: none;
  top: 51%;

  background: linear-gradient(90deg,
      rgba(51, 51, 51, 1) 0%,
      rgba(51, 51, 51, 0.938813025210084) 52%,
      rgba(51, 51, 51, 0) 100%);
}

.prospect-items-mini svg {
  /* min-width: 13px; */
}

.prospect-items-mini-notSelected {
  /* min-width: 57px; */
}

.propsect-item-list-popup {
  border-bottom: solid 1px #4d4d4d;
  /* padding-left: 0; */
  margin: 0px 2.5rem 20px 2.5rem;
}

.popup-prospect-con {
  background-color: transparent;
}

.expand-item-label {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expand-view-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 113px;
}

.expand-view-item .props-tick-inner {
  margin-right: 0;
}

.expand-header {
  font-family: "Poppins";
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.expand-view-items {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8.6px 8px 0px 0px;
  /* margin-left: 63px; */
  /* margin-right: 27px; */
}

.expand-view-items:last-child {
  margin-right: 0;
  /* min-width: 120px; */
}

.expand-prospect-view {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 0 21%;
}

.expand-notification-con {
  width: 100%;
  margin: 0 0px 0 0;
}

.prospect-hr-popup {
  border-top: 1px dashed #4d4d4d;
}

.prospect-hr-not-valid {
  /* max-width: 108px; */
}

.sigprop .prospect-hr-not-valid {
  /* max-width: 81px; */
}

.sigprop .prospect-hr {
  width: 100%;
  /* max-width: 18px;
	min-width: 20px; */

  margin: 10px ​0 0 0px;
}

.sigprop .prospect-hr-not-valid {
  /* max-width: 86px; */
}

.sigprop .prospect-items-mini-notSelected:last-child {
  min-width: 136px;
  display: flex;
}

.sigprop .prospect-items-mini:last-child {
  min-width: 136px;
  display: flex;
}

.real-analytic-popup.sigprop .prospect-hr {
  width: 100%;
  /* max-width: 59px;
	min-width: 20px; */

  margin: 10px ​0 0 0px;
}

.real-analytic .expand-prospect-view,
.real-analytic-popup.sigprop .expand-prospect-view {
  margin-left: 0;
}

.real-analytic-popup.sigprop .prospect-hr-not-valid {
  /* max-width: 119px; */
}

.real-analytic-popup.sigprop .expand-view-items {
  /* margin-right: 45px; */
}

.real-analytic-popup.sigprop .expand-view-items:last-child {
  /* margin-right: 0;
	min-width: 135px; */
}

.prospect-notification-container-popup {
  padding: 8px 0px 7px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* width: 74%; */
}

.exapnd-notification-wrapper {
  display: flex;
  justify-content: flex-end;
}

.expand-button {
  position: absolute;
  right: 0;
}

.Prospect-items-popup {
  margin: 0;
  padding: 0;
}

.prospect-item-header .my-upload-select-prosp.my-upload-select-prosp-popup {
  margin: 0 !important;

  background: url("selectArrow.svg") no-repeat right #242424;
  background-position-x: 94%;
}

.analytic-detail-popup-video .vd-modal-header,
.analytic-detail-popup-video .modal-analytic-popup {
  padding: 2.5rem;
}

.analytic-detail-popup-video .modal-analytic-popup {
  padding: 0 0 2.5rem 2.5rem;
}

.chart-custom-marker {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #e7416a;
  border-radius: 100%;
}

.chart-custom-popup {
  /* width: 120px; */

  margin: 0 0 11.4px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #242424;
  position: absolute;
  z-index: 1;
  bottom: 97%;
  left: 19%;
  margin-left: -60px;
}

.signal-icon-popup #Rectangle_267 {
  fill: #4d4d4d;
}

.chart-custom-popup::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -11px;
  border-width: 10px;
  border-style: solid;
  border-color: #242424 transparent transparent transparent;
}

.popup-in-chart,
.popup-in-chart-pinkish {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  color: #e6e6e6;
}

.popup-in-chart-pinkish {
  color: #e7416a;
}

.labelinchart-popup {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.5; */
  letter-spacing: normal;
  text-align: left;
  margin: 0;
}

.labelinchart-popup-label {
  margin: 0;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.5; */
  letter-spacing: normal;
  text-align: left;
}

.modal-analytic-popup-engag .chart-container {
  margin: -1rem;
  max-width: 100%;
  /* padding: 0 2.5rem !important; */
}

.center-custom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-container {
  /* must assign container position as relative */
  position: relative;
  width: 200px;
}

.dropdown-trigger {
  padding: 10px 0px;
}

.dropdown-body {
  position: absolute;
  top: 100%;
  width: 100%;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 1px 1px #888888;
  z-index: 2;
}

.colat-image-label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 143px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin: 0;
}

.colat-item {
  width: 219px;
  display: flex;
  height: 167px;
  border-radius: 5px;

  /* border: solid 1px #4d4d4d; */

  margin: 0 15px 10px 0;
  align-items: center;
  flex-direction: column;
}

.colat-item:first-child {
  margin-left: 0;
}

.colat-container.analytic-detail-popup-video {
  max-width: 1046px !important;
}

.colat-container.analytic-detail-popup-video .modal-analytic-popup {
  overflow-y: auto;
}

.colat-item:last-child {
  margin-right: 0;
}

.colat-item-image {
  width: 219px;
  height: 138px;
  max-width: 100%;
  max-height: 100%;
  object-fit: fill;
}

.colat-labello {
  display: flex;
  margin: 10px 0 0 0;
}

.colat-view {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #e7416a;
  cursor: default;

  display: flex;
  align-items: center;
}

.colat-view.disabled {
  color: #4d4d4d;
}

.colat-list-con {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.colat-items {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.avatar-setting-main-popup-contain .modal-lable-sm-thumbnail {
  margin-bottom: 0;
}

.colat-list-item {
  width: 100%;
  padding: 0 0 1.9rem 0;
  border-bottom: solid 1px #4d4d4d;
  margin: 0 0 1.9rem 0;
}

.colat-list-item:last-child {
  border-bottom: none;
}

.colat-container .analytic-popup-filter {
  margin-right: 20px;
}

.order-trigger {
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  box-shadow: none;
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-left: auto;
  border: none;
  outline: none;
  background: none;
  margin: 0;
  /* margin-right: 20px; */
}

.filterIconCat .order-trigger {
  margin-right: 0;
}

.order-trigger img {
  margin: 0;
}

.CAT.analytic-detail-popup-video .modal-analytic-popup {
  overflow-y: auto;
}

.Order-menu {
  width: 164px;
  right: -30px;
  margin: 0;
  padding: 3px 0 4px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #4d4d4d;
  background-color: #333333;
}

.order-list {
  display: flex;
  justify-content: space-between;
}

.color-disable-outside path {
  fill: #4d4d4d;
}

.main-campaign-checkbox {
  display: block;
  position: relative;
  padding-right: 45px;
  /* margin-bottom: 15px; */
  cursor: pointer;
  /* font-size: 20px; */
  /* max-width: 172px; */
}

.main-campaign-checkbox {
  display: block;
  position: relative;
  padding-right: 39px;
  /* margin-bottom: 15px; */
  cursor: pointer;
  /* font-size: 20px; */
  max-width: 172px;
}

/* Hide the default checkbox */
.campaign-input-check[type="checkbox"] {
  visibility: hidden;
}

/* Creating a custom checkbox
	based on demand */
.geekmark {
  position: absolute;
  top: 0;
  right: 0;
  width: 17px;
  height: 17px;
  background-color: #242424;
}

/* Specify the background color to be
	shown when hovering over checkbox */
.main-campaign-checkbox:hover .campaign-input-check~.geekmark {
  background-color: #292727;
}

/* Specify the background color to be
	shown when checkbox is active */
.main-campaign-checkbox .campaign-input-check:active~.geekmark {
  background-color: #242424;
}

/* Specify the background color to be
	shown when checkbox is checked */
.main-campaign-checkbox .campaign-input-check:checked~.geekmark {
  background-color: #242424;
}

/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Display checkmark when checked */
.main-campaign-checkbox .campaign-input-check:checked~.geekmark:after {
  display: block;
}

/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and 
	showing only two border to make it look
	like a tickmark */
.main-campaign-checkbox .geekmark:after {
  left: 7px;
  bottom: 5px;
  width: 5px;
  height: 8.6px;
  border: solid #e7416a;
  border-width: 0px 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.select-camp-list {
  max-height: 288px;
  overflow-y: auto;
}

.select-camp-list.ana-dash {
  max-height: 431px;
  overflow-y: auto;
}

.select-camp {
  width: 220px;
  height: 2.5rem;
  /* margin: 0 106.9px 0 20px; */
  padding: 0 16px;
  border-radius: 20px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.11);
  background-color: #242424;
}

.table-signals-containers td:last-child {
  border: none;
}

.select-camp span {
  width: 190px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
}

.not-breradcrumb {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.colat-list {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin-bottom: 20px;
}

.seletct-in-chart {
  margin-right: 20px !important;
  width: 136px;
  height: 2.5rem;

  padding: 0 16px;
  border-radius: 20px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.11);
  background-color: #242424;

  color: #e4e9ef !important;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  font-weight: 500;
  background: url("selectArrow.svg") no-repeat right #242424;
  -webkit-appearance: none;
  background-position-x: 88%;
}

.select-inchart-list {
  right: 30px;
  top: 38px;
  width: 182px;
}

.tabsgp-con {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.detail-main-container .tabsgp-con {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.tab-gp1 {
  display: flex;
}

.tab-gp2 {
  display: flex;
  align-items: center;
}

.mark-all-as-read {
  cursor: pointer;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #e7416a;
  display: flex;
  align-items: center;
  margin: 0;
}

.mainDsearchbox {
  width: 245px;
}

.detail-mail-mail-container .detail-sidebar-container {
  margin: 0 0 0 1.9rem;
}

.video-list-wrapper {
  color: #e6e6e6;
  padding: 1.9rem 0 2.5rem 0;
  width: 100%;
}

.video-list-wrapper .react-tabs {
  /* display: flex;
    flex-direction: column; */
  /* height: 100%; */
}

.breadcrumb-custom {
  list-style: none;
  display: flex;
  padding: 0 0 0 31px;
}

.custom-list-tabs {
  width: 119.6px;
  height: 50px;
  margin: 0 0 0 0.4px;
  padding: 11px 16.6px 1rem 17px;

  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  border-radius: 5px;
}

.custom-list-tab-container {
  list-style: none;
  display: flex;
  padding: 0 2.5rem;
}

.custom-list-tabs-selected {
  border-bottom: 5px solid;
  border-image-slice: 1;
  border-width: 4px;
  border-image-source: linear-gradient(92deg, #ea1f72 1%, #d86666);

  color: #e7416a;
}

/* .custom-list-container {
	width: 100%;

	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	row-gap: 1.9rem;
	column-gap: 1.9rem;
} */
.custom-list-container.template-item-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(247px, 1fr));
  align-items: start;
  justify-items: center;
  grid-row-gap: 1.9rem;
  row-gap: 1.9rem;
  grid-column-gap: 1.9rem;
  column-gap: 1.9rem;
  overflow: hidden !important;
}

.custom-list-container .infinite-scroll-component {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(247px, 1fr));

  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  justify-items: center;
  row-gap: 1.9rem;
  column-gap: 1.9rem;
  overflow: visible !important;
}

.search-box-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  justify-items: center;
  row-gap: 1.9rem;
  column-gap: 1.9rem;
}

.input-save-rename {
  padding: 10px 25px 10px 25px;
  border-radius: 20px;
  background-color: #242424;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  width: 93%;
  outline: none;
  border: none;
}

.add-video-from-list-container {
  padding: 25px;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
  height: 100%;
  transition: 0.3s;
}

.add-video-from-list-container:hover {
  background-color: rgba(51, 51, 51, 0.6);
}

.add-video-from-list-member {
  width: 100%;
  height: 100%;
}

.custom-list-item {
  margin: 0;
}

.custom-list-items {
  border: solid 1px #333333;
  border-radius: 5px;

  height: fit-content;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}

.custom-list-items-sales {
  border: solid 1px #333333;
  border-radius: 5px;
  max-width: 291px;
  height: fit-content;
}

.campaign-list .custom-list-items {
  width: 100%;
  /* height: 100%; */
}

.add-video-from-list {
  /* padding: 21% 0; */
  /* padding: 16% 0; */
  border-radius: 5px;
  border: dashed 1px #333333;
  /* background-color: #242424; */
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.add-video-from-list svg {
  font-size: 44px;

  margin-bottom: 1rem;
}

.add-video-from-list label {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.6px;
  text-align: center;
  color: #e6e6e6;
}

.list-vid-detail-container {
  display: flex;
  justify-content: space-between;
  border-top: solid 1px #333333;
  padding: 10px 1rem 10px 1rem;
}

.list-vid-detail-container:first-child {
  margin-left: 5px;
}

.list-img-container {
  cursor: pointer;
  /* width: 100%;
		height: 90%; */
  /* width: 491.6px;
			height: 256.8px; */
}

.list-img-container img {
  /* width: 100%;
			height: 100%; */
  width: 100%;
  height: 100%;
  /* min-height: 176px; */
  max-height: 188px;
}

.tabpanel-sales-popup .list-img-container img {
  width: 100%;
  height: 100%;
  min-height: 155px;
  max-height: 155px;
}

.lg-input-detail {
  width: 100%;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  padding: 10px 1rem;
  border-radius: 20px;
  border: solid 1px #333333;
  background-color: #242424;
}

.thumbnail-details-container {
  display: flex;
}

.thumbnail-text-body {
  border-bottom: solid 1px #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
}

.thumbnail-text-body-sales {
  border-bottom: solid 1px #333333;
  display: flex;
  flex-direction: column;
  border: none;
  padding: 10px 0px;
  justify-content: space-between;
}

.lg-lable-input {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin-right: 5px;
  padding-top: 5px;
}

.thumbnail-update-details {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;

  width: 92px;
  height: 2.5rem;

  border-radius: 25px;
  background-color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.fevicon-container-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.fevicon-container-item img {
  width: 70px;
  height: 70px;

  border-radius: 5px;
  border: solid 1px #333333;
}

.fevicon-container-upload {
  width: 140px;
  height: 30px;
  height: 2.5rem;
  display: flex;
  margin: 0 10px 0 10px;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: #333333;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.fevicon-container-remove {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  background: none;
}

.lable-inputx123 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin-bottom: 10px;
}

.fevicon-container-preview {
  width: 100%;
  max-width: 310px;
  height: 2.5rem;
}

.sidebar-content-details-items {
  padding: 1.9rem 0 1.9rem 0;
  border-bottom: 1px solid #333333;
}

.preview-edit-toolbar-salespage {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0px 20px 0px;
  width: 100%;
  max-width: 900px;
}

.pro-inner-item {
  padding: 3px 24px 3px 14px !important;
}

.fevicon-preview {
  position: relative;
}

.favicon-icon-preview {
  position: absolute;
  top: 1rem;
  width: 15px;
  height: 15px;
  left: 17%;
}

.favicon-bar-name {
  position: absolute;
  top: 0.8rem;
  width: auto;
  height: 15px;
  left: 26%;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.favicon-tab-preview {}

.favicon-wrapper {
  width: 70px;
  height: 70px;
  margin: 0 1rem 0 0;
  padding: 10px;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
}

.favicon-container {
  width: 50px;
  height: 50px;
  padding: 18px;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
  color: #4d4d4d;
  font-size: 10px;
}

.favicon-notuploaded {
  display: flex;
  flex-direction: column;
}

.detail-list-name {
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.6px;
  text-align: left;
  color: #e6e6e6;
  width: 100%;
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.6px;
}

.list-vid-detail {
  width: 88%;
}

.detail-list-deatils {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.generate-video-block-container,
.hover-list-overlay {
  position: relative;
  flex-grow: 1;
  min-height: 100%;
}

.list-generate-image-overlay,
.list-preview-image-overlay,
.list-generate-image-overlay-nohover {
  position: absolute;
  width: 101%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 18% 77px 0 77px;

  background-color: rgba(34, 34, 34, 0.8);
}

.list-generate-image-overlay-camp {
  position: absolute;
  width: 101%;
  height: 100%;
  top: 0;
  left: 0;

  padding: 11px 1px 1px 85%;
  width: 100%;
  height: 100%;
  min-height: 155px;
  max-height: 155px;
}

.salespop .list-generate-image-overlay-camp {
  position: absolute;
  width: 101%;
  height: 100%;
  top: 0;
  left: 0;

  padding: 11px 1px 1px 80%;
}

.percentage-generate {
  margin: 17px 0 8px 0;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.remaining-time-generate {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #a9a9a9;
}

.overlay-list-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.play-list-button {
  min-width: 131.2px;
  height: 30px;
  margin: 0 5px 10px 0;
  color: #e6e6e6;
  border-radius: 25px;
  background-image: linear-gradient(76deg, #e72870 1%, #e76161);
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.6px;
  text-align: left;
  color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
}

.play-list-button-insales {
  min-width: 154.2px;
}

.play-list-button-draft {
  min-width: 213px;
}

.play-list-button svg {
  margin-left: 12px;
}

.details-list-image-overlay {
  width: 131.2px;
  height: 30px;

  margin: 10px 0 5px 0;
  color: #e6e6e6;
  border-radius: 25px;
  border: solid 1px #333333;
  background-color: #333333;
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.6px;
  text-align: left;
  color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-vd-list {
  border-radius: 5px;
  background-color: #333333;
}

.filter.menu-vd-list-item {
  justify-content: space-between;
}

.menu-vd-list-item {
  width: 164px;
  padding: 1px 5px 1px 4px;

  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.3s;
  /* justify-content: space-between; */
}

.menu-vd-list-item.list-main {
  padding: 10px 16px;
}

.menu-vd-list-item:hover {
  background-color: #4d4d4d;
  color: #e6e6e6 !important;
}

.list-generate-image-overlay,
.list-generate-image-overlay-camp {
  display: none;
  /* transition: 0.5s ease; */
}

.hover-list-overlay:hover .list-generate-image-overlay,
.list-img-container:hover .list-generate-image-overlay-camp {
  display: block;
}

.details-conatiner {
  display: flex;
  position: relative;
}

.actions-details-page {
  position: absolute;
  right: 0px;
  top: -3.8rem;
  display: flex;
}

.sm-action-detail {
  border-radius: 100%;
  padding: 0;
  margin: 0 7.5px;
  border: 0;
  background-color: #242424;
}

.sm-action-detail svg {
  border-radius: 50%;
}

.launch-detail-btn {
  margin: 0 7.5px;
  width: 180px;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 10px 0px;
  border-radius: 25px;
  background-image: linear-gradient(80deg, #e72870 1%, #e76161);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  padding: 0px 3px 0 11px;
}

.detail-main-container {
  width: 59.6%;
  /* max-width: 900px; */
  /* display: inline-block; */
}

.detail-sidebar-container {
  width: 41.4%;
  margin: 0 0 0 20px;
  /* display: inline-block; */
}

.preview-salespage-image {
  border-radius: 5px;
  border: solid 1px #242424;
  width: 100%;
  max-width: 900px;

  position: relative;
}

.preview-salespage-image img {
  width: 100%;
  height: 100%;
}

.list-preview-image-overlay {
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s ease;
}

.preview-salespage-image:hover .list-preview-image-overlay {
  opacity: 1;
}

.view-live-page {
  width: auto;
  height: 2.5rem;

  padding: 0 15px;
  border-radius: 25px;
  background-image: linear-gradient(80deg, #e72870 1%, #e76161);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.bread-crumb-container {
  list-style: none;
  display: flex;
  align-items: center;
}

.breadcrumb-separator {
  color: #333;
  margin: auto 6px;
  user-select: none;
}

.edit-sales-page {
  height: 2.5rem;
  margin: 0 15px 0 0;
  padding: 10px 1rem;
  border-radius: 25px;
  background-color: #333333;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #e6e6e6;
}

.edit-page-name-change {
  background: #222222;
  outline: none;
  border: solid 1px #222222;
  color: #e6e6e6;
  border-radius: 25px;
  padding: 13px 1rem 13px 1rem;
  border-radius: 5px;
  width: 56%;
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  transition: 0.3s;
}

.edit-page-name-change:hover {
  background-color: #242424;
}

.edit-page-name-change:focus {
  background-color: #333333;
  border: solid 1px #4d4d4d;
}

.details-edit-page-buttons {
  background: none;
}

.preview-edit-toolbar {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 20px 0px;
  width: 100%;
  max-width: 900px;
}

.detail-Video-desc {
  padding-top: 19px;
}

.heading-sm-detail {
  margin-bottom: 10px;
  display: flex;
}

.heading-sm-detail label {
  margin-right: 0px;
  margin-bottom: 0px;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  display: flex;
  align-items: center;
}

.sidebar-content-details {
  padding: 1.9rem 1.9rem 20px 1.9rem;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
  margin: 0 0 30px 0;
  margin: 0 0 20px 0;
}

.heading-lg-detail {
  display: flex;
  margin: 0 0 12px 0;
  align-items: center;
}

.heading-lg-detail label {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin: 0;
  display: flex;
  align-items: center;
}

.para-sm-detail {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  margin-bottom: 22px;
}

.para-md-detail {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  padding: 10px 0px 3px 0px;
}

.input-x123 {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 20px 0;
}

.sm-lable-input {
  width: 30%;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  display: block;
  white-space: nowrap;
  padding: 6px 28px 6px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sm-input-detail {
  width: 66%;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  padding: 5px 1rem 5px 1rem;
  border-radius: 20px;
  border: solid 1px #333333;
  background-color: #242424;
}

.mergetag-detail {
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 0;
}

.detail-Video-desc {
  border-top: 1px solid #4d4d4d;
}

.slider-detials {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333333;
  transition: 0.4s;
}

.campaign-details-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0 1rem 0;
  border-bottom: solid 1px #333333;
}

.campaign-details-container:last-child {
  border: none;
  padding-bottom: 0;
}

.lable-salespopup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.campaign-details-img {
  width: 120px;
  height: 69px;
  border-radius: 5px;
  margin-right: 14px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
}

.para-campaign-detail {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.campaign-lable-input {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.6px;
  text-align: left;
  color: #e6e6e6;
}

.video-list-main-container {
  margin: 1rem 2.2rem 2.5rem 2.5rem;
}

.video-tags-preview {
  width: 96px;
  height: 1.9rem;
  margin: 0 20px 0 0;
  padding: 5px 1rem;
  border-radius: 15px;
  border: solid 1px #4d4d4d;
  background-color: #333333;
}

.detail-vd-tags-container {
  margin-top: 25px;
}

.breadcrumbs-items {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  display: flex;
  align-items: center;
}

.active-breadcrumb {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.breadcrumb-item-lib {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: left;
  color: #a9a9a9;
  display: flex;
  align-items: center;
}

.breadcrumb-item-lib:last-child {
  color: #e6e6e6;
  cursor: default;
}

.search-filter-container {
  display: flex;
  margin-left: auto;
  margin-bottom: 2.5rem;
  justify-content: flex-end;
}

.search-avatar-library {
  margin-right: 20px;
}

.detail-main-container .search-avatar-library {
  margin-right: 0px;
}

.main-dash .search-avatar-library {
  margin-right: 0px;
}

.para-input-detail {
  background: none;
  outline: none;
  border: none;

  border-radius: 5px;
  width: 100%;

  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  width: 100%;
  margin: 10px 0px;
}

.detail-description.newDetails:hover {
  background-color: #242424;
}

.detail-description.newDetails:focus-within {
  border: solid 1px #4d4d4d;
  background-color: #333333 !important;
}

.custom-list-tabs,
.breadcrumb-item-lib,
.pointer {
  cursor: pointer;
}

.detail-description.newDetails {
  margin: 10px 0 0;
  padding: 8px 1rem 8px 1rem;
  border-radius: 5px;
  border: dashed 1px #333333;
  background-color: #222222;
  transition: 0.3s;
}

.detail-description.newDetails:hover {
  background-color: #242424;
}

.para-input-detail::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
}

.para-input-detail::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
}

.para-input-detail :-ms-input-placeholder {
  /* IE 10+ */
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
}

.para-input-detail :-moz-placeholder {
  /* Firefox 18- */
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
}

.creditbx001 {
  width: 40%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

.creditbx002 {
  width: 60%;
}

.twotone-credit {
  border-radius: 6px;
  display: flex;
  width: 224px;
  height: 50px;
  background-color: #f87f73;
  background-image: -webkit-linear-gradient(-78deg,
      #333333 40%,
      #333333 49%,
      #e76161 50%,
      #e72870 96%);
  background-image: linear-gradient(-78deg,
      #333333 40%,
      #333333 49%,
      #e76161 50%,
      #e72870 96%);
}

.tone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  font-family: "Poppins";
  font-size: 27px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.pink-tone {
  font-weight: bold;
}

.black-tone {
  font-weight: 500;
}

.date-in-credit {
  font-family: "Poppins";
  font-size: 27px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.renew-sub-button {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #e6e6e6;
  width: 179px;
  height: 2.5rem;
  margin: 25px auto 4.5px auto;
  padding: 0 20px;
  border-radius: 25px;
  background-color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videocreditleft {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin: 10px auto 5px auto;
}

.date-in-credit {
  font-family: "Poppins";
  font-size: 27px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin: 0 auto 60px auto;
}

.sm-in-credits {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.credit-list-item {
  display: flex;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px #333333;
}

.credit-list-inneritem {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 20px;
}

.bold-incredits {
  font-weight: 600;
}

.name-in-credits-list {
  font-family: "Poppins";
  font-size: 14px;

  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.time-in-credit-his {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  margin-top: 10px;
}

.credit-list-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.credit-box-wrapper {
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
  display: flex;
  margin-bottom: 60px;
}

.creditbx001 {
  padding: 30px 0px 50px 0px;
}

.creditbx002 {
  padding: 50px 55px 0px 35px;
  border-left: solid 1px #333333;
}

.p-un-credit {
  padding: 9px 0 29px 0;
}

.Rectangle-272-credit {
  width: 100%;
  height: 336px;
  margin: 0 1.9rem 1.9rem 0;
  padding: 50px;
  border-radius: 5px;
  border: solid 2px #333333;
  background-color: #242424;
}

.addon-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  width: 209px;
  height: 2.5rem;
  padding: 0px 20px;
  border-radius: 25px;
  background-image: linear-gradient(80deg, #e72870 -4%, #e76161 100%);
}

.about-addon {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  margin: 0 0 30px 0;
}

.heading-addons {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin: 0 0 10px 0;
}

.header-addon {
  font-family: "Poppins";
  font-size: 50px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e7416a;
  margin: 0 0 20px 0;
  display: flex;
}

.addons-container-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.9rem;
}

.dates-header-in-subs {
  display: flex;
}

.date-item-in-header {
  margin: 10px 60px 0 0;
}

.date-label-headr {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  margin-right: 3px;
}

.date-label-date {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.subs-cred-hiss-pop .credit-list-container svg rect {
  fill: #4d4d4d;
}

.subs-cred-hiss-pop .credit-list-item {
  display: flex;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px #4d4d4d;
}

.subs-cred-hiss-pop .credit-list-item:last-child {
  border: none;
}

.CAT.analytic-detail-popup-video.ProfilePopupEdit.passwordChangePopup.subs-cred-hiss-pop .vd-modal-content {
  height: 75vh !important;
}

.CAT.analytic-detail-popup-video.ProfilePopupEdit.passwordChangePopup.subs-cred-hiss-pop {
  max-width: 620px !important;
}

.thumbnail-lable-input {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.popup-addon-about-container {
  margin: 1.9rem 24px 0 24px;
  padding: 1.9rem;
  border-radius: 5px;
  background-color: #242424;
  display: flex;
}

.nc-oop {
  margin-left: 2.5rem;
}

.profile-box-wrapper {
  padding: 50px 1.9rem 50px 55px;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
  display: flex;
}

.profile-peronal-container,
.profile-links-container {
  width: 50%;
  display: flex;
}

.image-profile-setting {
  width: 150px;
  height: 150px;
  border-radius: 100%;
}

.profile-imp-details {
  display: flex;
  flex-direction: column;
  margin: 0px 0 0 60px;
}

.info-heading-dsm {
  padding: 0 0 0 34px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  position: relative;
  margin: 15px 0 15px 0;
}

.info-heading-sm {
  font-family: "Poppins";
  padding: 0 0 0 34px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin: 0 0 15px 0;
}

.logo-personal-profile {
  position: absolute;
  left: 0px;
  top: 4px;
}

.name-in-profile {
  font-family: "Poppins";
  font-size: 1.9rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin: 0;
}

.label-type-of-user {
  width: 80px;
  height: 22px;

  border-radius: 5px;
  border: solid 1px #e7416a;
  background-color: #242424;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e7416a;
  margin: 0 0 8px 1.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-in-profile {
  display: flex;
  align-items: center;
  margin: 0 0 19px;
  justify-content: center;
}

.info-list-in-profile {
  display: flex;
  flex-direction: column;
}

.bx-22pp {
  border-left: 1px solid #333333;
  margin: 0px 0 0 0;
  padding: 0 0px 0 50px;
}

.bx0098i2 {
  color: #e7416a;
}

.bx0098i2.disable {
  color: #4d4d4d;
}

.left-inbx2-profile {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.button-n-bx2-profile {
  height: 2.5rem;

  padding: 0px 0.5rem;
  border-radius: 25px;
  background-color: #333333;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-n-bx2-profile:first-child {
  /* margin: 0 20px; */
}

.tab-myupload-continer-music {}

.tab-myupload-continer-campaign-csv .tab-myupload-container {
  margin: 5px;
  margin-bottom: 0;
}

.tab-myupload-continer-music .infinite-scroll-component {
  /* display: flex;
	justify-content: flex-start;
	flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(118px, 1fr));
  grid-gap: 1.9rem;
}

.tab-myupload-continer-campaign-csv .infinite-scroll-component {
  /* display: flex;
	justify-content: flex-start;
	flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(108px, 1fr));
  grid-gap: 1.3rem;
}

.tab-myupload-continer-music .bgMusic-single-container {
  width: 118px;
}

.tab-myupload-continer-music .bgMusic-vd-setting {
  max-width: 118px;
  max-height: 118px;
}

.tab-myupload-continer .infinite-scroll-component {
  /* display: flex;
					justify-content: flex-start;
					flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(297px, 1fr));
  grid-gap: 1.9rem;
}

.image-container-myupload {
  /* margin: 1.9rem 1.9rem; */
  /* max-width: 342.5px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.image-container-myupload {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  display: flex;
}

.image-my-upload {
  width: 100%;
  height: 194px;
  margin: 0 0 6px;
  border-radius: 5px;
}

.react-tabs__tab {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.label-bx88i_name {
  min-width: 115px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.ongoing-Camp.analytic-detail-popup-video .menu-trigger img {
  margin-right: 10px;
  /* border-radius: 90px; */
}

.bx88i_input {
  max-width: 490px;
  width: 100%;
  height: 2.5rem;
  margin: 0 0 0 15px;
  padding: 10px 1rem 10px 1rem;
  border-radius: 20px;
  border: solid 1px #333333;
  background-color: #242424;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
}

.bxppp-item {
  display: flex;
  align-items: center;
  margin: 1.9rem 0;
  position: relative;
}

.bxppp-item:first-child {
  margin-top: 0;
}

.bx88i_input:focus {
  color: #e6e6e6;
  border: solid 2px #e7416a;
}

.bxppp_img-container {
  position: relative;
  width: 150px;
  text-align: center;
  margin: 0 110px 0 0;
}

.bjjjhhs_button {
  cursor: pointer;
  position: absolute;
  bottom: 54px;
  right: -1rem;
}

.bjjjhhs_remove {
  cursor: pointer;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e7416a;
  margin-top: 10px;
}

.bxppp_img {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: solid 1px #333333;
  margin: 0 0 10px 0;
}

.bx88i_left {
  width: 100%;
  max-width: 516px;
}

.bx8882_1 {
  display: flex;
  padding: 9px 0 19px 0;
  border-bottom: 1px solid #4d4d4d;
  justify-content: space-between;
}

.bx88i_right {
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
}

.bcpp_lower .bx88i_input {
  max-width: none;
  width: 100%;
}

.bx8882_2 {
  display: flex;
  padding: 50px 0 0 0;
  /* border-bottom: 1px solid #4d4d4d; */
}

.ProfilePopupEdit .modal-footer {
  justify-content: flex-end !important;
  border-top: solid 1px #4d4d4d !important;
}

.cancel-popup-profile-edit {
  width: 11.9rem;
  height: 2.5rem;
  margin: 0 20px 0 0;
  padding: 0 15px;
  border-radius: 25px;
  background-color: #4d4d4d;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.bcpp_lower .bxppp-item {
  width: 100%;
}

.bcpp_lower {
  width: 100%;
}

.CAT.analytic-detail-popup-video.ProfilePopupEdit {
  max-width: 920px !important;
}

.CAT.analytic-detail-popup-video.ProfilePopupEdit .vd-modal-content {
  height: auto !important;
}

.CAT.analytic-detail-popup-video.ProfilePopupEdit.passwordChangePopup {
  max-width: 780px !important;
}

.CAT.analytic-detail-popup-video.ProfilePopupEdit.passwordChangePopup .vd-modal-content {
  height: auto;
}

.popup-profile-edit-select-btn {
  width: 11.9rem;
  height: 2.5rem;
  outline: none !important;
  color: #e4e9ef;
  margin: 0 0 0px 20px;
  border: none;
  border-radius: 25px;
  background-image: linear-gradient(77deg, #e72870 -4%, #e76161);
}

.pxbllk11 {}

.label-bx88i_name.pxbllk11 {
  width: 225px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.passwordChangePopup .bx8882_2 {
  padding: 0 0 0 0;
}

.lock-in-input {
  position: absolute;
  right: 15px;
}

.my-upload-box-wrapper {
  margin-top: 60px;
}

.my-upload-video-in-setting video {
  display: block;
  width: 100%;
  object-fit: fill;
  height: 100%;
}

.docs-preview {
  width: 80vw;
  height: 90vh;
}

.docs-preview iframe {
  width: 100%;
  height: 100%;
}

.sub-without-icon {
  padding: 0 10px 0 0;
}

.black-tabs .react-tabs__tab-list {
  border-bottom: solid 1px #333333 !important;
  margin-bottom: 0;
}

.black-tabs .react-tabs__tab {
  border: solid 1px #333333 !important;
}

.black-tabs .react-tabs__tab--selected {
  background: #242424 !important;
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.2);

  border: none !important;
  outline: none !important;
  border-top: 4px solid !important;
  border-image: linear-gradient(92deg, #ea1f72 1%, #d86666) !important;
  border-image-slice: 1 !important;
}

.black-tabs .react-tabs__tab-panel--selected {
  padding: 35px;

  border: solid 1px #333333;
  border-top: 0;
}

.campaign-list-image-overlay {
  width: 216px;
}

.selected-camp-item {
  border: solid 5px #e7416a;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.selected-camp-item.salespop {
  /* outline: solid 5px #e7416a; */
  /* margin: 5px; */
}

.campaign-header {
  display: flex;
  justify-content: space-between;
  padding: 22px 1.9rem 22px 1.9rem;
  border-bottom: solid 1px #333333;
}

.campaign-header-heading {
  display: flex;
}

.campaign-header-lable {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin: 0 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.campaign-steps {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}

.breadcrumb-item-lib-camp {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-camp-bread {
  color: #e7416a;
}

.campaign-breadcrumb {
  display: flex;
}

.campaign-header-action {
  display: flex;
}

.camp-action-prime {
  width: 130px;
  height: 40px;
  margin: 0 7.5px;
  padding: 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-image: linear-gradient(72deg, #e72870 5%, #e76161);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.camp-action-sec {
  width: 130px;
  height: 40px;
  padding: 0px 0px;
  margin: 0 7.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: #333333;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.camp-action-nt-slected {
  background-color: #a9a9a9;
  background-image: none;
  pointer-events: none;
  cursor: not-allowed;
}

.camp-bread-arrow {
  margin-top: 5px;
}

.camp-selected-prev {
  display: block !important;
}

.analytic-icon {
  margin: 10px;
}

.bredcrumbIconcamp {
  margin: 0 15px;
}

.video-sales-used {
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  margin-top: 1.9rem;
}

.video-sales-used .detail-description {
  /* margin-right: 137px; */
  max-width: 200px;
}

.some-used-popup-con {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.some-used-popup-img {
  width: 182px;
  height: 109.2px;
}

.label-used-popup-con {
  margin: auto 25px auto 25px;
  text-align: center;
}

.some-used-popup-button {
  height: 30px;
  margin: 10px 0 0;
  padding: 0px 1rem;
  border-radius: 25px;
  background-color: #333333;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.launch-campaign-details {
  width: 159px;
  height: 2.5rem;

  padding: 0px 1rem 0px 1rem;
  display: flex;
  align-items: center;
  border-radius: 25px;
  background-color: #a9a9a9;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.launch-campaign-details.selected {
  background-image: linear-gradient(78deg, #e72870 1%, #e76161);
  background-color: none;
}

.popup-video-edit {
  display: flex;
  margin: 0 25px;
}

.image-in-popup img {
  width: 249px;
  height: 151px;
  margin: 0 0 0 1.9rem;
}

.camp-detail-popup-video {
  max-width: 785px !important;
  width: 100% !important;
}

.camp-detail-popup-video .vd-modal-header {
  padding: 30px 40px;
}

.text-in-popup {
  border-right: solid 1px #4d4d4d;
  padding-right: 31px;
}

.camp-detail-popup-video .vd-modal-content {
  /* height: 363px; */
  min-height: auto;
  background-color: #333333;
}

.camp-detail-popup-video .vd-modal-footer {
  padding: 2.5rem;
  border: none !important;
  justify-content: flex-end !important;
}

.camp-detail-popup-video .vd-modal-footer span {
  display: flex;
  justify-content: flex-end;
}

.camp-detail-popup-video .vd-modal-content {
  height: auto;
}

.edit-duplicate-camp {
  width: 160px;
  height: 2.5rem;
  margin: 0px;
  border-radius: 25px;
  background-color: #4d4d4d;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.det-sidebar {
  border: solid 1px #333333;
  border-top: 0;
  padding: 35px;
  padding-bottom: 54px;
  background-color: #242424;
}

.Group-Campaign-via-Email {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.8px;
  text-align: left;
  color: #e6e6e6;
}

.Group-Campaign-via-Email-con .d-flex {
  margin-bottom: 20px;
}

.group-det-camp-wrapper {
  min-height: 69vh;
  max-height: 69vh;
}

.group-det-camp-wrapper-form {
  min-height: 500px;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
}

.tabs-camp-det-tabs ul {
  border-bottom: none;
}

.tabs-camp-det-tabs .react-tabs__tab-list {
  margin: 0;
}

.tabs-camp-det-tabs .tabLabel {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #e6e6e6;
  padding: 0 10.4px 0 10.4px;
}

.tabs-camp-det-tabs .react-tabs__tab--selected .tabLabel {
  color: #e7416a;
}

.tabs-camp-det-tabs .tabLabel .ntsvg,
.tabs-camp-det-tabs .tabLabel .selectedSvg {
  margin: 0px 10px;
}

.tabs-camp-det-tabs .react-tabs__tab {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: none;
  min-width: auto;
  width: 100%;
}

.groupCamp-no-shared {
  padding: 184px 62px 316px 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.share-with-lable {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #a9a9a9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-with-a-group {
  height: 2.5rem;
  margin: 20px auto;
  padding: 10px 20px;

  border-radius: 25px;
  background-image: linear-gradient(82deg, #e72870 0%, #e76161 97%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.sm-btn-camp-detail-group {
  margin-right: 10px;
  padding: 6px 1rem 7px;
  border-radius: 25px;
  background-color: #333333;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: left;
  color: #e6e6e6;
}

.Rectangle-272 {
  width: 100%;
  /* max-width: 52.5rem; */
  height: 81px;
  margin: 20px 0;
  padding: 12px 12.7px 13px 12.7px;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #222222;
}

.Rectangle-273 {
  height: 56px;

  border-radius: 5px;
  border: dashed 1px #333333;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.14px;
  text-align: left;
  color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Rectangle-csvlist {
  /* width: 52.5rem; */
  height: 81px;
  margin: 20px 0;
  padding: 17.5px 20px 1rem 1rem;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
}

.ContactListcsv {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.6px;
  text-align: left;
  color: #e7416a;
  margin: 0 15px 15px 0;
  padding-right: 15px;
}

.camp-form-item {
  display: flex;
  justify-content: space-between;
}

.Rectangle-csvlist {
  display: flex;
  justify-content: space-between;
}

.csv-det-con {
  display: flex;
  flex-direction: column;
}

.csv-det-con .ContactListcsv {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.6px;
  text-align: left;
  color: #e7416a;
  margin: 0 0px 0px 0;
  padding-right: 15px;
}

.csvPop-detail-popup-video {}

.camp-time-detail {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.csvdet {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.copy-code {
  margin: auto 0;
  width: 127px;
  height: 2.5rem;
  padding: 10.1px 10.5px 9.9px 10.5px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  background-image: linear-gradient(74deg, #e72870 -4%, #e76161 100%);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #e6e6e6;
}

.form-camp-det-gp-lable {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  /* margin-bottom: 15px; */
}

.form-camp-det-gp-lable-con {
  display: flex;
  margin-bottom: 15px;
}

.ooosjj112 {
  display: flex;
  flex-direction: column;
}

.ooosjj112 .image-related-content {
  display: flex;
}

.ooosjj112 .image-related-content img {
  width: 135px;
  height: 80px;
  object-fit: cover;
}

.ooosjj112 .content-used-popup-button,
.popup-camp-det-action .content-used-popup-button {
  width: 143px;
  padding: 10px 20px;
  border-radius: 25px;
  background-color: #333333;
  height: 2.5rem;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-camp-det-action .content-used-popup-button {
  width: 102px;
}

.ooosjj112 .content-campaign-lable-input {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.ooosjj112 .content-wrapper {
  width: 100%;
  margin-bottom: 32px;
}

.ooosjj112 .content-wrapper:last-child {
  width: 100%;
  margin-bottom: 10px;
}

.ooosjj112 .my-upload-select {
  width: 100% !important;
  background-position-x: 97%;
}

.ooosjj112 .content-3 {
  display: flex;
  margin-bottom: 20px;
}

.ooosjj112 .content-3-campaign-lable-input {
  padding-top: 10px;
  margin-left: 20px;
}

.ooosjj112 .popup-camp-det-action {
  padding: 20px 0;
}

.ooosjj112 .next-camp-12 {
  height: 2.5rem;

  padding: 10px 27.4px;
  border-radius: 25px;
  background-image: linear-gradient(72deg, #e72870 2%, #e76161 100%);
}

.popup-camp-det-action .next-camp-12 {
  padding: 10px 27.4px;
  border-radius: 25px;
  background-image: linear-gradient(72deg, #e72870 2%, #e76161 100%);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  width: 102px;
}

.popup-camp-det-action .content-used-popup-button {
  margin: 0 20px 0 0;
}

.popup-camp-det-action {
  display: flex;
  justify-content: flex-end;
  border-top: solid 1px #333333;
  padding: 20px 0 0 0;
}

.popup-camp-det-action.campaign-error {
  display: flex;
  justify-content: space-between;
  border-top: solid 1px #333333;
  padding: 20px 0 0 0;
}

.Group-Campaign-via-Email-99k {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin-bottom: 20px;
}

.input-label-camp-gp {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.input-camp-gp {
  border: none;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;

  width: 61%;

  height: 2.5rem;
  margin: 10px 0 10px;

  padding: 10px 0 10px 1rem;
  border-radius: 20px;
  background-color: #333333;
}

.bx88i_left {
  /* width: 40%; */
}

.next-camp-12xx {
  height: 2.5rem;
  padding: 0px 1rem 0px 1rem;
  border-radius: 25px;
  background-color: #a9a9a9;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  display: flex;
  align-items: center;
}

.next-camp-12xx.selected {
  background-image: linear-gradient(72deg, #e72870 2%, #e76161 100%);
  background-color: none;
}

.header-analytics .Timeline-select-trigger {
  margin-bottom: 0 !important;
}

.camp-test-detail-popup-video .vd-modal-header,
.camp-test-detail-popup-video .modal-avatar-select,
.camp-test-detail-popup-video .vd-modal-footer {
  padding: 1.9rem 2.5rem;

  background-color: #333333;
}

.camp-test-detail-popup-video .vd-modal-header {
  padding: 2.5rem 2.5rem 1.9rem 2.5rem;
}

.camp-test-detail-popup-video .vd-avatar-select-btn,
.camp-detail-popup-video .vd-avatar-select-btn {
  margin: 0 0 0 20px;
  font-weight: 500;
  font-family: "Poppins";
  font-size: 14px;

  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #dcf5f5;
}

.camp-test-detail-popup-video .vd-modal-footer {
  border: none !important;
}

.camp-test-detail-popup-video .vd-modal-footer span {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.camp-test-detail-popup-video .vd-modal-content,
.deletePop-detail-popup-video .vd-modal-content {
  background-color: #333333;
  height: auto !important;
}

.contactus-container {
  width: 100%;
}

.contactus-container .profile-imp-details {
  margin: 0;
}

.contactus-container .renew-sub-button {
  margin: 50px auto 0 auto;
}

.contact-box-wrapper {
  padding: 25px;
  border-radius: 5px;
  /* border: solid 1px #333333; */
  /* background-color: #242424; */
  display: flex;
}

.text-in-contact {
  width: 100%;
  height: 300px;
  padding: 10px;
  border-radius: 20px;
  border: solid 1px #333333;
  background-color: #242424;
  font-family: "Poppins", sans-serif;
  color: #e6e6e6;
}

.camp-test-detail-popup-video {
  max-width: 661px !important;
}

.test-mail-popup-input {
  border: none;
  width: 100%;
  padding: 10px 1rem 10px 1rem;
  border-radius: 20px;
  background-color: #242424;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
}

.test-mail-in-popup-lable {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin-bottom: 1.9rem;
}

.testlink-label-camp-gp {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.del-popup-heading {
  display: flex;
  justify-content: flex-end !important;
}

.deletecamp-lable-lg {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.5rem 0 20px 0;
}

.deletecamp-lable-sm {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.deletecamp-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.deletePop-detail-popup-video {
  max-width: 785px !important;
}

.ai-sales-footer {
  height: 113px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* .deletePop-detail-popup-video{
					max-width: 785px !important;
				} */
.deletePop-detail-popup-video .vd-modal-footer {
  border: none !important;
}

.deletePop-detail-popup-video .vd-modal-footer span {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.deletePop-detail-popup-video {
  max-width: 785px !important;
  min-width: 785px !important;
}

@media screen and (max-width: 1000px) {
  .deletePop-detail-popup-video {
    max-width: 785px !important;
    min-width: auto !important;
  }
}

.deletePop-detail-popup-video .vd-modal-header,
.deletePop-detail-popup-video .modal-avatar-select,
.deletePop-detail-popup-video .vd-modal-footer {
  padding: 2.5rem;
  background-color: #333333;
}

.campign-edit-toolbar-salespage {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.campign-edit-toolbar {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.campign-edit-toolbar-label {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-camp-input {
  border: solid 1px #e7416a !important;
}

.input-error-camp-lable {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #e7416a;
}

.csv-popup-heading {}

.csvpopup-heading {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.csvPop-detail-popup-video {
  max-width: 1582px !important;
  min-width: 1582px !important;
  width: 80vw !important;
}

@media screen and (max-width: 1654px) {
  .csvPop-detail-popup-video {
    max-width: 1582px !important;
    min-width: auto !important;
  }
}

.csv-popup-footer {
  border-top: none !important;
}

.table-csv-header-popup {
  padding: 15px;
  background-color: #242424;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e7416a;
  border-bottom: solid 1px #4d4d4d;
  /* width: 100%; */
}

.csv-table {
  width: 100%;
}

.csvTable {
  overflow-x: scroll;
}

.table-csv-block-popup {
  padding-left: 15px;
  border-right: solid 1px #4d4d4d;
  border-bottom: solid 1px #4d4d4d;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 557px;
}

.__csv-email0name {
  display: flex;
  justify-content: space-between;
}

.__csv-email0name span {
  padding: 15px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copied-in-csv {
  float: right;
  width: 80px;
}

.GroupEmailId {
  background-color: #242424;
  border: solid 1px #4d4d4d;
  border-top: none;
  border-left: none;
}

.text-in-popup-lable {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin: 0 24px;
}

.capaign-details-container .actions-details-page {
  right: -15px;
  top: -56px;
}

.selectedRowCol {
  background-color: rgba(231, 65, 106, 0.1);
}

.selectedBlockCsv {
  background-color: rgba(231, 65, 106, 0.6);
}

.csv-total-left,
.csv-numbers-pagination {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 32px;
  margin-right: 1rem;

  border-right: solid 1px #333333;
}

.csv-total-left {
  position: absolute;
  border: none;
  right: -188px;
  top: 13px;
}

.pagination-csv {
  display: flex;
  margin: auto !important;
  position: relative;
  top: 0;
}

.pagination-csv-box {
  padding: 0 1rem 0 20px;
  opacity: 0.79;
  border-radius: 25px;
  background-color: #242424;
  display: flex;
}

.csv-buttons {
  display: flex;
  flex-direction: column;
}

.csv-actions {
  display: flex;
  align-items: center;
}

.csv-actions .searchBox {
  max-width: 268px;

  height: 2.5rem;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
  margin-top: 0;
}

.csv-actions .svgWrapper {
  position: absolute;
  top: 3px;
  left: 15px;
}

.csv-actions .svgWrapper svg {
  width: 15px;
  height: 15px;
}

.csv-actions .search-avatar-library {
  margin: 0;
}

.csvPop-detail-popup-video .csv-popup-heading,
.csvPop-detail-popup-video .modal-avatar-select,
.csvPop-detail-popup-video .csv-popup-footer {
  padding: 2.5rem;
}

.popup-toolbar-camp-create {
  max-width: 307px;
  padding: 20px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.14px;
  text-align: left;
  color: #e6e6e6;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #333333;
  word-wrap: break-word;
}

.overlay-campaign-detail-container {
  position: relative;
}

.overlay-campaign-detail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.6q);
}

.overlay-campaign-detail {
  display: none;
}

.overlay-campaign-detail-container:hover .overlay-campaign-detail {
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.csv-overlay-button {
  padding: 3.4px 20.8px 3.6px 20px;
  border-radius: 25px;
  background-image: linear-gradient(75deg, #e72870 1%, #e76161 97%);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.csv-overlay-button svg {
  margin-left: 10px;
}

.csvPop-detail-popup-video .vd-modal-content {
  max-height: 87vh;
  height: auto;
  background-color: #333333;
}

/* tags Input */
.tags-input-container {
  margin: 10px 0 0;
  padding: 8px 1rem 10px 1rem;
  min-height: 119px;

  border-radius: 5px;
  border: dashed 1px #333333;
  background-color: #222222;
  transition: 0.3s;
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.tag-input-00o9-label {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.tags-input-container:hover {
  background-color: #242424;
}

.tags-input-container:focus-within {
  border: solid 1px #4d4d4d;
  background-color: #333333;
}

.tags-input input {
  /* display: none; */
  flex: 1;
  border: none;
  height: 1.9rem;
  font-size: 14px;
  background: none;
  margin: 14px 10px 14px 0;
  padding: 4px 4px 4px 1rem;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  width: 22.5rem;
}

.tags-input input:focus {
  outline: transparent;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.9rem;
  margin: 1rem 10px 14px 0;
  padding: 4px 4px 6px 1rem;
  border-radius: 15px;
  border: solid 1px #4d4d4d;
  background-color: #333333;
}

.tag .tag-title {
  margin-top: 3px;
}

.tag .tag-close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  margin: 0 0 0 13px;

  border: solid 1px #4d4d4d;
  background-color: #333333;

  border-radius: 50%;
  color: #e6e6e6;
  cursor: pointer;
}

/* 
				.tags-input-container:focus-within > .tags-input input { 
					display: flex;
					flex: 1;
				} */

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}

.tick-in-csv-popup {
  margin: 0 15px;
  float: right;
  display: flex;
  align-items: center;
}

.generatingbtn {
  width: 127px;
  height: 2.5rem;

  padding: 10.1px 10.5px 9.9px 10.5px;
  border-radius: 25px;
  background-color: #a9a9a9;

  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.generatingbtn img {
  height: 15px;
  margin-left: 10px;
}

.list-notabs-list {
  display: flex;
  justify-content: space-between;
}

.list-notabs-list .search-filter-container {
  margin-bottom: 0;
}

.list-notabs-list .breadcrumb-custom {
  margin: 0;
}

.campaign-create-wrapper .custom-list-tab-container {
  padding: 0;
  margin-bottom: 2.5rem;
}

.campaign-create-wrapper {
  padding-top: 0;
}

.campaign-create-wrapper .video-list-main-container {
  margin-top: 1.9rem;
}

.campaign-create-wrapper .search-filter-container {
  margin-bottom: 2.5remss;
}

.sales-icon-incampcreate circle {
  fill: #a9a9a9;
}

.tabs-camp-det-tabs .react-tabs__tab {
  border: solid 1px #333333 !important;
  border-bottom: 0px;
}

.tabs-camp-det-tabs .react-tabs__tab-list {
  border-bottom: solid 1px #333333 !important;
  display: flex;
}

.tabs-camp-det-tabs .react-tabs__tab--selected {
  background: #242424 !important;
  border: none !important;
  outline: none !important;
  border-top: 4px solid !important;
  border-image: linear-gradient(92deg, #ea1f72 1%, #d86666) !important;
  border-image-slice: 1 !important;
  box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, 0.2);
}

.inCamp.camp-detail-popup-video .vd-modal-header {
  padding: 2.5rem 2.5rem 1.9rem 2.5rem;
}

.my-popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  padding: 5px;
}

.my-popup-arrow {
  color: rgb(255, 255, 255);
}

[role="tooltip"].my-popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.my-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.popup-arrow {
  fill: #333333;
  color: #333333;
}

.popup-camp-det-action .next-camp-12-disable {
  background-image: none;
  background-color: #a9a9a9;
  pointer-events: none;
}

.button-custom-disable {
  background-image: none !important;
  background-color: #a9a9a9;
  pointer-events: none;
}

.main-container {
  display: flex;
  /* width: 100vw; */
  height: 100vh;
  background-color: #333333;
}

.pro-sidebar {
  color: #adadad;
  height: 100%;
  width: 100%;
  min-width: 300px !important;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009;
  flex-grow: 1;
}

.pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px !important;
  flex-grow: 1;
}

.pro-sidebar-layout {
  background-color: #333333;
}

.pro-item-content {
  color: #e6e6e6;

  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.sidebar-menu {}

.selected-pro-item-content .pro-item-content {
  color: #e7416a;
  font-weight: 600;
}

.pro-sidebar .pro-menu .pro-menu-item.selected-pro-item-content>.pro-inner-item:hover {}

.selected-pro-item-content {
  border-left: 5px solid;
  border-image-slice: 1;
  border-width: 4px;
  border-image-source: linear-gradient(to bottom, #ea1f72, #d86666);
  /* margin-left: -4px; */
  background-color: #242424;
}

.side-down-ac .selected-pro-item-content,
.side-down-sub .selected-pro-item-content {
  border-left: none;
  background-color: #333333;
}

.side-down-ac .pro-menu-item.selected-pro-item-content .pro-inner-item,
.side-down-sub .pro-menu-item.selected-pro-item-content .pro-inner-item {
  margin-left: 0px;
  padding: 3px 7px 3px 16px !important;
}

.pro-menu-item.selected-pro-item-content .pro-inner-item {
  margin-left: 0px;
  padding: 3px 24px 3px 10px !important;
}

.pro-sidebar.collapsed .pro-menu-item.selected-pro-item-content .pro-inner-item {
  margin-left: 0px;
  padding: 3px 24px 3px 14px !important;
}

.navbar-user {
  background-color: #333333;
  color: #e6e6e6;
  padding: 0px !important;
  height: 50px;
  display: flex;
  z-index: 99999;
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content-container {
  background-color: #222222;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
  overflow-y: scroll;
  position: relative;
}

.content-container.main {
  display: flex;
  flex-grow: 1;
}

.pro-sidebar.collapsed .selected-pro-item-content {
  border: none;
}

.main-wrapper {
  position: fixed;
  width: 100%;
}

.add-thumbnail-wrapper {
  width: 190px;
  height: 110px;
  margin: 9px 26px 17px 0px;
  padding: 13.2px 14.4px 14.3px 14.4px;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
}

.add-thumbnail-container {
  width: 161.2px;
  height: 82.5px;
  padding: 29.2px 68.5px 29.6px 69.1px;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
}

.thumbnail-preview-popup {
  width: 190px;
  height: 110px;

  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #ffffff;
  cursor: pointer;
}

.selectedThumbnailoutline {
  border: 3px solid #e72870;
}

.preview-popup-thumbnail-p {
  width: 498px;
  height: 303px;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #ffffff;
}

.arrow--right.thumbnail-arrow {
  width: 80px;
  height: 163px;
  right: 0px;
  top: 55%;
}

.arrow--left.thumbnail-arrow {}

.word-btw-line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #4d4d4d;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.settingMainContainer .word-btw-line {
  margin: 27px 0;
}

.word-btw-line span {
  background: #333333;
  padding: 0 10px;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;

  letter-spacing: normal;

  color: #e6e6e6;
}

.modal-lable-sm-thumbnail {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin-bottom: 18px;
}

.selecting-thumbnail-preview {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.col-md-5-thumb {
  width: 48.4%;
}

.col-md-7-thumb {
  width: 51.6%;
  padding-right: 20px !important;
}

.thumbnail-avatar-vd {
  margin: 20px 0 0 1.9rem;
  width: 98%;
  height: 70%;
  max-width: 498px;
  max-height: 303px;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #ffffff;
}

.thumbnail-preview-image {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 9px 26px 17px 18px;
}

.thumbnail-preview-image:first-child {
  margin: 5px 26px 17px 0;
  width: fit-content;
}

.thumbnail-preview-image label {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  margin: 8px 0 0 0;
}

.select-preview-name-thumbnail input {
  width: 190px;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 20px;
  border: solid 1px #333333;
  background-color: #242424;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.burger-button {
  background: none;
  outline: none;
  margin: 0px 7px;
}

.fileUpload-dragdrop {
  background: #000;
  width: 50vw;
}

.template-wrapper {
  background-color: #222222;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  color: #e6e6e6;
  display: flex;
  flex-flow: column;
}

.template-header {
  display: flex;
  justify-content: space-between;

  margin: 0 60px;
}

.templategp-1 {
  border-bottom: solid 1px #333333;
}

.template-item {
  border: solid 1px #333333;
  border-radius: 5px;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  position: relative;
}

.heading-in-preview-box-thumbnail {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.p-in-preview-box-thumbnail {
  font-family: "Poppins";
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.template-header-heading {
  display: flex;
  flex-direction: column;
}

.template-header {
  padding: 40px;
}

.templategp-2 {
  padding: 40px;
  /* flex: 1; */
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 0;
}

.sm-template-subheading {
  margin: 0;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.lg-template-heading {
  margin-bottom: 6px;
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.template-search {
  display: flex;
}

.overlay-template {
  position: absolute;
  width: 100%;

  height: 84%;
  border-radius: 5px;
  border: solid 1px #242424;
  background-color: rgba(34, 34, 34, 0.8);
  z-index: auto;
  top: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.template-item:hover .overlay-template {
  display: flex;
}

.edit-template {
  width: 170.7px;
  height: 35px;
  margin: 0 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-image: linear-gradient(78deg, #e72870 1%, #e76161);

  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.edit-template svg {
  margin: 0 3px;
  width: 15px;
  height: 15px;
}

.preview-template-btn {
  width: 170.7px;
  height: 35px;
  padding: 0 16px 0 16px;
  border-radius: 25px;
  border: solid 1px #4d4d4d;
  background-color: #333333;
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.template-item-img {
  width: 100%;
  height: 100%;
  /* min-height: 176px; */
  max-height: 188px;
}

.template-item-container {
  /* margin: 0 60px;
	flex: 1; */
}

.template-name {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.7px;
  text-align: left;
  color: #e6e6e6;
  margin: 20px 0;
}

.template-item-footer {
  padding: 15px 15px;
  border: solid 1px #333333;
  background-color: #242424;
}

.pro-sidebar .pro-menu a:before {
  content: none !important;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout ul {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.pro-sidebar .pro-menu,
.container-sidebar-inner {
  height: 100%;
  padding-top: 0;
}

.pro-sidebar .pro-menu {
  padding: 0 !important;
}

.side-down {
  margin: 0;
}

.side-down-sub,
.side-down-ac {
  border-top: 1px solid #4d4d4d;
  padding: 20px 22px;
  margin: 0 16px;
}

.sidebar-menu.subs-selected {
  background-color: #333333;
}

.sidebar-menu.subs-selected .pro-item-content {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e7416a;
}

.sidebar-menu.subs .pro-inner-item {
  padding: 19px 0px !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
  transition: background-color 0.3s ease;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover {
  background-color: #4d4d4d;
  color: inherit;
}

.icon-m {
  margin: 14px;
}

.in-create-campaign-search {
  margin-bottom: 0;
}

.avatarsetting .vd-modal-header {
  padding: 2.5rem 2.5rem 1.9rem 2.5rem;
}

.avatarsetting .modal-avatar-select {
  padding: 0 2.5rem 30px 2.5rem !important;
}

.avatarsetting .vd-modal-footer {
  padding: 1rem 2.5rem;
}

.avatarsetting .vd-modal-content {
  height: auto;
}

.arrowinthumbnail {
  /* border: 1px solid rgb(224, 212, 212); */
  height: 87%;
  width: 60px;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail-arrow {
  position: absolute;
  z-index: 99;
}

.left-thumbnail {
  top: 0;
  left: 0;
}

.right-thumbnail {
  top: 0;
  right: 0;
}

.thumbnail-slider .slick-slide {
  width: 190px !important;
  margin-left: 20px;
}

.thumbnail-slider .slick-slide:first-child {
  margin-left: 0px;
}

.thumbnailsetting .vd-modal-content {
  height: 81vh;
  max-height: 626px !important;
}

.loading-main {
  width: 100%;
  height: 100%;
  background-color: #222222;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 117;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel-thumbnail-popuo {
  width: 130px;
  height: 40px;
  border-radius: 25px;
  background-color: #4d4d4d;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail-popup-footer {
  display: flex;
}

.savethumbnail-popuo {
  width: 130px;
  height: 40px;
  margin: 0px 0 0px 20px;

  border-radius: 25px;
  background-image: linear-gradient(74deg, #e72870 -4%, #e76161);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-container img {
  width: 50px;
  height: 50px;
}

.loading-name {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e7416a;
  margin-top: 20px;
}

.loading-sm-component {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.onGoingCamp-container .table-campaign-hd:nth-child(6) {
  max-width: 118px;
}

.ongoing-Camp .table-campaign-hd {
  margin: 0 31 px;
}

.ongoing-Camp .table-campaign-hd.table-campaign-names {
  margin: 0 1.25rem 0 0;
}

.main-container-2 .detail-main-container {
  width: 65.6%;
  /* background-color: #333333; */
  /* max-width: 900px; */
  /* display: inline-block; */
}

.main-container-2 .detail-sidebar-container {
  width: 43.4%;
  margin: 0 0 0 30px;
  /* display: inline-block; */
}

.main-container-2.preview-salespage-image {
  max-width: none;
}

.menu.pagination-csv-menuelist {
  width: 129px;
  /* height: 210px; */
  padding: 0px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 16%);
  background-color: #242424;
  top: -258px;
}

.pagination-csv-menuelist.prospect-menue-list ul {
  min-width: auto;
}

.menu.pagination-csv-menuelist li {
  padding: 12px 20px;
}

.menu.pagination-csv-menuelist li:hover {
  background-color: #333333;
}

.error-info-tooltip-tool {
  position: absolute;
  padding: 20px;

  box-shadow: 0 0 10px 0 #222222;
  background-color: rgba(51, 51, 51, 0.6);
  right: -79%;
  top: -21%;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
}

.error-info-tooltip-head {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.error-info-item {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e7416a;
}

.salespage-select-popup {
  /* width: 80vw; */
  height: 95vh;
  max-width: 1518px;
  max-height: 873px;
  border-radius: 5px;
  border: solid 1px #4d4d4d;
  background-color: #333333;
  z-index: 99999;
  display: flex;
  flex-flow: column wrap;
}

.salespage-select-popup.logo {
  width: 65vw;
  height: 87vh;
  max-height: 873px;
}

.logo .salespage-popup-body {
  padding: 0 6px 0 40px;
  margin: 0 0 0 0;
}

.logo .salespage-popup-body {
  padding: 0 6px 0 40px;
  margin: 0 0 0 0;
}

.salespage-popup-header {
  padding: 30px 40px 20px 40px;
}

.salespage-header-gp1 {
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px 0;
}

.salespage-header-gp2 {
  margin: 0 0 0px 0;
  height: 110px;
}

.header-sales-popup {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.salespage-popup-footer {
  padding-left: 40px;
  padding-right: 40px;
  height: 71px;
  border-top: solid 1px #4d4d4d;
  z-index: 9;
  background-color: #333333;
}

.salespage-popup-body {
  overflow: hidden;
  height: inherit;
  flex: 1;
  /* height: 34%;
    flex: auto; */
  padding: 0 0px 0 40px;
  margin: 0 -30px 0 -40px;
}

.gp2-sales-popup {
  display: flex;
  align-items: center;
}

.tablist-sales-popup {
  display: flex;
  list-style: none;
  margin: 20px 35px 30px 19px;
  padding: 0;
  border-bottom: solid 1px #4d4d4d;
}

.sales-logo-popup {
  padding: 0 40px 0 0;
  overflow: auto;
  height: 100%;
}

.menu-overlay,
.menu-thumbail-overlay {
  box-shadow: none;
  background: none;
  /* display: none !important; */
}

.menu-thumbail-content {
  z-index: 9999999999 !important;
}

.menu-thumbail-content .menu-vd-list-item {
  width: 91px;
  padding: 5px 16px;
  /* justify-content: space-between; */
}

.tab-sales-popup {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  padding: 12px 16px;
  cursor: pointer;
}

.tabs-sales-popup {
  /* height: 88%; */
  overflow: hidden;
  /* display: flex; */
  /* flex-flow: row wrap; */
  height: 100%;
}

.tabpanel-sales-popup.react-tabs__tab-panel--selected {
  padding: 10px 26px 0 18px;
  margin-top: 15px;
  overflow-y: scroll;
  flex: 1 1;
  max-height: 100%;
  /* height: 100%; */
  height: calc(100% - 47px);

  margin: 0 -22px 0 0;
  /* padding: 0 10px 0 0; */
}

.tab-sales-popup.react-tabs__tab--selected {
  border: none !important;
  outline: none !important;
  border-bottom: 4px solid !important;
  border-image: linear-gradient(92deg, #ea1f72 1%, #d86666) !important;
  border-image-slice: 1 !important;
  box-shadow: none;
}

.salespopup-list-container {
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  row-gap: 1.3rem;

  column-gap: 1.3rem;
  height: 100%;
  position: relative;
  /* overflow: scroll; */
  /* margin: 0; */
}

.sales-myupload-empty {
  /* width: 966px;
	height: 475px; */

  width: 100%;
  border-radius: 5px;
  border: dashed 1px #e7416a;
  background-color: #333333;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* margin-top: 30px; */
  padding: 50px 50px;
}

.myupload-empty {
  width: 100%;
  height: auto;
  padding: 61px 0;

  border-radius: 5px;
  border: dashed 1px #e7416a;
  background-color: #242424;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 0px;
}

.myupload-empty-dashboard {
  width: 100%;
  height: auto;
  padding: 85px 0;
  border-radius: 5px;
  /* border: solid 1px #333333; */
  /* background-color: #242424; */
  /* cursor: pointer; */
  display: flex;
  align-items: center;
  /* margin-top: 0px; */
  /* margin: 0px 0 1.9rem 0px; */
}

.myupload-empty-dashboard-popup {
  width: 100%;
  height: auto;
  padding: 128px 0;

  border-radius: 5px;
  border: solid 1px #333333;
  background-color: transparent;
  /* cursor: pointer; */
  display: flex;
  align-items: center;
  /* margin-top: 0px; */
  /* margin: 0px 0 1.9rem 0px; */
}

.list-in-dashboard-prospect {
  border-bottom: solid 1px #333333;
}

.Prospect-items:nth-last-child(2) .list-in-dashboard-prospect {
  border-bottom: none;
}

.upload-empty1 {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  margin-top: 30px;
}

.upload-empty2 {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #a9a9a9;
  margin: 10px 0 30px 0;
}

.upload-empty3 {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e7416a;
}

.active-upload-salespage-empty {
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.empty-sales-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 100%;
  height: 100%;
  /* padding: 50px 0; */
  flex-direction: column;
  width: 100%;
}

.video-salespopup {
  width: 100%;
  height: 155px;
}

.video-salespopup video {
  display: block;
  width: 100%;
  height: inherit !important;
  object-fit: fill !important;
}

.add-to-page-salespage-popup {
  width: 130px;
  height: 40px;

  border-radius: 25px;
  background-image: linear-gradient(74deg, #e72870 -4%, #e76161);

  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #dcf5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel-salespage-popup {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #dcf5f5;
  width: 130px;
  height: 40px;
  margin: 0 20px 0 0;
  padding: 11.1px 42.2px 11.9px 41.8px;
  border-radius: 25px;
  background-color: #4d4d4d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-salespopup-contaner {
  display: flex;
  width: 100%;
  padding: 15px 0px;
  justify-content: flex-end;
}

.image-of-templates {
  margin: 0px 0 0 0;
}

.Line-164 {
  width: 0;
  height: 40px;
  margin: 5px 0;
  border: solid 1px #4d4d4d;
}

.save-tooltip {
  position: relative;
}

#savetry66 {
  display: none;
  width: 96px;
  padding: 10px 18px 10px 18px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #333333;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 72%;
  margin-left: -60px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

#savetry77 {
  width: 96px;
  padding: 10px 18px 10px 18px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #333333;
  position: absolute;
  z-index: 1;
  top: 69px;
  left: 31px;
  margin-left: -60px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

#savetry77::after {
  content: "";
  position: absolute;
  top: -44%;
  left: 46%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: #333333 transparent transparent transparent;
  transform: rotate(179deg);
}

#savetry66::after {
  content: "";
  position: absolute;
  top: -44%;
  left: 46%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: #333333 transparent transparent transparent;
  transform: rotate(179deg);
}

.popup-toolbar-video-tool {
  /* width: 96px; */
  padding: 10px 18px 10px 18px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #333333;
  position: absolute;
  z-index: 1;
  top: 160%;
  left: 72%;
  margin-left: -60px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.popup-toolbar-video-tool::after {
  content: "";
  position: absolute;
  top: -44%;
  left: 46%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: #333333 transparent transparent transparent;
  transform: rotate(179deg);
}

.music-choose-label {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin: 0 0 15px 0;
}

.music-continue-selected-container {}

.music-continue-selected-container .bgMusic-single-container {
  flex-direction: row;
  width: fit-content;
}

.music-continue-selected-container .bgMusic-vd-setting {
  min-width: 106px;
}

.music-continue-selected-container .my-uplaod-lable-music {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 0 20px;
}

.bgImgAvatarContainer.diffrent-bdcontainer {
  overflow-y: scroll;
  height: 74vh;
  margin: 0 -40px;
  padding: 0 35px;
}

.bg-inside {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  row-gap: 11px;

  column-gap: 11px;

  margin: 0 -35px;
  padding: 0 35px;
  grid-auto-rows: 145px;
}

.dragandDrop-container {
  width: 100%;
  height: 110px;
  margin: 0 0 40px 0;
}

.dragandDrop-1st-bx {
  padding: 14px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: solid 1px #333333;
  background-color: #242424;
}

.dragandDrop-2st-bx {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: dashed 1px #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dragandDrop-2st-bx .dragIcon {
  margin-bottom: 10px;
}

.dragandDrop-2st-bx label {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #a9a9a9;
  margin-bottom: 0;
}

.selected-in-tabs-component {
  display: flex;
  border: solid 1px #4d4d4d;
  border-radius: 5px;
  margin: 0 0 21px 0;
}

.selected-in-video-tabs {
  width: 171px;
  height: 100px;
  margin: 0 30px 0 0;
  border-radius: 5px;
  border: solid 1px #333333;
}

.name-of-file,
.name-of-filetype {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.currentlyselected-v {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.name-of-filetype {
  color: #e7416a;
}

.info-select-in-video-tabs {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.searchstickyintabs {
  position: sticky;
  top: 0;
  z-index: 7;
  background: #333333;
  width: 103%;
  padding: 10px 0px 20px 9px;
  margin: 0 0 0 -9px !important;
}

.searchstickyintabs .seventy {
  width: 73%;
}

.searchstickyintabs .thirty {
  width: 27%;
}

.setting-vd:has(.avatar-view-experimantal) {
  border: 10px solid #000;
}

.progress-bar-save-custom {
  width: 24px !important;
  height: 24px;
}

.progress-bar-save-custom svg {
  width: 24px;
  height: 24px;
}

.progressbar-tick {
  width: 11px;
  height: 7px;
  position: absolute;
  top: 11px;
  left: 7px;
}

.tick-on-tabs {
  position: absolute;
  background-color: #e72c70;
  width: 32px;
  height: 32px;
  clip-path: polygon(0 1%, 0% 100%, 99% 0);
  top: -1px;
  left: 0;
  /* top: 0px;
    left: 5px; */
  display: none;
  z-index: 50;
}

.tick-on-tabs.select {
  top: 0px;
  left: 0px;
}

.tick-on-tabs.showticktabs {
  display: block;
}

.tickmark-tabs .react-tabs__tab {
  position: relative;
}

.tickmark-tabs .react-tabs__tab .tick-on-tabs {
  background-color: #4d4d4d;
}

.tickmark-tabs .react-tabs__tab.react-tabs__tab--selected .tick-on-tabs {
  background-color: #e72c70;
}

.tickImage-tabs {
  position: absolute;
  top: 18%;
  left: 9%;
}

.template-select-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 316px;
  max-height: 190px;
}

.template-select-label {
  width: 100%;
  text-align: center;
  padding: 0 16px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #e6e6e6;
  margin-top: 6px;
}

.template-select-label-extra {
  width: 100%;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 300;

  color: #e6e6e6;
  display: flex;
  align-items: center;
}

.color-template-container {
  width: 100%;
  padding: 20px 40px;
  margin: 0 -40px;
  box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: #333333;
  position: absolute;
  /* opacity: 0; */
  transition: all 0.7s;
  overflow: hidden;
  z-index: 1;
  height: 80px;
  top: 92%;
}

.color-template-container.open {
  height: 100%;
  top: 0%;
}

.color-template-header {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.colorbox-colortheme {
  display: flex;
}

.colorthemelabel {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  margin: 0 20px;
  display: flex;
  align-items: center;
}

.up-to-down {
  transform: rotate(180deg);
}

.colorbox-selecteable {
  width: 96px;
  height: 40px;
  border-radius: 5px;
  border: solid 1px #333333;
  position: relative;
}

.color-template-footer {
  width: 100%;
  margin: 0 -41px;
  height: 80px;
  padding: 20px 41.1px 20px 37px;
  box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: #333333;
  bottom: 0;
}

.color-template-footer {
  display: flex;
  justify-content: flex-end;
}

.footer-default-btn {
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  background-color: #4d4d4d;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  font-family: "Poppins";
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.footer-color-template {
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: #4d4d4d;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  font-family: "Poppins";
}

.cancel-btn.footer-color-template {
  background-color: #4d4d4d;
  margin: 0 20px;
}

.save-btn.footer-color-template {
  background-image: linear-gradient(72deg, #e72870 -4%, #e76161);
}

.Avatar-videoplayer {
  /* width: 610px;
	height: 370px; */
  width: 802px;
  height: 451px;
}

.colorbox-selected {
  border: solid 5px #e7416a;
}

.colorbox-selected::after {
  top: 117%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(213, 28, 77, 0);
  border-top-color: #e7416a;
  border-width: 8px;
  margin-left: -8px;
}

.overlay-videocreate-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 120px;
}

.overlay-videocreate {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 150;
}

.overlay-videocreate-container:hover .overlay-videocreate,
.selcted-bgImgAvatar .overlay-videocreate {
  display: block;
}

.image-container-myupload:hover .overlay-videocreate {
  display: block;
}

.progress-bar-uploading {
  width: 35px !important;
  height: 35px;
}

.progress-bar-uploading svg {
  width: 35px;
  height: 35px;
}

.selected-in-video-tabs.uploading {
  display: flex !important;

  background-color: #4d4d4d;
}

.input-mergetgcontainer {
  min-width: 130px;
  max-width: 130px;
  height: 40px;
  border-radius: 20px;
  border: solid 1px #333333;
  background-color: #242424;
  position: relative;
  padding: 5px 16px;
}

.input-mergetg-input {
  background-color: #242424;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  border: none;
  outline: none;
  width: 88%;
  border-radius: 20px;
  max-width: 140px;
}

.input-mergetg-button {
  position: absolute;
  width: 30px;
  height: 30px;

  color: #e6e6e6;

  background-image: linear-gradient(to bottom, #e72870, #e76161);
  border-radius: 50%;
  right: 5px;
}

.tint-feed {
  height: 94%;
  width: 39%;
  position: fixed;
  top: 6.7vh;
  left: 0;
  z-index: -9;
}

.tint-feed-salespage {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -9;
}

.tint-feed-csv {
  position: absolute;
  top: 49px;
  right: 0;
  z-index: -9;
  background-color: rgba(231, 65, 106, 0.4);
}

.tint-feed.popup {
  position: absolute;

  top: 0;
  left: 0;

  z-index: -9;
}

.tintfeed-true {
  z-index: 150 !important;
  background-color: rgba(231, 65, 106, 0.4);
}

.template-color-salespage {
  display: flex;
}

.color-box-sales {
  margin-right: 10px;
}

.color-box-sales:first-child {
  margin-left: 10px;
}

.spinner-invideo {
  width: 171px;
  height: 100px;
  margin: 0 30px 0 0;
  border-radius: 5px;
  border: solid 1px #333333;
  /* padding: 22% 48%; */
  background-color: #4d4d4d;
}

.spinner-invideo-k {
  width: 232px;
  height: 130px;
  margin: 20px 30px 0 0;
  border-radius: 5px;
  border: solid 1px #333333;
  /* padding: 22% 48%; */
  background-color: #4d4d4d;
}

.spinner-invideo-list {
  width: 100%;
  height: 100%;
  max-height: 120px;

  border-radius: 5px;
  border: solid 1px #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4d4d4d;
  padding: 22% 48%;
}

.spinner-invideo img {
  width: 16px;
  height: 15.5px;
  margin: 22% 0 0 44%;
}

.spinner-invideo-k img {
  width: 16px;
  height: 15.5px;
  margin: 22% 0 0 44%;
}

.spinner-invideo-list img {
  width: 16px;
  height: 15.5px;
  margin: 0;
}

.twoPiece-select-new-cont {
  display: grid;
  grid-template-columns: auto 1fr;
  /* height: 80%; */
  overflow-y: hidden;
  flex: 1;
}

.selectable-items-cont {
  max-width: 1000px;
  width: 63vw;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-flow: column wrap;
  padding: 0 40px 0 25px;
}

.dragable-item-container {
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  margin: 0 0 0 0px;
  border-left: solid 2px #4d4d4d;
  height: 100%;
}

.drag-container {}

.drag-item-container {
  width: 100%;
  height: 100px;
  margin: 20px 0;
  padding: 0 20px 0 0;
  border-radius: 5px;
  border: solid 1px #4d4d4d;
  background-color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999999999999999;
}

.thumbnail-lable-input-salespopup {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e7416a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 188px;
}

.remove-dragitem {
  font-family: "Poppins";
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #dcf5f5;
  width: 87px;
  height: 25px;
  margin: 0;
  padding: 0;
  border-radius: 25px;
  background-color: #4d4d4d;
}

.Some-Long-Document-salespopup {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 163px;
}

.link-active-icon-sm path {
  fill: #e6e6e6;
}

.error-head-con {
  display: flex;
  flex-direction: column;
  margin: 0 0 20px 0;
}

.error-head-type-white {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  display: flex;
  align-items: center;
}

.error-war-type-pink {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e7416a;
}

.error-type-container {
  display: flex;
  align-items: center;
}

.block-csv {
  width: 70px;
  height: 30px;
  margin: 0;
  padding: 5px 25px;
  border-radius: 5px;
  background-color: #333333;
}

.Icon-material-error {
  width: 20px;
  height: 20px;
  margin: 010px;
  background-color: #e7416a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.Icon-material-error.multiple {}

.error-type-lable-white {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.error-head-item-1 {
  margin-top: 10px;
}

.anitry {
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  position: absolute;
}

.wrapperr {
  position: relative;
}

.MoveLeft {
  animation: slideIn 0.8s forwards;
}

.MoveRight {
  animation: slideOut 2s;
}

@keyframes moveLeft {
  0% {
    opacity: 0;
    transform: translateX(-100vw);
  }

  100% {
    opacity: 100%;
    transform: translateX(0);
  }
}

/* .slform{
	animation: moveLeft 2s forwards ;
} */
@keyframes moveRight {
  from {
    opacity: 100%;
    transform: translateX(0);
  }

  to {
    opacity: 0%;
    transform: translateX(100vw);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(200%);
    /* // opacity:0; */
  }

  100% {
    /* // opacity:1; */
    transform: translateX(0%);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(-200%);
    /* // opacity:0; */
  }

  100% {
    /* // opacity:1; */
    transform: translateX(0%);
  }
}

.Empty-colatral-slider {
  width: 100%;
  margin: auto;
  max-width: 1284px;
  height: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Empty-colatral-slider-l1 {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e7416a;
}

.Empty-colatral-slider-l2 {
  font-family: "Poppins";

  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #4d4d4d;
}

.login-new-plai {
  width: 60vw;
}

.login-header {
  font-family: "Poppins";
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  margin: 80px 0;
}

.google-login {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;

  background-color: #242424;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  border: solid 2px #333333 !important;
  margin: 0 0 40px 0;
}

.word-btw-line.login span {
  background: #333333;
  padding: 0 10px;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #e6e6e6;
}

.login-gp-1 {
  width: 100%;
}

.login-inner-wrapper {
  width: 100%;
}

.word-btw-line.login span {
  background: #242424;
  padding: 0 10px;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #e6e6e6;
}

.login-item {
  width: 100%;
  height: 50px;
  margin: 0 0px 30px;
  padding: 0 16px;
  border-radius: 28px;
  border: solid 2px #333333;
  background-color: #242424;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.login-item::placeholder {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
}

.login-form {
  margin: 40px 0 0 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.login-main-button {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  width: 100%;
  height: 50px;
  margin: 10px 0 40px 0;
  padding: 0 16px;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(83deg, #e72870 -4%, #e76161 100%);
}

.login-main-label {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #a9a9a9;
  margin-bottom: 20px;
}

.login-main-label .link-custom {
  font-family: "Poppins";

  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.link-custom.forget-login-main {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6 !important;
}

/* .link-custom.forget-login-main:hover {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6 !important;
} */

.login-footer {
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorInput {
  box-shadow: 0 0 10px 0 rgba(231, 65, 106, 0.3);
  border: solid 2px #e7416a;
}

.login-verification-label {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  margin: 0 0 80px 0;
}

.login-gp-1 {
  text-align: center;
}

.error-login-info {
  width: 100%;
  height: 90px;
  margin: 0px 0 30px 0px;
  padding: 16px;
  /* opacity: 0.6; */
  border-radius: 28px;
  background-image: linear-gradient(78deg,
      rgba(231, 40, 112, 0.6) -4%,
      rgba(231, 97, 97, 0.6));
  position: relative;
  display: flex;
  flex-direction: column;
}

.error-login-info-heading {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.error-login-info-label {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.heading-e6e6e6 {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.sub-a9a9a9 {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #a9a9a9;
}

.popup-content.tooltip-bg-content {
  top: 114px !important;
  left: 492.344px !important;
}

.manual-alignment {
  position: relative;
  display: inline-block;
}

.manual-alignment .manual-alignmenttext:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #333333 transparent;
}

.popup-info:after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  /* To the left of the tooltip */
  margin-top: -8px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #333333 transparent transparent;
}

.popup-info.arrow242424:after {
  border-color: transparent #242424 transparent transparent;
}

.manual-alignment .manual-alignmenttext {
  visibility: hidden;
  min-width: 120px;
  width: max-content;
  padding: 10px;

  color: #fff;
  text-align: center;
  border-radius: 6px;

  position: absolute;
  z-index: 15;
  top: 112%;
  left: 34%;
  margin-left: -60px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  background-color: #333333;

  /* Fade in manual-alignment - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 0.2s;
}

.manual-alignment:hover .manual-alignmenttext {
  visibility: visible;
  opacity: 1;
}

.manual-alignment:focus-within .manual-alignmenttext {
  visibility: hidden;
  opacity: 0;
}

.signal-prospect-info-wrapper {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0;
}

.signal-prospect-info-wrapper .slick-slide {
  width: 173px !important;
  margin: 0 5px;
}

.signal-prospect-info-con {
  margin: 0 0px;
  display: flex;
  border: solid 1px #4d4d4d;
  background-color: #333333;
  padding: 10px 16px 10px 0;
  width: 173px;
  height: 57px;
}

.detail-main-container .signal-prospect-info-con {
  border: solid 1px #333333;
  background-color: #242424;
}

.signal-prospect-info-ico {
  padding: 6px 16px 6px 16px;
}

.signal-prospect-info-lable-date {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.signal-prospect-info-lable {
  font-family: "Poppins";
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.signal-prospect-info-date {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.track-ac {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  margin: 0 0 10px 0;
}

input[type="search"]::-webkit-search-cancel-button {
  position: relative;
  right: 1px;

  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background: url("searchcross.svg") no-repeat 50% 50%;
  cursor: pointer;
}

.preview-mobile-frame-in {
  position: absolute !important;
  /* 
    left: 10%; */
  /* position: absolute; */
  /* left: 50%; */
  /* margin-left: -50px; */
  /* top: 52px; */
  /* top: 50%; */
  margin-top: -50px;
  /* width: calc(100% - 105px); */

  /* width: 676px; */
  /* min-width: 355px;
	width: 18.5%;
    margin: auto;
    position: absolute !important;
    top: 5%;
    left: 36.9%; */
  /* left: 38%; */
  /*
    overflow-y: scroll;
    height: 81.6vh !important;
	background-color: #222222; */
  border-radius: 42px 33px 35px 35px;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.preview-mobile-frame-in .text-editor {
  min-width: auto !important;
}

.preview-mobile-frame-in .video-thumbnail {
  min-height: auto !important;
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 50px 0px;
  /* min-height: none; */
  max-width: 782px;
  padding-top: 56.25%;
  position: relative;
}

.preview-mobile-frame-in .video-thumbnail-preview {
  min-height: auto !important;
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 50px 0px;
  /* min-height: none; */
  max-width: 782px;
  /* padding-top: 56.25%; */
  position: relative;
}

.preview-mobile-frame-in .video-thumbnail i {
  position: absolute;
  top: 36%;
  font-size: 40px;
}

.preview-container-wrapper .preview-page-container.preview-mobile-frame-in .sales-preview-editor::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
  display: none;
  /* Safari and Chrome */
}

.preview-container-wrapper .preview-page-container.preview-mobile-frame-in .text-editor {
  padding: 5px 0 5px 0 !important;
}

.preview-container-wrapper .preview-page-container.preview-mobile-frame-in .contact-icons svg {
  height: 1.875em;
  width: 1.875em;
  min-width: 24px;
  min-height: 24px;
}

.preview-container-wrapper .preview-page-container.preview-mobile-frame-in .mobile-view-carosel p,
.mobile-view-carosel a {
  /* width: 128px; */
  display: flex;
  align-items: center;
  font-family: "Poppins";
  font-size: 1.25em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.6px;
  text-align: left;
  padding: 6px 4px 6px 4px;
  overflow: visible;
  word-break: break-all !important;
  white-space: normal !important;
  justify-content: flex-start;
}

.preview-container-wrapper .preview-page-container.preview-mobile-frame-in .mobile-view-carosel img {
  height: 10.24em;
  min-width: 18.625em;
  max-width: 18.625em;
  width: 100%;
  margin-right: 2.5em;
}

.preview-container-wrapper .preview-page-container.preview-mobile-frame-in .mb-cus {
  padding: 0;
  padding-bottom: 4em;
  margin: 1em;
  margin-top: 4.5em;
  margin-bottom: 4.5em;
}

.preview-container-wrapper .preview-page-container.preview-mobile-frame-in .ai-sales-footer {
  height: auto;
}

.preview-mobile-frame-con {
  position: relative;
  /* overflow-y: scroll; */
  background-color: #4d4d4d;
  height: 100vh;
  font-size: 100%;
}

.preview-mobile-frame-main {
  /* position: absolute;
    width: 24.7%;
    min-width: 485px;
    left: 37%;
    z-index: 0;
    max-height: 93%; */
  width: 100%;
  height: 100%;
  max-height: 93vh;
}

.spinner-in-snapshot {}

.empty-search-wrapper {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-search-white {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  margin: 30px 0 20px 0;
}

.empty-search-off-white {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #a9a9a9;
}

.frame-preview-try {
  min-width: 485px;
  min-height: 93vh;
  max-width: 485px;
  margin: auto;
  position: relative;
}

.live-preview-moble-container {
  font-size: 100%;
}

.live-preview-moble-container .preview-page-container.preview-mobile-frame-in {
  position: static !important;
  margin-left: 0;
  position: relative;
  height: 100vh;
  overflow-y: hidden;

  border-radius: 0;
  margin-top: 0;
}

._title {
  display: flex;
  justify-content: center;
}

._title ._innerTitle {
  margin-bottom: 3em;
}

.signal-prospect-info-wrapper .slick-track {
  display: flex;
  margin-left: 0;
  margin-right: 0;
}

.manage-team-table {
  display: flex;
  flex-direction: column;
}

.manage-team-th,
.manage-team-tr {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.manage-team-tr {
  border-bottom: solid 1px #4d4d4d;
}

.manage-team-tr:last-child {
  border-bottom: none;
}

.manage-team-th {
  background-color: #242424;
}

.manage-team-thd {
  width: 150px;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  padding: 10px 0px;
  margin: 0 20px;
}

.manage-team-trd {
  padding: 20px 0px;
  margin: 0 20px;
  width: 150px;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
}

.manageteam-lable-subheading {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.manage-team-popup-wrapper-header {
  margin: 0 0 30px 0;
  padding: 0 0 30px 0;
  border-bottom: solid 1px #4d4d4d;
  display: flex;
  justify-content: space-between;
}

.manage-team-popup-wrapper-header .headingg {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.manage-team-popup-wrapper-header input {
  border-radius: 20px;
  border: solid 1px #333333;
  background-color: #242424;
  max-width: 632px;
  width: 100%;
  margin-right: 20px;
  padding: 8px 16px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
}

.manage-team-popup-wrapper-header .btn-custom-manage-team {
  width: 167px;
  height: 40px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;

  padding: 10px 16px 10px 16px;
  border-radius: 25px;
  background-image: linear-gradient(76deg, #e72870 1%, #e76161);
}

.manage-invitation-pending {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a9a9a9;
}

.menu-submenu-manageteam-container {
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #4d4d4d;
  background-color: #333333;
  padding: 15px;
  width: 257px;
  height: 115px;
}

.manage-button-content {
  display: flex;
  justify-content: center;
  /* margin-top: 20px; */
}

.cancel-content-remove-make {
  width: 92px;
  height: 30px;
  margin: 0 10px 0px 0;

  border-radius: 25px;
  background-color: #4d4d4d;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #dcf5f5;
}

.do-content-remove-make {
  width: 92px;
  height: 30px;
  border-radius: 25px;
  background-image: linear-gradient(73deg, #e72870 -4%, #e76161);
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #dcf5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-n-bx2-manage-team {
  width: auto;
  height: 2.5rem;
  padding: 0px 0.5rem;
  border-radius: 25px;
  background-image: linear-gradient(79deg, #e72870 1%, #e76161);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-submenu-manageteam-container label {
  margin-bottom: 20px;
}

.menu-submenu-manageteam-container div {
  /* display: flex;
justify-content: center; */
}

.PreviewInSalespageTemplate {
  position: relative;
  width: 25vw;
  height: 50vw;
  top: -8vh;
  margin: auto;
}

.preview-page-container.mobile ::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
  display: none;
  /* Safari and Chrome */
}

.PreviewInSalespageTemplate-content {
  /* width: 20vw;
	height: 30vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.PreviewInSalespageTemplate-image {
  /* position: fixed; */
  width: 100%;
  border-radius: 1.8vw;
  /* width: 15.7vw;
	margin: 5px 9px 0 0px;
    z-index: 99999999999999;
	border-radius: 42px 33px 35px 35px;
	top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
}

.PreviewInSalespageTemplate-image-div {
  /* position: fixed;
    width: 18.2vw;
    overflow-y: scroll;
    height: 79vh;
    margin: -5px 9px 0px -3px;
    z-index: 99999999999999;
    border-radius: 42px 33px 35px 35px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
  overflow-y: scroll;
  position: absolute;
  margin: 0%;
  top: 49.5%;
  left: 49%;
  transform: translate(-50%, -50%);
  width: 76%;
  height: 82%;
  /* background: yellow; */
  border-radius: 1.8vw;
}

.preview-container-wrapper .preview-page-container.mobile {
  position: relative;
  /* height: 94vh; */
  /* overflow-y: hidden; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 9vh;
}

.Timeline-menu .Cal__Header__dateWrapper.Cal__Header__day {
  height: 30px;
  font-size: 22px;
  line-height: 30px;
  text-transform: capitalize;
}

.Timeline-menu .Cal__Header__dateWrapper.Cal__Header__year {
  height: 17px;
  font-size: 13px;
  line-height: 15px;
}

.Timeline-menu .Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
  content: "";
  position: absolute;
  top: 55%;
  left: 64%;
  width: 40px;
  height: 40px;
  margin-top: -25px;
  margin-left: -26px;
  border-radius: 50%;
  line-height: 54px;
  z-index: 2;
  background-color: #e72870 !important;
  color: #e6e6e6 !important;
  background-image: linear-gradient(to bottom, #e72870, #e76161) !important;
}

.Timeline-menu .Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__day {
  position: relative;
  top: 2px;
  font-weight: bold;
  font-size: 14px;
}

.Timeline-menu .Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection .Cal__Day__day {
  top: 0px;
}

.Timeline-menu .Cal__Day__root {
  display: inline-block;
  box-sizing: border-box;
  width: 14.28571%;
  list-style: none;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.Timeline-menu .Cal__Day__range.Cal__Day__selected.Cal__Day__start:after,
.Timeline-menu .Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 50%;
  height: 40px;
  margin-top: -23px;
  box-shadow: inset 56px 56px;
}

.Timeline-menu .Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before,
.Timeline-menu .Cal__Day__root.Cal__Day__enabled:active:before,
.Timeline-menu .Cal__Day__root.Cal__Day__enabled:hover:before {
  content: "";
  position: absolute;
  top: 59%;
  left: 64%;
  width: 40px;
  height: 40px;
  margin-top: -25px;
  margin-left: -26px;
  border-radius: 50%;
  background-color: #efefef;
  z-index: -1;
}

.Timeline-menu .Cal__Day__root.Cal__Day__today:before {
  content: "";
  position: absolute;
  top: 59%;
  left: 64%;
  width: 40px;
  height: 40px;
  margin-top: -25px;
  margin-left: -26px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px;
  z-index: -1;
}

.Timeline-menu .Cal__Day__month,
.Timeline-menu .Cal__Day__year {
  position: absolute;
  left: 0;
  right: 0;
  font-size: 11px;
  line-height: 8px;
  text-transform: capitalize;
  background-image: none;
}

.Timeline-menu .Cal__Header__range .Cal__Header__wrapper:first-child:before,
.Timeline-menu .Cal__Header__range .Cal__Header__wrapper:first-child:after {
  content: "";
  position: absolute;
  top: 8%;
  left: 50%;
  width: 0;
  height: 0;
  margin-top: -52px;
  margin-left: -50px;
  border-top: 93px solid transparent;
  border-bottom: 100px solid transparent;
  border-left: 50px solid;
}

.calender-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: antiquewhite;
  top: 0;
  right: 120px;
}

.Timeline-menu .Cal__Header__root {
  background-color: #242424 !important;
  color: #e6e6e6 !important;
}

.Timeline-menu .Cal__Weekdays__root {
  background-color: #333333 !important;
  color: #e6e6e6 !important;
}

.Timeline-menu .Cal__Today__root.Cal__Today__show {
  background-color: #242424 !important;
  color: #e6e6e6 !important;
}

.Timeline-menu .Cal__Header__range .Cal__Header__wrapper:first-child:before {
  /* background-color: #242424 !important; */

  transform: translateX(1px);
}

.Timeline-menu .Cal__Header__range {
  color: #242424 !important;
}

.Timeline-menu .Cal__Day__range.Cal__Day__selected.Cal__Day__start .Cal__Day__selection {
  background-color: #e72870 !important;
  color: #e6e6e6 !important;
  background-image: linear-gradient(to bottom, #e72870, #e76161) !important;
}

.Timeline-menu .Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange .Cal__Day__selection {
  background-color: rgba(231, 65, 106, 0.5) !important;
  color: #e6e6e6 !important;
  background-image: none !important;
}

.Timeline-menu .Cal__Day__root.Cal__Day__selected {
  color: rgba(231, 65, 106, 0.5) !important;
}

.Timeline-menu .Cal__MonthList__root {
  width: 100% !important;
  background-color: #333333 !important;
  -webkit-overflow-scrolling: touch;
}

.Timeline-menu .Cal__Month__row {
  color: #e6e6e6 !important;
  box-shadow: none !important;
}

.Timeline-menu .Cal__Month__row:first-child li {
  background-color: #4d4d4d !important;
  box-shadow: 0 -1px 0 #4d4d4d;
}

.Timeline-menu .Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection {
  background-color: #333333 !important;
  border: 2px solid #e7416a !important;
  color: #e6e6e6 !important;
  background-image: none !important;
}

.Timeline-menu .Cal__Month__row.Cal__Month__partial:first-child li:first-child {
  box-shadow: none !important;
}

.Timeline-menu .Cal__Today__chevron {
  display: none !important;
}

.Timeline-menu .Cal__Day__root.Cal__Day__today.Cal__Day__enabled {
  color: #e7416a !important;
}

.Timeline-menu .Cal__Day__root.Cal__Day__today>span {
  color: #e6e6e6 !important;
}

.Timeline-menu .Cal__Years__root {
  background-color: #333333 !important;
}

.Timeline-menu .Cal__Years__root:before,
.Timeline-menu .Cal__Years__root:after {
  top: 0;
  background: linear-gradient(to bottom,
      rgba(32, 31, 31, 0.8) 0%,
      rgba(32, 31, 31, 0) 100%);
}

.Timeline-menu .Cal__Years__year:hover label>span,
.Cal__Years__year.Cal__Years__active label>span,
.Timeline-menu .Cal__Years__year label span {
  color: #e6e6e6 !important;
}

.Timeline-menu .Cal__Years__year:hover label>span,
.Cal__Years__year.Cal__Years__active label>span,
.Timeline-menu .Cal__Years__year ol li {
  color: #e6e6e6 !important;
}

.Timeline-menu .Cal__Years__year ol li {
  width: 40px;
  height: 40px;
}

.Timeline-menu .Cal__Years__year.Cal__Years__withMonths {
  border-bottom: 1px solid #4d4d4d;
}

.Timeline-menu .Cal__Years__year ol li:hover {
  background-color: #4d4d4d;
}

.Timeline-menu .timeline-actions {
  background-color: #333333;
  width: 300px;
  padding: 15px;
  display: flex;
  justify-content: flex-end;
}

.Timeline-menu .timeline-custom-close-btn {
  width: 100px;
  height: 30px;
  padding: 5px 16px;
  border-radius: 25px;
  background-color: #242424;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
  margin-right: 20px;
}

.Timeline-menu .timeline-custom-apply-btn {
  width: 100px;
  height: 30px;
  padding: 5px 16px;
  border-radius: 25px;
  background-image: linear-gradient(103deg, #e72870 6%, #e76161 92%);
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e6e6e6;
}

.expand-notification-wrapper {
  display: flex;
  justify-content: space-between;
}

.prospect-d-items {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 10px 0px 0;
}

/* media queries */

@media only screen and (max-width: 1808px) {

  /* .vd-toolbar{
	  padding-left: 22px !important;
	} */
  .setting-vd-container {
    /* margin-left: 1rem !important;
	  margin-right: 1rem !important; */
    width: 96%;
  }
}

@media only screen and (max-width: 1609px) {
  :root {
    --liner: linear-gradient(6deg, #e72870, #e76161);
    --white: #ffffff;
    font-size: 15px;
  }
}

@media only screen and (max-width: 1558px) {
  .gp-toolbar-1 {
    /* padding-left: 10px; */
    width: 30%;
  }

  :root {
    --liner: linear-gradient(6deg, #e72870, #e76161);
    --white: #ffffff;
    font-size: 14px;
  }

  .vd-toolbar {
    /* padding-left: 7px !important; */
  }
}

@media only screen and (max-width: 1490px) {
  .salespopup-list-container {
    grid-template-columns: 1fr 1fr;
  }

  .selectable-items-cont {
    max-width: 1087px;
    width: 46vw;
  }
}

@media only screen and (max-width: 1478px) {}

@media only screen and (max-width: 1351px) {
  .bgmusic-container {
    padding: 0px 20px 0px 41px;
  }

  .tabLabel {
    font-size: 1.1vw;
  }
}

@media only screen and (max-width: 1351px) {
  .tabLabel {
    font-size: 1.1vw;
  }
}

@media only screen and (max-width: 1338px) {}

@media only screen and (max-width: 1315px) {
  .react-tabs__tab {
    padding: 11px 4px !important;
    min-width: 100px !important;
  }
}

@media only screen and (max-width: 1202px) {
  .toolbar-sv-content {
    /* margin: 20px; */
    padding: 0px 0px 0px 14px;
    /* margin: 0 20px 0 0; */
  }

  .toolbar-lable-avatar,
  .toolbar-lable {
    font-size: 10px !important;
  }

  .selectedArr svg {
    bottom: -9px;
    width: 13px;
    height: 15px;
    left: 40%;
  }

  .avatar-selection-setting {
    width: 100px;
    height: 100px;

    margin: 0px;
  }
}

@media only screen and (max-width: 1021px) {
  .mobile-view-carosel {
    display: flex;
  }

  .desktop-crousel {
    display: none;
  }

  .editor-carosel .desktop-crousel {
    display: block !important;
  }

  .live-page-content .video-player-jw {
    display: block;
    width: 90vw;
    height: auto;
    z-index: 3;
  }
}

@media only screen and (max-width: 921px) {
  .rec-arrow {
    display: none !important;
  }
}

@media only screen and (min-width: 576px) {
  .avatarSpeech-modal .modal-dialog {
    max-width: 90vw !important;

    margin: 1.75rem auto;
  }
}

@media screen and (max-width: 800px) {
  .player {
    flex-direction: column;
  }

  .player .controls {
    width: 100%;
    margin-top: 20px;
  }

  .player .bar {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  .player .song .song__title {
    font-size: 2.5em;
  }

  .player .song .song__artist {
    font-size: 0.8em;
  }
}

/* responsive.css */
@media only screen and (max-width: 1440px) {

  .manage-content-sales button,
  .btn-manage-content {
    width: auto;
    height: 2.1rem;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    margin-left: 22px;
  }

  .menu .main-list-li {
    padding: 12px 10px;
  }

  .myupload-empty-dashboard-popup {
    padding: 59px 0 0 0;
  }

  .select-inchart-list {
    width: 136px;
  }

  .analytic-popupButton svg {
    width: 25px;
    height: 25px;
  }

  .analytic-detail-popup-video {
    max-width: 909px !important;
  }

  .cat-list-item {
    padding: 0 0 15px 0;
    margin: 0 0 15px 0;
  }

  .cat-button-clicked-number {
    font-size: 0.8rem;
  }

  .colat-list {
    font-size: 0.86rem;

    margin-bottom: 15px;
  }

  .colat-item-image {
    width: 164px;
    height: 104px;
  }

  .colat-image-label {
    max-width: 107px;
    font-size: 10px;
  }

  .colat-view {
    font-size: 10px;
  }

  .colat-item {
    width: 164px;

    height: 132px;
  }

  .cat-button {
    font-size: 0.8rem;

    min-width: 104px;
  }

  .cat-camp-name {
    font-size: 0.86rem;

    margin-bottom: 15px;
  }

  .padding-40-popup {
    padding: 2.15rem !important;
  }

  .mail-list {
    padding: 10px 0;
  }

  .header-date-mail {
    font-size: 15px;
  }

  .CAT.analytic-detail-popup-video .vd-modal-content {
    height: 64vh;
    background-color: #333333;
  }

  .select-camp {
    width: 165px;
  }

  .seletct-in-chart {
    width: 102px;
  }

  .modal-title-analutics {
    font-size: 15px;
  }

  .menu-signals {
    width: 147px;
    top: 32px;
  }

  .analytic-detail-popup-video .vd-modal-header,
  .analytic-detail-popup-video .modal-analytic-popup {
    padding: 30px !important;
  }

  .analytic-detail-popup-video .vd-modal-header {
    padding-bottom: 20px !important;
  }

  .analytic-detail-popup-video .modal-analytic-popup {
    padding-top: 0px !important;
  }

  .mail-header {
    padding: 0 0 20px;
  }

  .analytic-detail-popup-video .vd-modal-content {
    height: 64vh;
  }

  .chart-bx1 {
    width: 67%;
    height: 45vh;
    margin-right: 10px;
  }

  .analytic-detail-popup-video .modal-analytic-popup {
    padding-top: 14px;
  }

  .chart-bx2 {
    width: 36%;
    max-height: 421px;
    border-left: solid 1px #4d4d4d;
    padding: 0 0px 23px 23px;
    margin: 0 0 0 0px;
  }

  .gpsolo-tabs .table-campaign-hd {
    margin: 0px 10px;
  }

  .searchBoxongoingCamp {
    min-width: 210px;
  }

  .color-template-container {
    height: 74px;
    top: 90%;
  }

  .settingMainContainer.music-container-custom {
    height: 70vh !important;
  }

  .settingMainContainer.music-container-custom {
    overflow-y: scroll;
    margin: 0px -30px;
    padding: 0 30px;
  }

  .music-player-container {
    padding: 15px 0;
  }

  .player .controls {
    min-height: 53px;
    padding: 5px 22px 4px 5px;
  }

  .player .player__button svg {
    width: 38px;
  }

  .player .song .song__title {
    font-size: 12px;
  }

  .logoTab svg {
    max-width: 59px !important;
  }

  .player .song {
    -webkit-user-select: none;
    user-select: none;
    margin: 0 20px;
    margin: 15px 0 10px 0;
  }

  .bgImgAvatarContainer.diffrent-bdcontainer {
    overflow-y: scroll;
    height: 72vh;
    margin: 0 -40px;
    padding: 0 35px;
  }

  .col-md-10-preview {
    width: 72%;
    position: relative;
  }

  .bjjjhhs_button {
    cursor: pointer;
    position: absolute;
    bottom: 42px;
    right: -0.5rem;
  }

  .groupCamp-no-shared {
    padding: 172px 25px 191px 25px;
  }

  .switch {
    width: 40px;
    height: 19px;
    margin-left: 10px;
  }

  .slider:before {
    width: 19px;
    height: 19px;
  }

  .container-fluid.setting-vd-container .col-md-12.d-flex span+.info-logo-new {
    padding: 28px 0 15px 0 !important;
    margin: 0 0 0 10px !important;
  }

  .tag .tag-close-icon {
    height: 18px;
    width: 18px;
  }

  .tag {
    padding: 4px 4px 4px 1rem;
    font-size: 12px;
  }

  .analytics-tabs-popup {
    grid-gap: 15.3px 15.3px;
    margin: 0 0 15.3px 0px;
  }

  .detail-mail-mail-container .detail-sidebar-container {
    margin: 0 0 0 15.3px;
  }

  .dashbord-container {
    margin: 0px 0 15.3px 0px;
    padding: 0rem 1.9rem;
  }

  .main-container-2 .detail-main-container {
    width: 61.6%;
  }

  .tabs-in-analytics {
    margin-bottom: 15.3px;
  }

  .list-generate-image-overlay-camp {
    position: absolute;
    width: 97%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 11px 1px 1px 85%;
    min-height: 143px;
    max-height: 143px;
  }

  .toolbar-tab-analytic-box1 img {
    width: 2.7rem !important;
  }

  .d-content-lable {
    font-size: 0.9rem !important;

    margin: 0 1.9rem 0 1rem !important;
  }

  .d-content-left-side svg {
    width: 3.3rem;
    height: 3.3;
  }

  .play-list-button-insales {
    min-width: 163.2px;
  }

  .onGoingCamp-container .table-campaign-names {
    width: 100%;
    max-width: 9.1rem !important;
    min-width: 9.1rem !important;
    margin: 0 1rem 0 0 !important;
    display: flex;
    align-items: center;
    /* border-bottom: solid 1px #333333; */
  }

  .onGoingCamp-container .table-campaign-hd:nth-child(6) {
    margin: 0 31px 0 auto;
  }

  .detail-main-container {
    width: 71.6%;
    max-width: 900px;
    /* display: inline-block; */
  }

  .prospect-campaign-lable {
    font-size: 0.76rem;
  }

  .detail-main-container .search-avatar-library {
    margin-right: 0px;
  }

  .detail-main-container .search-avatar-library {
    max-width: 210px;
    justify-content: flex-end;
  }

  .search-avatar.search-avatar-library .svgWrapper {
    position: absolute;
    top: 5px !important;
    left: -20px !important;
  }

  .Mail_unread {
    width: 43px;
    height: 43px;
  }

  .mainDsearchbox {
    max-width: 199px;
  }

  .signals-items {
    padding: 17px 25px;
  }

  .signals-item-footer {
    padding: 15.3px;
  }

  .signal-DOts img {
    width: 33.1px;
    height: 33.1px;
  }

  .onGoingCamp-header {}

  .gpsolo-tabs .table-campaign-names {
    width: 100%;
    max-width: 7.1rem;
    min-width: 7.1rem;
    margin: 0 1.25rem 0 0;
    display: flex;
    align-items: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* border-bottom: solid 1px #333333; */
  }

  .gpsolo-tabs .table-campaign-numbers {
    font-family: "Poppins";
    font-size: 0.9rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #e6e6e6;
    width: 100%;
    margin: 0 0px;
    padding: 1.25rem 0;
  }

  .ongoing-Camp .table-campaign-hd {
    margin: 0 12px;
  }

  .gpsolo-tabs-popup .table-campaign-hd {
    font-size: 0.89rem;
  }

  .icon.table-campaign-numbers {
    max-width: none;
    min-width: auto;
    width: 31px;
    margin: 0;
  }

  .real-analytic .searchBoxongoingCamp {
    width: 106%;
  }

  .real-analytic .prospect-hr-not-valid {
    /* max-width: 124px; */
  }

  .detail-main-container .tabs-in-analytics-tablist {
    list-style: none;
    display: flex;
    padding: 15px 20px;
    margin-bottom: 0;
  }

  .__expand-icon {
    height: 34px;
  }

  .tabs-in-analytics-tabs {
    height: 34px;
  }

  .tabs-in-analytics-tabs.react-tabs__tab--selected {
    height: 34px;
  }

  .sidebar-container {
    position: absolute;
    height: 100vh;
  }

  .content-container {
    background-color: #222222;
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
    width: 100%;
    overflow-y: scroll;
    position: relative;
    margin-left: 60px;
  }

  .detail-list-name {
    max-width: 200px;
    font-size: 12px;
  }

  .inCamp.camp-detail-popup-video .modal-content.vd-modal-content {
    background-color: #333333;
  }

  .label-used-popup-con {
    margin: auto 22px auto 22px;
  }

  .csvPop-detail-popup-video .modal-avatar-select {
    height: 56vh;
    overflow: auto;
  }

  .bgImgAvatarContainer .infinite-scroll-component {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0;
    padding: 0px;
  }

  .bg-inside {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0;
    padding: 0px;
    grid-auto-rows: 166px;
  }

  .bgImgAvatarContainer .infinite-scroll-component {
    grid-auto-rows: 114px;
  }

  .overlay-videocreate-container {
    max-height: 90px;
  }

  .bgImgAvatar img {
    max-width: 164px;
    max-height: 90px;
  }

  .bgImgAvatar {
    max-width: 164px;
    max-height: 90px;
  }

  .color-select {
    max-width: 164px;
    max-height: 90px;
  }

  .video-avbg {
    max-height: 90px;
  }

  .video-avbg video {
    max-height: 90px;
  }

  .bgmusic-container .infinite-scroll-component {
    grid-template-columns: repeat(auto-fill, minmax(106px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(106px, 1fr));
    row-gap: 15px;
    column-gap: 15px;
  }

  .tab-myupload-continer-music .infinite-scroll-component {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(87px, 1fr));
    grid-gap: 15px;
  }

  .add-music {
    max-width: 90px;
    max-height: 90px;

    padding: 10px;
  }

  .bgMusic-single-container label {
    font-size: 12px;
  }

  .add-music-container {
    width: 68px;
    height: 68px;
    padding: 19.5px 0 26.5px 0;

    font-size: 20px;
  }

  .bgMusic-single-container,
  .tab-myupload-continer-music .bgMusic-single-container {
    width: 87px;
  }

  .container-select-color {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px 10px;
    margin: 24px 0;
  }

  .selectedArr svg {
    position: absolute;
    left: 29%;
    bottom: -9px;
    font-size: 25px;
  }

  .toolbar-tab svg {
    max-width: 46px;
  }

  .logoTab svg {
    max-width: 75px;
  }

  .toolbar-width-7 .col-md-9 {
    flex: 0 0 75%;
    max-width: 67%;
  }

  .toolbar-width-7 .col-md-3 {
    flex: 0 0 33%;
    max-width: 33%;
  }

  .preview-container {
    position: relative;
    max-width: 90%;
  }

  .popup-info-av-video {
    font-family: "Poppins";
    font-size: 10px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #e6e6e6;
  }

  .mail-link-popup-td {
    padding: 10px;
    font-size: 0.86rem;
  }

  .td-main-mail-link-popup {
    width: 31%;
  }

  .td-main-mail-link-popup:first-child {
    width: 6%;
  }

  .MailSetnt.analytic-detail-popup-video {
    max-width: 760px !important;
  }

  .signals-timestamp {
    font-size: 10px;
  }

  .signal-icon svg {
    width: 43px;
    height: 42px;
  }

  .mark-all-as-read {
    font-size: 12px;
  }

  .prospect-email-lable {
    font-size: 0.86rem;
  }

  .prospect-items-mini svg:first-child {
    width: 14px;
  }

  .onGoingCampaign-label {
    font-size: 14px;
  }

  .table-campaign-hd:nth-child(3) {
    max-width: 107px;
  }

  .table-campaign-td:nth-child(3) {
    max-width: 107px;
  }

  .list-img-container img {
    max-height: 177px;
  }

  .custom-list-container .infinite-scroll-component {
    grid-template-columns: repeat(auto-fill, minmax(308px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(221px, 1fr));

    row-gap: 1.429rem;

    column-gap: 1.429rem;
  }

  .video-list-main-container {
    margin: 1rem 2.143rem 2.143rem 2.143rem;
  }

  .pro-inner-item {
    padding: 3px 9px 3px 9px !important;
  }

  .pro-sidebar.collapsed {
    min-width: 60px !important;

    max-width: 60px;
  }

  .pro-sidebar.collapsed .pro-menu-item.selected-pro-item-content .pro-inner-item {
    margin-left: 0px;
    padding: 3px 9px 3px 7px !important;
  }

  .burger-button svg {
    width: 39px;
    height: 39px;
  }

  .burger-button {
    background: none;
    outline: none;
    margin: 0 4px;
  }

  .breadcrumb-item-lib {
    font-size: 12px;
  }

  .breadcrumbs-items svg:first-child {
    width: 39px;
    height: 39px;
  }

  .breadcrumbs-items svg:nth-child(2) {
    width: 26px;
    height: 26px;
  }

  .add-video-from-list label {
    font-size: 13px;
  }

  .order-trigger {
    margin: 0;
  }

  .order-trigger svg {
    width: 25px;
    height: 25px;
  }

  .list-vid-detail-container span:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .campaign .svgWrapper {
    top: 9px !important;
  }

  .sales-video .svgWrapper {
    top: 9px !important;
    left: 15px !important;
  }

  .in-create-campaign-search .svgWrapper {
    top: 12px !important;
  }

  .side-down-sub,
  .side-down-ac {
    border-top: 1px solid #4d4d4d;
    padding: 20px 35px;
    margin: 0px;
  }

  .custom-list-tab-container .custom-list-tabs {
    height: 34px;
    padding: 0;
  }

  .campaign-header-lable {
    font-size: 16px;
  }

  .camp-action-sec,
  .camp-action-prime {
    width: 110px;
    height: 34px;
  }

  .bredcrumbIconcamp {
    width: 34px;
    height: 34px;
  }

  .camp-bread-arrow {
    margin-top: 3px;
  }

  .deletePop-detail-popup-video {
    max-width: 589px !important;
    width: 589px !important;
    min-width: auto !important;
  }

  .deletePop-detail-popup-video .modal-title {
    font-size: 15px;
  }

  .deletecamp-lable-sm,
  .test-mail-in-popup-lable {
    font-size: 12px;
    margin-bottom: 20px;
  }

  .deletePop-detail-popup-video .vd-modal-header,
  .deletePop-detail-popup-video .modal-avatar-select,
  .deletePop-detail-popup-video .vd-modal-footer {
    padding: 30px 30px;
  }

  .deletePop-detail-popup-video .vd-modal-header {
    padding-bottom: 15px;
  }

  .save-rename .deletecamp-lable-sm {
    margin-bottom: 20px !important;
  }

  .save-rename .funnellabel {
    margin-bottom: 10px !important;
  }

  .save-rename .edit-duplicate-camp,
  .vd-avatar-select-btn,
  .edit-duplicate-camp {
    min-width: 98px;
    height: 30px;
    min-width: auto;
    max-width: none;
    margin: 0 0 0px 1.5rem;
  }

  #ajeeberrorcrossbtn {
    width: 18px !important;
    height: 18px !important;
  }

  .deletecamp-image svg {
    width: 75px;
    height: 75px;
  }

  .deletecamp-lable-lg {
    font-size: 15px;
    margin: 20px 0 15px 0;
  }

  .view-live-page {
    /* width: 177px; */
    height: 32px;
    font-size: 12px;
  }

  .edit-page-name-change {
    font-size: 15px;
  }

  .campign-edit-toolbar-salespage {
    padding: 7px 0;
  }

  .campaign-lable-input {
    font-size: 12px;
  }

  .tabLabel {
    font-size: 12px;
  }

  .Group-Campaign-via-Email {
    font-size: 14px;
  }

  .sm-btn-camp-detail-group {
    padding: 6px 0.7rem 7px;

    font-size: 10px;
  }

  .det-sidebar {
    padding: 20px 30px;
  }

  .Rectangle-272 {
    height: 70px;
    margin: 15px 0;
  }

  .Rectangle-273 {
    height: 45px;

    font-size: 12px;
  }

  .Rectangle-csvlist {
    /* width: 52.5rem; */
    height: 70px;
    margin: 15px 0;
    padding: 17.5px 19px;
  }

  .csv-det-con .ContactListcsv {
    font-size: 12px;
  }

  .generatingbtn,
  .copy-code {
    width: 120px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .copy-code svg {
    margin-left: 5px;
  }

  .Group-Campaign-via-Email {
    margin-bottom: 15px;
  }

  .main-container-2 .detail-sidebar-container {
    width: 43.4%;
    margin: 0 0 0 26px;
    /* display: inline-block; */
  }

  .camp-test-detail-popup-video {
    max-width: 496px !important;
  }

  .camp-test-detail-popup-video .vd-modal-header {
    padding: 30px 30px 15px 30px;
  }

  .camp-test-detail-popup-video .modal-title {
    font-size: 15px;
  }

  .test-mail-popup-input {
    padding: 5px 1rem;
  }

  .csv-actions .search-avatar.search-avatar-library .svgWrapper {
    left: 12px !important;
  }

  .csvPop-detail-popup-video .csv-popup-heading,
  .csvPop-detail-popup-video .modal-avatar-select {
    padding: 30px;
  }

  .csvPop-detail-popup-video .csv-popup-heading {
    padding-bottom: 15px;
  }

  .csvPop-detail-popup-video .csv-popup-footer {
    padding: 20px 30px;
  }

  .csv-actions a img {
    width: 30px;
    height: 30px;
  }

  .csvpopup-heading {
    font-size: 15px;
  }

  .table-csv-block-popup {
    font-size: 12px;
  }

  .pagination-csv {
    height: 38px;
  }

  .csv-buttons svg {
    width: 13.4px;
  }

  .Group-Campaign-via-Email-99k {
    font-size: 15px;

    margin-bottom: 20px;
  }

  .form-camp-det-gp-lable {
    font-size: 12px;
  }

  .form-camp-det-gp-lable-con {
    margin-bottom: 10px;
  }

  .group-det-camp-wrapper .Group-Campaign-via-Email-con .d-flex {
    margin-bottom: 0px;
  }

  .ContactListcsv {
    font-size: 12px;
    font-weight: 500;
  }

  .dragandDrop-2st-bx label {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #a9a9a9;
    margin-bottom: 0;
  }

  .ooosjj112 .content-wrapper {
    width: 100%;
    margin-bottom: 24px;
  }

  .dragandDrop-1st-bx {
    padding: 12px;
  }

  .dragandDrop-1st-bx .dragIcon {
    width: 17px;
    margin-bottom: 5.7px;
  }

  .dragandDrop-container {
    max-height: 86px;
    margin: 0 0 15px 0;
  }

  .tab-myupload-continer-campaign-csv .infinite-scroll-component {
    grid-template-columns: repeat(auto-fit, minmax(79px, 1fr));
    grid-gap: 17px;
  }

  .bgMusic-vd-setting,
  .tab-myupload-continer-music .bgMusic-vd-setting,
  .music-continue-selected-container .bgMusic-vd-setting {
    max-width: 87px;
    max-height: 87px;
    min-width: 87px;
  }

  .logo-nav-video svg {
    width: 177px;
  }

  .navVideoSetting {
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0 0;
    /* padding: 15px 0 0 0.5px; */
    background-color: #242424;
  }

  .bgMusic-vd-setting img {
    max-width: 30px;
  }

  .popup-camp-det-action .next-camp-12 {
    font-size: 12px;

    width: 88px;
    height: 35px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .popup-camp-det-action {
    display: flex;
    justify-content: flex-end;
    border-top: solid 1px #333333;
    padding: 20px 0 0 0;
  }

  .group-det-camp-wrapper {
    max-height: 71vh;
  }

  .camp-form-item .my-upload-select-camp {
    margin: 0px 0px 15px 0px !important;
    font-size: 12px;
  }

  .input-label-camp-gp {
    font-size: 12px;
  }

  .error-head-type-white {
    font-size: 12px;
  }

  .next-camp-12xx,
  .error-type-lable-white,
  .popup-camp-det-action .content-used-popup-button {
    font-size: 12px;
  }

  .popup-camp-det-action .content-used-popup-button {
    margin: 0 14px 0 0;
  }

  .error-war-type-pink {
    font-size: 12px;
  }

  .block-csv {
    width: 60px;
    height: 26px;

    padding: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Icon-material-error {
    width: 17px;
    height: 17px;
  }

  .camp-time-detail {
    font-size: 10px;
  }

  .camp-time-detail svg {
    /* width:10px ; */
    margin-right: 8px;
  }

  .form-camp-det-gp-lable-con .info-logo-new img {
    width: 22px;
    height: 20px;
  }

  .camp-test-detail-popup-video .vd-avatar-select-btn,
  .camp-detail-popup-video .vd-avatar-select-btn {
    margin: 0 0 0 20px;
    font-weight: 500;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #dcf5f5;
    width: auto;
  }

  .details-edit-page-buttons svg {
    width: 29px;
    height: 30px;
  }

  .edit-sales-page {
    height: 2.1rem;
    margin: 0 15px 0 0;
    padding: 5px 12px;

    font-size: 12px;
  }

  .edit-page-name-change {
    padding: 8px 1rem 8px 1rem;
    font-size: 15px;
  }

  .avatarsetting .modal-avatar-select {
    padding: 0 30px 0px 30px !important;
  }

  .avatar-selection-setting {
    width: 90px;
    height: 90px;
  }

  .avatarsetting.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-width: 870px;
  }

  .avatarsetting .vd-modal-header {
    padding: 30px 30px 20px 30px;
  }

  .avatar-setting-main-popup-contain {
    padding-right: 1.9rem !important;
    margin-right: 1.9rem !important;
    width: 44%;
  }

  .avatarsrtting-containerpreview {
    width: 100%;
    /* padding: 2.5rem 0; */
    /* max-width: 457px; */
  }

  .Avatar-videoplayer {
    max-width: 417px;
    max-height: 277px;
  }

  .edit-duplicate-camp {
    width: 120px;
    height: 30px;
    min-width: auto;
    max-width: none;
    margin: 0 0 0px 1.5rem;
  }

  .avatarsetting .vd-modal-footer {
    padding: 0.71rem 2.2rem;
  }

  .images-vd-container {
    row-gap: 15px;
    column-gap: 15px;
  }

  .sound-vd-setting {
    width: 45px;
    height: 45px;
    margin: 0 0 5px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .voice-single-container {
    max-width: 45px;
  }

  .avatar-setting-main-popup-contain .modal-lable-sm-thumbnail {
    font-size: 12px;
  }

  .my-upload-lable {
    font-size: 10px;
    padding: 4px 4px 4px 4px;
  }

  .vd-avatar-select-btn svg {
    width: 10px;
    height: 13px;
  }

  .title-vd-setting {
    font-size: 15px;
    margin: 0px !important;
    padding: 28px 0 15px 0 !important;
  }

  .container-fluid.setting-vd-container {
    padding: 0 32px;
    margin: 0 !important;
  }

  .container-fluid.setting-vd-container .col-md-12.d-flex .title-vd-setting+.info-logo-new {
    padding: 28px 0 15px 0 !important;
    margin: 0 0 0 10px !important;
  }

  .music-choose-labe {
    font-size: 12px;
  }

  .mergeTag {
    height: 30px;
    font-size: 12px;
  }

  .add-mergeTag-container {
    width: 85px;
    height: 21px;
    padding: 0;
    border-radius: 14px;
    border: solid 1px #333333;
    background-color: #242424;
    color: #e4e9ef;
    font-size: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .add-mergeTag {
    width: 97px;
    height: 30px;

    /* margin: 0 20px 0 0; */
    padding: 5px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .music-choose-label {
    font-size: 12px;
  }

  .avatarSpeech-merge-tag {
    margin-top: 20px;
  }

  .textInSpeech {
    width: 100%;
    box-shadow: 0 0 5px white;
    /* padding: 21px; */

    margin-top: 20px;

    font-size: 12px;
    min-height: 285px;
  }

  .avatarSpeech-merge-tag .arrow {
    width: 34px;
    height: 36px;
  }

  .input-mergetgcontainer {
    min-width: 97px;
    max-width: 97px;
    height: 30px;
    border-radius: 20px;
    border: solid 1px #333333;
    background-color: #242424;
    position: relative;
    padding: 2px 10px;
  }

  .input-mergetg-input {
    font-size: 12px;

    width: 88%;
    border-radius: 20px;
    max-width: 95px;
  }

  .input-mergetg-button {
    width: 23px;
    height: 23px;
    right: 4px;
    top: 2.7px;
  }

  .avatarSpeech-merge-tag .slick-initialized .slick-slide {
    margin-left: 10px;
  }

  .templateSetting-container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    row-gap: 15px;

    column-gap: 15px;
    margin: 0 -30px;
    padding: 0 30px;
    padding-top: 6px;
  }

  .color-template-container {
    width: 100%;
    padding: 16px;
    margin: 0 -33px;
    height: 61px;
    top: 92%;
  }

  .colorthemelabel {
    font-size: 12px;
    font-weight: 500;

    margin: 0 15px;
  }

  .colorbox-selecteable {
    width: 73px;
    height: 30px;
  }

  .color-template-header svg {
    height: 30px;
  }

  .template-select-label {
    font-size: 12px;
  }

  .add-color-container {
    width: 100%;
    max-height: 90px;
    max-width: 162px;
    padding: 12px;
  }

  .color-select {
    max-width: 162px;
    max-height: 90px;
  }

  .color-cancel,
  .color-add {
    font-size: 12px;
    font-weight: 500;
  }

  .selected-in-video-tabs {
    width: 130px;
    height: 75px;
    margin: 0 30px 0 0;
    border-radius: 5px;
    border: solid 1px #333333;
  }

  .currentlyselected-v {
    font-size: 12px;
  }

  .name-of-file,
  .name-of-filetype {
    font-size: 12px;
  }

  .avatarLayout {
    height: 45px;
  }

  .layout-avatar-custom.layout .avatarLayout:first-child img {
    width: 20.7px !important;
    height: 35px !important;
  }

  .layout-avatar-custom.layout .avatarLayout img {
    width: 20.7px !important;
    height: 20.7px !important;
  }

  .avatarLayout.Pos {
    height: 22.7px;
  }

  .avatarLayout.Pos img,
  .avatarLayout.Pos svg {
    height: 10px;
  }

  .searchstickyintabs {
    justify-content: space-between;
    top: -2px;
    width: 106%;
    padding: 10px 11px 20px 9px;
  }

  .searchstickyintabs .seventy {
    width: 65%;
  }

  .searchstickyintabs .thirty {
    width: 28%;
  }

  .overlay-videocreate svg {
    width: 41px;
  }

  .overlay-videocreate {
    top: -7px;
  }

  .spinner-invideo-list {
    max-height: 90px;

    padding: 20% 47%;
  }

  .my-upload-select {
    font-size: 12px;
  }

  .spinner-invideo {
    width: 130px;
    height: 75px;
    margin: 0 20px 0 0;
    padding: 5% 13%;
  }

  .bgImgAvatarContainer .dragandDrop-container {
    width: 103%;
  }

  .react-tabs__tab {
    height: 43px !important;
  }

  .scene-arrow {
    width: 34px;
    height: 27px;
    top: 44%;
    right: -9px;
  }

  .toolbar-tab svg {
    max-width: 36px;
    max-height: 45px;
  }

  .tickImage-tabs {
    top: 18%;
    left: 9%;
    width: 10px;
  }

  .tick-on-tabs {
    width: 23px;
    height: 28px;
    top: -1px;
    left: 0;
  }

  .Preview-scene-now {
    width: 113px;
    height: 63px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Preview-scene-now svg {
    width: 15.2px;
    height: 24px;
  }

  .scene-label {
    margin-top: 5px;

    font-size: 12px;
  }

  .scene-main {
    width: 113px;
    height: 63px;
  }

  .add-scene {
    width: 113px;
    height: 63px;

    padding: 7px;
  }

  .add-scene-container {
    width: 98px;
    height: 48px;
    padding: 0;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container-preview-slider {
    justify-content: flex-start;
  }

  .save-tooltip svg.delete {
    width: 15px;
  }

  .save-tooltip svg.save {
    width: 20px;
  }

  .toolbar-gen-vid {
    font-family: "Poppins", sans-serif;
    width: 135px;
    height: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  .button-add-new-video {
    max-width: 180;
    height: 30px;
    margin: 0px 5px 0 16px;
    font-size: 12px;
  }

  .avatar-select-button img {
    width: 2.144rem;
    height: 2.144rem;
  }

  .toolbar-lable-avatar,
  .selected-toolbar .toolbar-tab .toolbar-lable-avatar,
  .toolbar-lable {
    font-size: 12px;

    line-height: 1;
  }

  .Line-164 {
    width: 0;
    height: 40px;
    margin: 14px 0;
  }

  .gp-toolbar-2,
  .gp-toolbar-1,
  .preview-toolbar-container,
  .toolbar-sv,
  .toolbar-tab {
    max-height: 45px;
  }

  .vd-toolbar {
    padding: 0;
  }

  .vd-toolbar .Line-164 {
    height: 19px;
  }

  .selectedArr svg {
    position: absolute;
    left: 29%;
    bottom: -5px;
    font-size: 25px;
  }

  .input-save-rename {
    padding: 10px 25px 10px 25px;
    border-radius: 20px;
    background-color: #242424;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    max-height: 30px;
    margin-top: 10px;
  }

  .deletePop-detail-popup-video .vd-modal-footer {
    border: none !important;
    padding-top: 20px;
  }

  #Group_393,
  #Group_392 {
    width: 35px;
  }

  .para-input-detail {
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
  }

  .para-sm-detail {
    font-size: 12px;
    line-height: 1;
    margin-bottom: 15px;
  }

  .thumbnail-update-details {
    font-size: 12px;
    width: 79px;
    height: 34px;
  }

  .thumbnail-lable-input {
    margin-bottom: 8px;
  }

  .custom-list-tab-container {
    margin-bottom: 38px;
  }

  .template-header {
    padding: 30px;
    padding-bottom: 15px;
    margin: 0;
  }

  .template-item-container {
    /* margin: 0px; */
  }

  .templategp-2 {
    padding: 30px;
    padding-top: 15px;
  }

  .sm-template-subheading {
    font-size: 12px;
  }

  .lg-template-heading {
    margin-bottom: 15px;
    font-size: 20px;
  }

  .template-search .pointer svg {
    width: 34px;
    height: 34px;
  }

  .template-item-footer {
    padding: 11px 14px;
  }

  .template-name {
    font-size: 12px;
  }

  .template-search {
    height: max-content;
  }

  .search-avatar.search-avatar-library {
    height: max-content;
    position: relative;
  }

  .template-search .search-avatar.search-avatar-library {
    height: max-content;
    position: relative;
  }

  .template-search .search-avatar.search-avatar-library .svgWrapper,
  .custom-list-tab-container .search-avatar.search-avatar-library .svgWrapper {
    top: 6px !important;
    left: 15px !important;
  }

  .custom-list-container.template-item-container {
    grid-template-columns: repeat(auto-fill, minmax(308px, 1fr));
  }

  .template-item {
    min-height: 214px;
  }

  .preview-template-btn.link-custom,
  .edit-template {
    width: 112px;
    height: 35px;
    font-size: 12px;
  }

  .edit-template svg {
    margin: 0 3px;
    width: 11px;
    height: 15px;
  }

  .toolbar-custom,
  .edit-strip,
  .nav-container,
  .save-gp-salespage {
    max-height: 45px;
  }

  .color-box-sales {
    width: 33.8px !important;
    height: 22.5px !important;
  }

  .nav-color-theme .footer-default-btn {
    max-width: 90px;
    max-height: 30px;
    font-size: 12px;
  }

  .toolbar-custom {
    font-size: 0.85rem;
  }

  .undo-redo .Line-164 {
    width: 0;
    height: 30px;
    margin: 7px 0px;
  }

  .undo-redo svg {
    width: 38px;
    height: 37px;
  }

  .undo-redo span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .save-preview svg.pointer:first-child {
    width: 15px;
  }

  .save-preview svg.pointer:nth-child(3) {
    width: 22px !important;
  }

  .save-preview .saveiconSalespage svg {
    width: 19px;
  }

  .preview-salespage-btn {
    width: auto;
    height: 2.1rem;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;

    line-height: 1;
    margin-left: 22px;
  }

  .saveiconSalespage {
    padding: 8px;
    position: relative;
    margin: 0px 26px;
  }

  .salespage-template-editor-container {
    padding-top: 6vh;
  }

  .change-image-buttonn {
    font-weight: 500;
    width: auto;
    height: 2.15rem;
    padding: 0 10px;
    font-size: 12px;
  }

  .editor-input-lable,
  .editor-input-lable {
    font-size: 12px;
  }

  .editor-input {
    font-size: 12px;
    padding: 8px 10px 8px 41px;
    height: 30px !important;
  }

  .trash-icon svg {
    width: 30px;
  }

  .manage-content-sales button {
    padding: 0 12px;

    height: 30px;

    font-size: 12px;
    font-weight: 500;
  }

  .pointer.manage-content-sales+.trash-icon {
    left: 151px !important;
    bottom: -56px;
  }

  .salespopup-list-container {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .salespage-select-popup.logo .order-trigger {
    margin: 0 15px 0 0;
  }

  .salespage-select-popup.logo .search-avatar.search-avatar-library .svgWrapper {
    position: absolute;
    top: 5px !important;
    left: 15px !important;
  }

  .selectable-items-cont {
    max-width: 778px;
    width: 53vw;
  }

  .dragable-item-container {
    padding: 0 30px;
  }

  .Empty-colatral-slider {
    max-width: 294px;
  }

  .salespage-select-popup {
    width: 80vw;

    max-width: 1138px;
  }

  .salespage-popup-header {
    padding: 30px 30px 22px 30px;
  }

  .tabpanel-sales-popup.react-tabs__tab-panel--selected {
    padding: 10px 26px 0 15px;
    margin-top: 15px;
  }

  .selectable-items-cont {
    padding: 0 10px 0 15px;
  }

  .drag-container ul {
    padding: 0px 25px 0px 0px !important;
    margin: 0px -25px 0px 0px !important;
    height: 46vw !important;
  }

  .salespage-popup-body {
    padding: 0 0px 0 30px;
    margin: 0 -30px 0 -30px;
  }

  .salespage-popup-body .tablist-sales-popup {
    margin: 15px 30px 20px 19px;
    padding: 0 15px 0 0;
  }

  .salespage-header-gp2 {
    margin: 0 14px 0px 0;
    height: auto;
  }

  .salespage-header-gp1 {
    margin: 0 20px 20px 0;
  }

  .tabpanel-sales-popup .list-img-container img {
    min-height: 143px;
    max-height: 143px;
  }

  .video-salespopup {
    height: 143px;
  }

  .custom-list-items-sales {
    border: solid 1px #333333;
    border-radius: 5px;
    max-width: 226px;
    height: -moz-fit-content;
    height: fit-content;
  }

  .drag-container .campaign-details-img {
    width: 128px !important;
    height: 75px !important;
  }

  .drag-item-container .thumbnail-details-container .campaign-details-img {
    width: 128px !important;
    height: 75px !important;
  }

  .thumbnail-text-body-sales {
    padding: 5px 0px;
  }

  .thumbnail-lable-input-salespopup {
    font-size: 10px;
    width: 121px;
  }

  .Some-Long-Document-salespopup {
    font-size: 10px;

    max-width: 118px;
  }

  .drag-item-container {
    width: 100%;
    height: 80px;

    margin: 15px 0;
    padding: 0 15px 0 0;
  }

  .remove-dragitem svg {
    width: 7px !important;
  }

  .remove-dragitem {
    font-size: 10px;
    width: 72px;
    height: 20px;
    padding: 5px 4px;
  }

  .salespopup-list-container .spinner-invideo-list {
    position: absolute;
    top: 0px;
    max-height: 144px !important;
    max-width: 294.41px !important;
    height: 177px !important;

    padding: 28% 50% !important;
  }

  .list-vid-detail-container {
    padding: 7px 1rem;
  }

  .Empty-colatral-slider-l1 {
    font-size: 15px;
  }

  .Empty-colatral-slider-l2 {
    font-size: 10px;
  }

  .salespage-popup-header .search-avatar.search-avatar-library .svgWrapper {
    left: 15px !important;
  }

  .salespage-popup-header .order-trigger img {
    width: 30px;
  }

  .salespage-popup-header .searchBoxongoingCamp {
    max-width: 184px;

    height: 30px;

    font-size: 12px;
  }

  .salespage-preview {
    height: 45px;
    padding: 0 20px;
  }

  .back-btn-preview {
    width: 43px;
    height: 48px;
    margin-right: 17px;
  }

  .select-size-icon-container svg {
    width: 60px;
    height: 45px;
  }

  .selected-size-bd {
    right: 5px;
  }

  .selected-size-bd.x99 {
    left: 5px;
  }

  .closesalespagebtn {
    width: 130px;
    height: 30px;
    margin: 10px 0 10px 20px;
    padding: 5px 10px;

    font-size: 12px;
  }

  .tabs-in-analytics-tablist-popup .search-avatar.search-avatar-library .svgWrapper {
    left: 15px !important;
  }

  .tabs-in-analytics-tabs-popup.react-tabs__tab--selected {
    height: 30px;
  }

  .tabs-in-analytics-tabs-popup {
    padding: 5px 15px;
    height: 30px;
  }

  .tabs-in-analytics-tablist.tabs-in-analytics-tablist-popup .menu-container.menu-campain-order {
    /* margin: 0 !important; */
  }

  .tabs-in-analytics-tablist.tabs-in-analytics-tablist-popup .menu-container.menu-campain-order svg,
  .tabs-in-analytics-tablist.tabs-in-analytics-tablist-popup .menu-trigger.order-trigger img,
  .OrderIconCat img {
    width: 35px;
    height: 35px;
  }

  .main-list-li img {
    width: auto;
    height: auto;
  }

  .tabs-in-analytics-tablist.tabs-in-analytics-tablist-popup img.pointer {
    width: 21px !important;
  }

  .tabs-in-analytics-tablist-switch-popup {
    height: 32px;
  }

  .signal-prospect-info-con {
    margin: 0 17px;
    display: flex;
    border: solid 1px #4d4d4d;
    background-color: #333333;
    padding: 10px 14px 10px 0;
    width: 169px;
    height: 43px;
  }

  .signal-prospect-info-date {
    font-size: 10px;
  }

  .signal-prospect-info-lable {
    margin-bottom: 2px;
    font-size: 8px;
  }

  .signal-prospect-info-ico {
    padding: 0 14px;
  }

  .signal-prospect-info-con {
    margin: 0 17px;
    padding: 7px 14px 7px 0;
    width: 169px;
    height: 43px;
  }

  .ongoing-Camp.analytic-detail-popup-video {
    max-width: 819px !important;
    width: 90vw !important;
  }

  .ongoing-Camp.analytic-detail-popup-video .search-avatar.search-avatar-library .svgWrapper {
    left: 15px !important;
  }

  .export-csv-analytuc-popups {
    width: 90px;
    height: 35px;
    margin: 0 50px 0 0px;
    padding: 0 15px;
    font-size: 12px;
    font-weight: 500;
  }

  .table-campaign-numbers:nth-child(2) {
    max-width: 102px;
  }

  .table-campaign-numbers:nth-child(5) {
    max-width: 73px;
  }

  .chart-container-mail-sent {
    overflow-y: scroll;
    flex: auto;
    margin: 0px -30px;
    padding: 0 31px;
  }

  .ongoing-Camp .searchBoxongoingCamp {
    max-width: 184px;
    min-width: auto;
    height: 35px;
    margin: 0;
  }

  .ongoing-Camp .search-avatar-library.an .svgWrapper {
    top: 1px !important;
  }

  .d-content-count {
    font-size: 1.3rem;
  }

  .image-profile-setting {
    width: 129px;
    height: 129px;
  }

  .button-n-bx2-manage-team {
    width: auto;
    height: 2.5rem;
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  .button-n-bx2-profile,
  .info-heading-sm,
  .info-heading-dsm {
    font-size: 12px;
    font-weight: 500;
  }

  .logo-personal-profile {
    top: 2px;
  }

  .tab-myupload-continer .infinite-scroll-component {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-gap: 15px;
  }

  .image-my-upload {
    max-height: 166px;
    min-height: 166px;
    margin: 0 0 4px;
  }

  .heading-analytics {
    font-size: 15px;
    font-weight: 600;
  }

  .my-upload-box-wrapper {
    margin-top: 50px;
  }

  .manage-team-popup-wrapper .menu-signals {
    width: 156px;
    top: 32px;
  }

  .manage-team-popup-wrapper .menu-submenu-manageteam-container {
    width: 218px !important;
  }

  .manage-team-popup-wrapper-header input {
    margin-right: 15px;
    padding: 5px 10px;

    font-size: 12px;

    height: 35px;
    max-width: 429px;
  }

  .manage-team-popup-wrapper-header .btn-custom-manage-team {
    width: 148px;
    height: 35px;
    font-size: 12px;
    padding: 5px 10px;
  }

  .manage-team-thd,
  .manage-team-trd {
    font-size: 10px;

    line-height: 1;

    padding: 10px 0px;
    margin: 0 8px;
  }

  .menu-submenu-manageteam-container {
    padding: 10px !important;
    width: 257px !important;
    height: 115px;
    margin: 0;
    position: absolute;
    right: 0;
  }

  .bxppp_img {
    width: 113px;
    height: 113px;
  }

  .bjjjhhs_button img {
    width: 52px;
    height: 52px;
  }

  .bjjjhhs_remove {
    font-size: 12px;
    font-weight: 500;
  }

  .label-bx88i_name {
    font-size: 12px;
  }

  .CAT.analytic-detail-popup-video.ProfilePopupEdit {
    max-width: 690px !important;
  }

  .bxppp_img-container {
    width: 113px;

    margin: 0 53px 0 0;
  }

  .bx88i_input {
    max-width: 292px;
    width: 100%;
    height: 35px;
    margin: 0 0 0 15px;
    padding: 5px 1rem 5px 1rem;
    font-size: 12px;
    font-weight: 500;
  }

  .bxppp-item {
    display: flex;
    align-items: center;
    margin: 1.48rem 0;
    position: relative;
  }

  .cancel-popup-profile-edit {
    width: 7.2rem;
    margin: 0 10px 0 0;
    padding: 0 15px;

    font-size: 12px;
  }

  .popup-profile-edit-select-btn {
    width: 7.2rem;
  }

  .lock-in-input {
    right: 54px;
  }

  .bx8882_2 {
    padding: 30px 0 0 0;
    /* border-bottom: 1px solid #4d4d4d; */
  }

  .bx8882_1 {
    padding: 9px 0 10px 0;
  }

  .ProfilePopupEdit .modal-footer {
    padding: 10px 30px;
  }

  .header-analytics {
    margin: 0 0 35px 0;
  }

  .name-in-credits-list {
    font-size: 12px;
  }

  .time-in-credit-his {
    font-size: 10px;

    margin-top: 5px;
  }

  .credit-list-item svg {
    width: 43px;
  }

  .credit-list-item {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .credit-list-inneritem {
    margin: 0 0 0 16px;
    justify-content: center;
  }

  .p-un-credit {
    padding: 19px 0 29px 0;
  }

  .date-in-credit {
    font-size: 22px;
    margin: 0 auto 50px auto;
  }

  .videocreditleft {
    font-size: 12px;
  }

  .twotone-credit {
    width: 188px;
    height: 62px;
  }

  .renew-sub-button {
    font-size: 12px;
    width: auto;
    height: 2.5rem;
    margin: 68px auto 4.5px auto;
    padding: 0 15px;
  }

  .creditbx002 {
    padding: 30px 30px 0 30px;
    border-left: solid 1px #333333;
  }

  .Rectangle-272-credit {
    width: 100%;
    height: 289px;
    padding: 43px;
  }

  .addon-buttons {
    font-size: 11px;
    font-weight: 500;
    width: 179px;
  }

  .header-addon {
    font-size: 43px;
  }

  .Rectangle-272-credit:nth-child(2) .header-addon {
    margin-bottom: 28px !important;
  }

  .Rectangle-272-credit:nth-child(1) .header-addon {
    margin-bottom: 7px !important;
  }

  .add-ons .vd-avatar-select-btn {
    width: 120px;
    height: 30px;
  }

  .heading-addons {
    font-size: 0.9rem;
  }

  .about-addon {
    font-size: 10px;
  }

  .camp-detail-popup-video .vd-modal-footer {
    padding: 2.2rem;
  }

  .add-ons .modal-body {
    padding: 3px;
  }

  .camp-detail-popup-video .vd-modal-header {
    padding: 30px 30px;
  }

  .subs-cred-hiss-pop .credit-list-item {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .CAT.analytic-detail-popup-video.ProfilePopupEdit.passwordChangePopup.subs-cred-hiss-pop {
    max-width: 465px !important;
  }

  .login-header {
    font-size: 26px;
    font-weight: 600;

    margin: 53px 0;
  }

  .login-item {
    height: 40px;
    margin: 0 0px 20px;
    border-radius: 28px;
    font-size: 14px;
  }

  .google-login {
    height: 40px;
    margin-bottom: 30px;
    font-size: 14px;
  }

  .login-main-button {
    font-size: 14px;
    font-weight: 500;

    height: 40px;
    margin: 10px 0 30px 0;
    padding: 0 16px;
  }

  .error-login-info {
    height: auto;
    margin: 0px 0 24px 0px;
    padding: 12px;
  }

  .error-login-info-heading {
    font-size: 16px;
    font-weight: 500;
  }

  .error-login-info-label {
    font-size: 14px;
  }

  .login-main-label {
    font-family: "Poppins";
    font-size: 14px;
    margin-bottom: 16px;
  }

  .link-custom.forget-login-main {
    font-size: 14px;
  }

  .error-login-info-label+svg {
    top: 25%;
  }

  .google-login svg {
    margin: 11px !important;
    width: 16px;
  }

  .login-header.forgotpass {
    margin: 53px 0 122px 0 !important;
  }

  .login-verification-label {
    font-size: 16px;
    margin: 0 0 60px 0;
  }

  .thumbnail-preview-popup {
    width: 146px;
    height: 84px;
  }

  .thumbnail-slider .slick-slide {
    width: 146px !important;
    margin-left: 20px;
  }

  .add-thumbnail-container+.pointer svg {
    width: 30px;
    height: 30px;
  }

  .add-thumbnail-container+.pointer {
    right: -68px !important;
  }

  .add-thumbnail-container {
    width: 124px;
    height: 61px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .add-thumbnail-wrapper {
    width: 146px;
    height: 84px;
    padding: 10px;
  }

  .avatarsetting.modal-dialog-centered.modal-dialog-scrollable.thumbnailsetting .modal-content,
  .avatarsetting.modal-dialog-centered.modal-dialog-scrollable.thumbnailsetting {
    max-width: 932px !important;
    height: auto;
  }

  .avatarsetting.modal-dialog-centered.modal-dialog-scrollable.thumbnailsetting .modal-footer.vd-modal-footer span {
    font-size: 14px;
    font-weight: 500;
  }

  .col-md-7-thumb {
    width: 43.6%;
    padding-right: 15px !important;
  }

  .col-md-5-thumb {
    width: 56.4%;
  }

  .preview-popup-thumbnail-p {
    width: 92%;
    height: 260px;
    border-radius: 5px;
    border: solid 1px #333333;
    background-color: #ffffff;
  }

  .cancel-thumbnail-popuo,
  .savethumbnail-popuo {
    width: 98px;
    height: 30px;
    font-size: 10px;
    font-weight: 500;
  }

  .thumbnail-preview-popup+label {
    font-size: 12px;
  }

  .arrowinthumbnail {
    width: 20px;
  }

  .btn-custom-manage-team svg {
    width: 11px;
  }
}

@media only screen and (max-width: 1370px) {
  .colorthemelabel {
    font-size: 14px;

    margin: 0 16px;
  }

  .color-template-container {
    height: 65px;
    top: 90%;
  }

  .tickmark-tabs .tabLabel {
    font-size: 0.98rem;
  }

  .settingMainContainer.music-container-custom {
    height: 61.2vh !important;
  }

  .bgmusic-container .infinite-scroll-component {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    row-gap: 11px;

    column-gap: 11px;
  }

  .list-generate-image-overlay-camp {
    position: absolute;
    width: 97%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0px 1px 1px 85%;
  }

  .detail-main-container .prospect-hr {
    /* max-width: 37px;
		min-width: 37px; */
  }

  .detail-main-container .prospect-hr-not-valid {
    /* max-width: 75px; */
  }

  .detail-main-container .search-avatar-library {
    width: 100%;
    max-width: 185px !important;
    margin-right: 0;
  }

  .onGoingCamp-container .table-campaign-names {
    width: 100%;
    max-width: 9.1rem;
    min-width: 9.1rem;
    margin: 0 1.25rem 0 0 !important;
    display: flex;
    align-items: center;
    /* border-bottom: solid 1px #333333; */
  }

  .onGoingCamp-container .table-campaign-hd {
    margin: 0 0.84rem !important;
  }

  .onGoingCamp-header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 0.8rem 0;
  }

  .dashbord-container {
    padding: 0.84rem 1.5rem !important;
  }

  .list-img-container img {
    width: 100%;
    height: 100%;
    min-height: 155px;
    max-height: 155px;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .funnelChart-container {
    width: 100%;
    height: 451px;
  }

  .funnel-details {
    width: 100%;
    top: 2%;
  }

  .funnel-detail-item {
    height: 81px;
  }

  .funnelChart-container .funnel-image {
    width: 100%;
    height: 333px;
  }

  .onGoingCamp-container .table-campaign-hd:nth-child(6) {
    margin: 0 auto;
  }

  .real-analytic .prospect-hr {
    /* max-width: 47px;
		min-width: 20px; */
  }

  .real-analytic .prospect-hr-not-valid {
    /* max-width: 124px; */
  }

  .real-analytic .gpsolo-tabs .table-campaign-names {
    width: 100%;
    max-width: 6.1rem;
    min-width: 6.1rem;
    margin: 0 1.25rem 0 0;
    display: flex;
    align-items: center;
    /* border-bottom: solid 1px #333333; */
  }

  .label-used-popup-con {
    margin: auto 15px auto 11px;
  }
}

@media only screen and (max-width: 1500px) {
  .toolbar-tab-analytic-container {
    max-width: none !important;
  }
}

@media only screen and (max-width: 920px) {

  .logo-container img,
  .preview-mobile-frame-con .logo-container img {
    min-width: 20px;
  }

  .live-preview-moble-container {
    font-size: 100%;
  }
}

@media only screen and (max-width: 920px) {
  .live-preview-moble-container {
    font-size: 75%;
  }
}

@media only screen and (max-width: 689px) {
  .live-preview-moble-container {
    font-size: 75%;
  }
}

@media only screen and (max-width: 567px) {
  .live-preview-moble-container {
    font-size: 75%;
  }
}

@media only screen and (max-width: 419px) {
  .live-preview-moble-container {
    font-size: 60%;
  }
}

._input_error {
  border: solid 1px #e7416a;
  padding-right: 40px;
}

._error_i {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #e7416a;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
}

._error_label {
  position: absolute;
  padding: 18.4px 13px 10px 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(85deg, #e72870 -1%, #e76161 100%);
  top: calc(100% + 1em);
  text-align: center;
  border-radius: 10px;
}

._error_label::before {
  content: "";
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  background: rgba(231, 70, 105);
  top: calc(0% - 0.8em);
  left: calc(50% - 0.75em);
  transform: rotateZ(45deg);
}

.docs-preview {
  width: 80vw;
  height: 90vh;
}

.preview-mobile-frame-con .logo-container img {
  min-width: 20px;
}

.center-menu-new {
  display: flex;
  justify-content: center;
  align-items: center;
}

.LOOGIN {
  background-color: #e7416a;
}

.LOOGIN_img {
  padding: 40px;
}

.login-container {
  /* width: ; */
  height: 80vh;
  padding: 66px;
  background-color: #222222;
  align-items: center;
  display: flex;
}

.login-wrapper {
  margin: auto;
  /* max-width: 400px; */
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}