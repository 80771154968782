.Slider_Scene {
  // height: 72px !important;
  // width: calc(100vw - 444px);
  // .slick-list{
  //     .slick-track{
  //         transform: unset !important;
  //         .slick-slide{
  //             width: 200px !important;
  //         }
  //     }
  // }
  .slick-track {
    display: flex;
    align-items: center;
  }
  // .SceneBar_list_item{
  //     width: unset !important;
  // }
}

.CustomArrow {
  width: 30px;
  position: sticky;

  &_img {
    cursor: pointer;
  }
}
.VerticalText_Scene {
  margin-right: 0 !important;
}
.SceneBar {
  .DisabledWrapper_child {
    display: flex;
    width: 100%;
  }
  z-index: 110 !important;

  position: relative;
  // padding: 10px;
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #333;
  background-color: #333;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px 5px 0 0;
  height: 72px;
  // &_add{
  //     width: 90px !important;
  //     height: 50px !important;
  //     position: absolute !important;
  // }
  &_list {
    // position: absolute;
    bottom: 0;
    position: relative;
    top: -25px;
    height: 115px;
    display: flex;
    max-width: calc(100% - 600px);
    width: max-content;
    align-items: center;
    align-items: flex-end;
    padding-bottom: 5px;
    padding-left: 5px;
    canvas {
      border-radius: 5px;
    }
    // scroll-behavior: smooth;
    overflow-x: scroll;
    overflow-y: hidden;
    // padding: 10px;
    // margin: 10px;
  }
  &_Scene {
    height: 46px;
    //    background: blue;
    width: 86px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    .SelectOption {
      //    background: red;
      //    padding-top: 50px;
      position: relative;
      //    top: -60px;
      // background: yellow;
      width: 20px !important;
      right: -15px !important;
      left: unset !important;
      height: 20px !important;
      &_list {
        // position: relative;
        top: -60px !important;
        left: -40px !important;
        // margin-bottom: 200px !important;
        // padding-bottom: 100px !important;
      }
    }
    &_inside {
      position: relative;
      height: 100%;
      width: 100%;
      svg {
        position: absolute;
        border-radius: 5px;
        width: 100%;
        height: 100%;
      }
    }
  }
  &_play {
    box-shadow: 0 0 10px 0 rgba(231, 40, 112, 0.16);
    border: solid 1px #e7416a;
    background-image: linear-gradient(
      60deg,
      #e72870,
      #e74469 47%,
      #e76161 98%
    ) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    .Loading {
      width: 30px;
      height: 30px;
      filter: hue-rotate(290deg) saturate(3) brightness(10);
    }
  }
}

.VerticalText {
  font-size: 10px;
  color: #e6e6e6;
  padding: 10px 5px;
  border-radius: 5px;
  background-color: #4d4d4d;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  // text-orientation:;
  margin: 0 15px;
}

.DeletePopup {
  width: 38vw;
  // height: 33vh;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  &_icon {
    // width: 100%;
  }
  &_warning {
    margin-top: 40px;
    font-size: 20px;
    font-weight: 600;
  }
  &_footer {
    // background: red;
    width: 100%;
    text-align: right;
    display: flex;
    // flex-direction: row-reverse;
    justify-content: flex-end;
    .grey {
      margin-right: 10px;
    }
  }
}
.Scene {
  &:last-child {
    // width: 20px !important;
    .SelectOption {
      // display: none;
      &_list {
        top: -60px !important;
        left: -70px !important;
      }
    }
  }
}
.SceneBar_Scene {
  background: white;
  position: relative;
  // box-sizing: border-box;
  margin: 0 10px;
  cursor: pointer;
  .SelectOption {
    // background: red;
    width: 30px;
    height: 30px;
    position: absolute;
    left: calc(100% - 2px);
    top: 5px;
    transform: translate(-100%, 0%);
    z-index: 9;
    &_title {
      padding: unset !important;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
    // display: none;
    &_list {
      top: -110%;
      left: -100%;
    }
  }
  // &:hover{
  // .SelectOption{
  // display: flex;
  // }
  // }
  // &:nth-last-child(1) {
  //   .SceneBar_Scene_active {
  //     // background: red;
  //     display: none;
  //   }
  //   // display: none;
  // }
}
.SceneBar_Scene_number {
  position: absolute;
  background: #4d4d4d;
  left: 0;
  transform: translateX(-100%);
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  font-size: 12px;
  border-radius: 200px 0 0 200px;
}

.SceneBar_Scene_active {
  border: 2px solid #e7416a;

  .SceneBar_Scene_number {
    background: #e7416a;
  }
}

.SceneBar_add {
  border-radius: 5px;
  border: solid 1px #333;
  background-color: #242424;
  margin-left: 0 !important;
}

.TimeZoom {
  margin-left: auto;
  display: flex;
  align-items: center;
  border-left: 1px solid rgb(87, 87, 87);
  height: 100%;
  // padding-left: 10px;

  img {
    margin: 0 5px;
  }
  &_input {
    width: 100px !important;
    display: flex;
    align-items: center;
  }
}
