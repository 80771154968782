.TextRow {
  border-radius: 5px;
  border: solid 1px #333;
  background-color: #4d4d4d;
  padding: 9px 15px;
  color: #e6e6e6;
  margin-bottom: 10px;
  cursor: pointer;
}

.Text {
  padding: 20px;
}
.EffectText {
  padding: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 90vh;
  &_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &_box {
      cursor: pointer;
      width: 75px;
      border-radius: 5px;
      height: 75px;
      border: 1px solid #4d4d4d;
      box-sizing: border-box;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      &_img {
        position: relative;
        // left: 10px;
      }
    }
    &_box_active {
      border: 3px solid #e7416a;
    }
  }
}
